import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Container, Row, Card } from "react-bootstrap";

// import image from "../assets/img/photos/login-banner.jpg";
import image from "../assets/img/photos/login-wallpaper.jpg";
import SabahLogo from "../assets/img/photos/sabah-logo.png";

import Main from "../components/Main";

import LoadingPage from "../components/common/LoadingPage";

const SignInLayout = ({ children }) => {
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  if (isLoading) return <LoadingPage />;

  return (
    <React.Fragment>
      {/* <div className='container-fluid min-vh-100'>
        <div className='row justify-content-center h-100'>
          <div
            className='d-flex align-items-center h-100  position-relative'
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Col lg={8} md={6} className='hidden-md-down p-0'></Col>
            <Col lg={4} md={6} className='pt-4 d-flex h-100'>
              <Card className='mx-4' style={{ borderRadius: 20 }}>
                {children}
                <Outlet />
              </Card>
            </Col>
          </div>
        </div>
      </div> */}
      <div className='container-fluid min-vh-100'>
        <div className='row justify-content-center h-100'>
          <div
            className='d-flex align-items-center w-100 position-relative'
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Col lg={8} md={6} className='d-none d-md-block p-0'></Col>
            <Col
              lg={4}
              md={6}
              className='p-4 d-flex flex-column justify-content-center'
            >
              <Card className='w-100' style={{ borderRadius: 20 }}>
                {children}
                <Outlet />
              </Card>
            </Col>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignInLayout;
