import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchScopeList = createAsyncThunk(
  "projectScope/fetchScopeList",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`/project-scope`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createScope = createAsyncThunk(
  "projectScope/createScope",
  async (values) => {
    const response = await axios.post(`/project-scope`, values);
    const data = await response.data;
    return data;
  },
);

export const updateScope = createAsyncThunk(
  "projectScope/updateScope",
  async (values) => {
    const { id } = values;
    const response = await axios.put(`/project-scope/${id}`, values);
    const data = await response.data;
    return data;
  },
);

export const removeScope = createAsyncThunk(
  "projectScope/removeScope",
  async (id) => {
    const response = await axios.delete(`/project-scope/${id}`);
    const data = await response.data;
    return id;
  },
);

const initialState = {
  scopeList: {
    data: [],
    links: null,
    meta: null,
  },
  page: 1,
  perPage: 30,
  keywords: "",
  isLoading: true,
  btnLoading: true,
};

export const projectScopeSlice = createSlice({
  name: "projectScope",
  initialState,
  reducers: {
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScopeList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchScopeList.fulfilled, (state, action) => {
      state.scopeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createScope.fulfilled, (state, action) => {
      if (!Array.isArray(state.scopeList.data)) {
        state.scopeList.data = [];
      }
      state.scopeList.data.unshift(action.payload); // Add the new object to the top of the list
      state.isLoading = false;
    });
    builder.addCase(updateScope.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.scopeList = state.scopeList.data.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.isLoading = false;
    });
    builder.addCase(removeScope.fulfilled, (state, action) => {
      const id = action.payload;
      state.scopeList.data = state.scopeList.data.filter(
        (item) => item.id.toString() !== id.toString(),
      );
      state.isLoading = false;
    });
  },
});

export const { setPage, setPerPage, setKeywords } = projectScopeSlice.actions;

export default projectScopeSlice.reducer;
