import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import Quill from "quill";

// Editor is an uncontrolled React component
const Editor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    }, [onTextChange, onSelectionChange]);

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement("div"),
      );
      const quill = new Quill(editorContainer, {
        theme: "snow",
        placeholder:
          "Sila isi maklumat, tinggalkan kosong jika tidak berkaitan.",
        modules: {
          toolbar: [
            [{ header: [2, 3, 4, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }],
          ],
        },
      });

      ref.current = quill;

      const sanitizeDelta = (delta) => {
        // Ensure that delta has the expected structure
        if (!delta || !Array.isArray(delta.ops)) {
          return delta;
        }

        return {
          ...delta,
          ops: delta.ops.map((op) => {
            if (op.insert === null) {
              return { ...op, insert: "\n" }; // Replace null with \n
            }
            return op;
          }),
        };
      };

      if (defaultValueRef.current) {
        const delta = sanitizeDelta(defaultValueRef.current);
        quill.setContents(delta);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        // onTextChangeRef.current?.(...args);
        const content = quill.getContents();
        onTextChangeRef.current(
          content.ops.length === 1 && content.ops[0].insert === "\n"
            ? null
            : content,
        );
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref]);

    return <div ref={containerRef}></div>;
  },
);

Editor.displayName = "Editor";

export default Editor;
