import { format } from "date-fns";
import numeral from "numeral";
import React from "react";
import { Badge } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

// icons
import Icon from "@mdi/react";
import { mdiFile, mdiOpenInNew } from "@mdi/js";

const Grouped = ({
  yearValue,
  projects,
  handleViewProject,
  page,
  perPage,
  showColumn,
  sortKey,
}) => {
  const data = projects;
  let cumulativeIndex = 0;

  return (
    <React.Fragment>
      {data.map((program) => {
        const totalAllocation =
          program.main_projects
            .flatMap((item) => item.projects)
            .flatMap((item) => item.s3p_data)
            .reduce((acc, item) => (acc += parseFloat(item.state_fund)), 0) ||
          0;

        const totalCurrentBalance =
          program.main_projects
            .flatMap((item) => item.projects)
            .flatMap((item) => item.s3p_data)
            .reduce(
              (acc, item) => (acc += parseFloat(item.current_balance)),
              0,
            ) || 0;

        const totalExpenditure = 0;

        return (
          <React.Fragment key={program.id}>
            <tr>
              <td />
              <td>
                <div className='d-flex'>
                  <div
                    className='bg-info px-2 text-white rounded-2 fw-bold w-100 text-center text-truncate'
                    style={{ fontSize: "12px" }}
                  >
                    {program?.prefix_type}
                    {program?.ministry_id} {program?.code}
                  </div>
                </div>
              </td>
              <td colSpan={10}>{program?.name}</td>
            </tr>
            {program?.main_projects.map((mainProject) => {
              return (
                <React.Fragment key={mainProject.id}>
                  <tr>
                    <td />
                    <td>
                      <div className='d-flex'>
                        <div
                          className='bg-secondary px-2 text-white rounded-2 fw-bold w-100 text-center fw-bold'
                          style={{ fontSize: "12px" }}
                        >
                          {mainProject?.code}
                        </div>
                      </div>
                    </td>
                    <td colSpan={10}>{mainProject?.name}</td>
                  </tr>
                  {mainProject?.projects.map((project, idx) => {
                    cumulativeIndex++;

                    const sentReport = project?.reports?.filter(
                      (item) => item.is_sent === true,
                    );
                    const isDraft = project?.reports?.some(
                      (item) => item.is_sent === false,
                    );

                    const overallPercentage = sentReport[0]?.progress;

                    const findYear = project?.s3p_data.find(
                      (item) => item.year.toString() === yearValue.toString(),
                    );

                    const lejar =
                      findYear?.overall_financial_data_by_months?.reduce(
                        (acc, item) => (acc += parseFloat(item.lejar)),
                        0,
                      );

                    const latestReport = sentReport[0] || null;

                    return (
                      <React.Fragment key={project.id}>
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => handleViewProject(project.id)}
                        >
                          <td className='text-center'>{cumulativeIndex}</td>
                          <td className='text-center fw-bolder'>
                            <div>{project?.code}</div>
                            <div>
                              {isDraft && (
                                <span className='ms-2'>
                                  <Tooltip
                                    placement='right'
                                    title='Laporan ini mempunyai draf'
                                    arrow
                                  >
                                    <Icon path={mdiFile} size='16px' />
                                  </Tooltip>
                                </span>
                              )}
                            </div>
                          </td>
                          <td>{project?.name}</td>
                          <td className='text-center'>
                            {project?.ministry?.short_form}
                          </td>
                          <td className='text-center'>
                            {project?.executing_agency?.name}
                          </td>
                          <td className='text-center'>
                            {latestReport
                              ? format(
                                  new Date(latestReport?.updated_at),
                                  "dd/MM/yyyy, hh:mm a",
                                )
                              : "-"}
                          </td>
                          <td className='text-center'>
                            <Tooltip
                              title={
                                project?.current_progress_status?.description
                              }
                              placement='top'
                              arrow
                            >
                              <Badge
                                bg=''
                                className={`text-uppercase w-100`}
                                style={{
                                  backgroundColor:
                                    project.current_progress_status?.color,
                                }}
                              >
                                {project.current_progress_status?.label}
                              </Badge>
                            </Tooltip>
                          </td>
                          <td className='text-center fw-bolder'>
                            {overallPercentage || 0} %
                          </td>
                          <td className='text-end text-truncate'>
                            {numeral(findYear?.revised_total_allocation).format(
                              "0,0.00",
                            )}
                          </td>
                          <td className='text-end text-truncate'>
                            {numeral(lejar).format("0,0.00")}
                          </td>
                          <td className='text-end text-truncate'>
                            {numeral(findYear?.current_balance).format(
                              "0,0.00",
                            )}
                          </td>
                          <td onClick={(e) => e.stopPropagation(e)}>
                            <div className='d-flex justify-content-center'>
                              <Link
                                to={`/laporan/kemaskini-kemajuan-projek/${project.id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <Icon path={mdiOpenInNew} size='18px' />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
            <tr style={{ backgroundColor: "#eeeeee" }}>
              <td colSpan={8} className='text-end fw-bold'>
                JUMLAH
              </td>
              <td className='text-end fw-bold'>
                {numeral(totalAllocation).format("0,0.00")}
              </td>
              <td className='text-end fw-bold'>
                {numeral(totalExpenditure).format("0,0.00")}
              </td>
              <td className='text-end fw-bold'>
                {numeral(totalCurrentBalance).format("0,0.00")}
              </td>
            </tr>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default Grouped;
