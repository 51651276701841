import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  thumb,
  thumbInner,
  thumbsContainer,
  img,
} from "../../../../../components/common/thumbStyle";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  styled,
} from "@mui/material";
import { isEmpty } from "lodash";
import usePalette from "../../../../../hooks/usePalette";
import axios from "axios";

// Icons
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiPaperclipPlus,
  mdiTrashCan,
  mdiCheckboxMarked,
  mdiMenuDown,
} from "@mdi/js";

import PDF from "../../../../../assets/img/icons/pdf.svg";

import {
  storeTempFiles,
  removeProgress,
} from "../../../../../redux/slices/reports/projectReportsSlice";
import {
  storeReportAttachment,
  previewReportAttachments,
  removeReportAttachment,
} from "../../../../../redux/slices/reports/projectReportSlice";

const Attachment = ({ isEdit, formik, setFiles, files }) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const palette = usePalette();

  const { projectId, reportId } = routeParams;

  const [matchId, setMatchId] = useState(null);

  const { project, report, uploadProgress, attachments } = useSelector(
    (state) => ({
      project: state.reportReducer.project,
      report: state.reportReducer.report,
      uploadProgress: state.reportReducer.uploadProgress,
      attachments: state.reportReducer.attachments,
    }),
  );

  // Upload handler
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (acceptedFile) => {
      const temp_id = uuidv4();
      const newValues = {
        temp_id: temp_id,
        id: reportId,
        file: acceptedFile,
        collection_type: "report-attachment",
        uploading: true,
      };

      const tempFile = {
        temp_id: temp_id,
        uploading: true,
      };

      await dispatch(storeTempFiles(tempFile));

      await dispatch(storeReportAttachment(newValues));

      setFiles((prev) => [...prev, newValues]);
      dispatch(removeProgress(temp_id));
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
      "application/pdf": [],
    },
    onDrop,
  });

  const handleMouseEnter = (id) => {
    if (id === matchId) {
      setMatchId(null);
    }

    setMatchId(id);
  };

  const handleRemoveAttachment = async (item) => {
    const { id } = item;
    await dispatch(removeReportAttachment(id)).then(() =>
      setFiles(() => files.filter((obj) => obj.id !== id)),
    );
  };

  const handleViewAttachment = (item) => {
    const { url_path } = item;
    window.open(url_path);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        id: projectId,
        source: source,
        reportId: reportId,
      };

      await Promise.all([
        dispatch(previewReportAttachments(props)).then(({ payload }) =>
          setFiles(payload),
        ),
      ]);
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch, projectId, reportId]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files?.forEach((file) => URL.revokeObjectURL(file?.blob));
  }, [files]);

  return (
    <React.Fragment>
      <Form.Group className='mb-3'>
        <Form.Label>
          <span className='fw-bold'>Lampiran</span>
          <p className='mb-0 text-muted text-sm'>
            Dokumen-dokumen atau gambar yang berkaitan dengan laporan
          </p>
          {formik.errors?.attachments && (
            <div className='text-error align-self-center'>
              {formik.errors?.attachments}
            </div>
          )}
        </Form.Label>
        <div
          {...getRootProps({
            onClick: (event) => event.stopPropagation(),
          })}
          className={`${
            isEmpty(attachments) ? "d-flex justify-content-center p-5" : ""
          } rounded border-dashed-m border-red`}
        >
          <input {...getInputProps()} />
          {isEmpty(attachments) ? (
            <div className='align-self-center'>Lampiran Tidak Tersedia</div>
          ) : (
            <ImageList className='mb-0' cols={10} className='p-3 mb-0'>
              {attachments.map((item, idx) => {
                let src = null;

                switch (item?.mime_type) {
                  case "application/pdf":
                    src = PDF;
                    break;

                  default:
                    src = item?.thumb;
                    break;
                }

                const opacityTrue =
                  matchId === item?.uuid || item?.uploading || false;
                return (
                  <React.Fragment key={idx}>
                    {item?.uploading ? (
                      <div
                        className='border-dashed-m rounded d-flex justify-content-center align-items-center'
                        style={{ height: 110, width: 80 }}
                      >
                        <CircularProgress
                          size={35}
                          thickness={7}
                          variant='determinate'
                          value={uploadProgress[item?.temp_id]}
                        />
                      </div>
                    ) : (
                      <ImageListItem
                        sx={{ height: 80, width: 80 }}
                        className='mb-3 position-relative'
                        onMouseEnter={() => handleMouseEnter(item?.uuid)}
                        onMouseLeave={() => setMatchId(null)}
                      >
                        <img
                          className={`${
                            matchId === item?.uuid ? "opacity-25" : ""
                          } `}
                          src={src}
                          alt='Fail tidak ditemui'
                          loading='lazy'
                          style={{ height: 80, width: "auto" }}
                          onClick={() => handleViewAttachment(item)}
                        />
                        <ImageListItemBar
                          className={`text-center`}
                          subtitle={item?.file_name}
                          position='below'
                          sx={{ width: "6rem" }}
                        />
                        {/* {isEdit && (
                          <> */}
                        {isEdit && matchId === item?.uuid && (
                          <IconButton
                            className='position-absolute'
                            sx={{ right: "-15%", top: "-13%" }}
                            size='small'
                            color='error'
                            onClick={() => handleRemoveAttachment(item)}
                          >
                            <Icon path={mdiTrashCan} size='18px' />
                          </IconButton>
                        )}
                        {/* </>
                        )} */}
                      </ImageListItem>
                    )}
                  </React.Fragment>
                );
              })}
            </ImageList>
          )}
        </div>
      </Form.Group>
      {isEdit && (
        <div className='d-flex justify-content-between gap-2'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            <Tooltip title='Muat Naik Lampiran' placement='top' arrow>
              <IconButton size='small'>
                <Icon path={mdiPaperclipPlus} size={1} color={palette.info} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Attachment;
