import React, { useState } from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FourColumns } from "../../../../../components/common/tableLoading";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

// redux
import {
  setKeywords,
  setPage,
  setPerPage,
} from "../../../../../redux/slices/maintenance/projectScopeSlice";

const ProjectScopes = ({
  handleEditItem,
  handleRemoveItem,
  goToPage,
  setGoToPage,
}) => {
  const dispatch = useDispatch();

  const { user, scopeList, isLoading, links, meta, perPage } = useSelector(
    (state) => ({
      user: state.userReducer.user,
      scopeList: state.projectScopeReducer.scopeList.data,
      meta: state.projectScopeReducer.scopeList.meta,
      links: state.projectScopeReducer.scopeList.links,
      perPage: state.projectScopeReducer.perPage,
      isLoading: state.projectScopeReducer.isLoading,
    }),
  );

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center'>Bil.</th>
              <th>Nama Skop</th>
              <th>Keterangan Skop</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading ? (
            <FourColumns />
          ) : (
            <tbody>
              {scopeList.map((row, idx) => {
                const baseIndex = (meta?.current_page - 1) * meta?.per_page;
                return (
                  <tr key={row.id}>
                    <td className='text-center'>{baseIndex + idx + 1}.</td>
                    <td>{row.name}</td>
                    <td>{row.description}</td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Tooltip title='Edit' placement='top' arrow>
                          <IconButton
                            color='info'
                            size='small'
                            aria-label='Edit'
                            onClick={() => handleEditItem(row)}
                          >
                            <MdModeEditOutline fontSize='large' />
                          </IconButton>
                        </Tooltip>
                        {user.roles.includes("superadmin") && (
                          <Tooltip title='Padam' placement='top' arrow>
                            <IconButton
                              color='error'
                              size='small'
                              aria-label='Padam'
                              onClick={() => handleRemoveItem(row.id)}
                            >
                              <MdDelete fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
        <div className='d-flex justify-content-between'>
          <div>
            <span className=''>
              Halaman{" "}
              <strong>
                {meta?.current_page} daripada {meta?.last_page}
              </strong>
              <span className='ms-3 me-2'>Tunjuk:</span>
              <Form.Select
                className='d-inline-block w-auto'
                value={perPage}
                onChange={(e) => {
                  const value = e.target.value;

                  dispatch(setPerPage(value));
                  dispatch(setPage(1));
                }}
              >
                {[15, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </span>
            <span className='ms-3 me-2'>Pergi ke halaman:</span>
            <Form.Control
              className='d-inline-block'
              type='number'
              value={goToPage}
              onChange={(e) => {
                const value = e.target.value;
                setGoToPage(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(setPage(goToPage));
                }
              }}
              style={{ width: "75px" }}
            />
          </div>
          <Pagination>
            <Pagination.First
              onClick={() => {
                dispatch(setPage(1));
              }}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => {
                dispatch(setPage(meta?.current_page - 1));
              }}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => {
                dispatch(setPage(meta?.current_page + 1));
              }}
              disabled={links?.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => {
                dispatch(setPage(meta?.last_page));
              }}
              disabled={links?.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectScopes;
