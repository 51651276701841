import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

//Styles
import { customStyles } from "../../../components/common/customStyleSelect";
import NotyfContext from "../../../contexts/NotyfContext";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

// Components
import MainSectors from "./components/tables/MainSectors";
import MainSectorForm from "./components/modals/MainSectorForm";
import ModalDelete from "../../../components/common/ModalDelete";
import LoadingState from "../../../components/common/LoadingState";

// Redux
import { fetchRmkList } from "../../../redux/slices/rmk-information/rmkSlice";
import {
  fetchMainSectors,
  removeMainSector,
  setSearchRmk,
  setKeywords,
} from "../../../redux/slices/rmk-information/stateMainSectorSlice";
import { getRmkItems } from "../../../redux/slices/utilsSlice";
import axios from "axios";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState(null);
  const [showRemove, setShowRemove] = useState(false);

  const {
    rmkList,
    rmkOptions,
    mainSectorList,
    isLoading,
    btnLoading,
    searchRmk,
    activeRmk,
    keywords,
  } = useSelector((state) => ({
    rmkList: state.rmkReducer.rmkList,
    activeRmk: state.rmkReducer.activeRmk,
    isLoading: state.mainSectorReducer.isLoading,
    btnLoading: state.mainSectorReducer.btnLoading,
    mainSectorList: state.mainSectorReducer.mainSectorList,
    rmkOptions: state.utilsReducer.dropdownItems.rmkItems,
    searchRmk: state.mainSectorReducer.searchRmk,
    keywords: state.mainSectorReducer.keywords,
  }));

  const filteredList = mainSectorList.filter((item) => {
    let filteredPass = true;

    if (keywords) {
      filteredPass =
        filteredPass &&
        item.name &&
        item.name.toLowerCase().includes(keywords.toLowerCase());
    }

    return filteredPass; // Return true to include the item in the filtered array
  });

  const handleCloseForm = () => {
    setOpenForm(false);
    handleResetState();
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleResetState = () => {
    setIsEdit(false);
    setItem(null);
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    handleResetState();
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeMainSector(data.id));
    handleNotification("error", data);
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = () => {
      const props = {
        source: source,
        params: {
          rmk_id: isEmpty(searchRmk) ? activeRmk.number : searchRmk.number,
        },
      };

      dispatch(fetchRmkList());
      dispatch(fetchMainSectors(props));
      dispatch(getRmkItems(props));
    };

    if (!isEmpty(activeRmk)) {
      initialize();
    }

    return () => {
      source.cancel();
    };
  }, [dispatch, searchRmk, activeRmk]);

  useEffect(() => {
    if (!btnLoading) {
      handleCloseRemove();
      handleCloseForm();
    }
  }, [btnLoading]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <MainSectorForm
        showForm={openForm}
        closeForm={handleCloseForm}
        handleNotification={handleNotification}
        data={item}
        btnLoading={btnLoading}
        isEdit={isEdit}
      />

      <Helmet title='Senarai Sektor Utama Negeri' />
      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Maklumat RMK'
          breadcrumbItem='Senarai Sektor Utama Negeri'
        />
        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Sektor Utama
              </Button>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari Sektor Utama...'
                      value={keywords}
                      onChange={(e) => dispatch(setKeywords(e.target.value))}
                      style={{ width: 250, height: 38 }}
                    />
                  </label>
                  <MdSearch
                    className='search-icon-2'
                    size={20}
                    style={{ top: 9, left: 10 }}
                  />
                </div>
              </div>

              <Form.Group style={{ width: 140 }}>
                <Select
                  name='rmk'
                  // className='react-select-container'
                  // classNamePrefix='react-select'
                  placeholder='Pilih RMK'
                  options={rmkOptions}
                  value={searchRmk || activeRmk}
                  onChange={(value) => dispatch(setSearchRmk(value))}
                  noOptionsMessage={() => "Tiada pilihan"}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                    }),
                  }}
                />
              </Form.Group>
            </div>
          </Card.Header>
          <Card.Body>
            <MainSectors
              mainSectorList={filteredList}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
