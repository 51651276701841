import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  return (
    <Formik
      initialValues={{
        nric: "",
        password: "",
        submit: false,
      }}
      // validationSchema={Yup.object().shape({
      //   email: Yup.string()
      //     .email("Must be a valid email")
      //     .max(255)
      //     .required("Email is required"),
      //   password: Yup.string().max(255).required("Password is required"),
      // })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.nric, values.password);
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Alert className='my-3' variant='primary'>
            <div className='alert-message'>For demo puprose only</div>
          </Alert>
          {errors.submit && (
            <Alert className='my-3' variant='danger'>
              <div className='alert-message'>{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className='mb-3'>
            <Form.Label>No. MyKad</Form.Label>
            <Form.Control
              size='lg'
              type='nric'
              name='nric'
              placeholder='Masukkan no. MyKad'
              value={values.nric}
              isInvalid={Boolean(touched.nric && errors.nric)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.nric && (
              <Form.Control.Feedback type='invalid'>
                {errors.nric}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kata Laluan</Form.Label>
            <Form.Control
              size='lg'
              type='password'
              name='password'
              placeholder='Masukkan kata laluan'
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type='invalid'>
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <div className='text-center mt-3'>
            <Button
              type='submit'
              variant='primary'
              size='lg'
              disabled={isSubmitting}
            >
              Log Masuk
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
