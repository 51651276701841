import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty, isUndefined } from "lodash";
import { IconButton, Tooltip } from "@mui/material";
import NotyfContext from "../../../contexts/NotyfContext";
import axios from "axios";
import usePalette from "../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiTrashCan, mdiComment } from "@mdi/js";
import { IoAlertOutline } from "react-icons/io5";

// Components
import BasicInfo from "./components/BasicInfo";
import MainAccordion from "./components/MainAccordion";
import LoadingState from "../../../components/common/LoadingState";
import ModalDelete from "../../../components/common/ModalDelete";
import ReturnApplication from "./components/modals/ReturnApplication";
import Application from "../../export/Application";

// Redux
import { fetchMainSectors } from "../../../redux/slices/rmk-information/stateMainSectorSlice";
import { fetchUpenSections } from "../../../redux/slices/maintenance/upenSectionsSlice";
import {
  createProject,
  fetchProjectDetails,
  getProjectBasicInfo,
} from "../../../redux/slices/projects/projectSlice";
import {
  resetProjectsReducer,
  removeProject,
  setIsLoading,
} from "../../../redux/slices/projects/projectsSlice";
import {
  getMinistries,
  getDepartments,
  getExecutingAgencies,
  getCategories,
  getTargetGroups,
  getPrograms,
  getMainProjects,
  getDistrictItems,
  getParliamentItems,
  getStateLegislativeAssembly,
  getSustainableDevelopmentGoals,
  getUpenSections,
  getProjectScope,
  resetComponentsState,
  resetDropdownItems,
  getMainSectorOptions,
} from "../../../redux/slices/utilsSlice";
import ReturnApplicationContent from "./components/modals/ReturnApplicationContent";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const routeParams = useParams();
  const navigate = useNavigate();
  const palette = usePalette();

  const { projectId, applicationType } = routeParams;

  const [disableBtn, setDisableBtn] = useState(true);
  const [rmkInfo, setRmkInfo] = useState("");
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);
  const [enableBtnSave, setEnableBtnSave] = useState(false);
  const [showReturnApplication, setShowReturnApplication] = useState(false);
  const [returnApplicationNotification, setReturnApplicationNotification] =
    useState(false);
  const [showReturnApplicationContent, setShowReturnApplicationContent] =
    useState(false);

  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [showReturnButton, setShowReturnButton] = useState(false);

  const {
    isLoading,
    user,
    activeRmk,
    rmkActiveApplication,
    applicationStatus,
    project,
    applicationStages,
    categories,
  } = useSelector((state) => ({
    user: state.userReducer.user,
    activeRmk: state.rmkReducer.activeRmk,
    rmkActiveApplication: state.rmkReducer.rmkActiveApplication,
    isLoading: state.projectReducer.isLoading,
    applicationStatus: state.projectReducer.applicationStatus,
    project: state.projectReducer.project,
    applicationStages: state.projectReducer.applicationStages,
    categories: state.utilsReducer.dropdownItems.categories,
  }));

  const handleShowSummary = () => {
    const ceilingFlowEmpty = project?.financial_details.flatMap(
      (item) => item.ceiling_flow_details,
    );

    if (isEmpty(project?.financial_details) || isEmpty(ceilingFlowEmpty)) {
      handleFinancialInfoError();
      return;
    }

    navigate(`/permohonan/butiran-projek/${projectId}/ringkasan-profil-projek`);
  };

  const handleEnableBtn = () => {
    setDisableBtn(false);
  };

  const handleRemoveItem = (project) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    setItem({});
  };

  const handleConfirmRemove = async () => {
    await dispatch(removeProject(project.id));
    handleNotification();
    navigate(`/senarai-projek/status-permohonan`);
  };

  const handleReturnApplication = () => {
    setShowReturnApplication(true);
  };

  const handleShowReturnApplicationContent = () => {
    setShowReturnApplicationContent(true);
  };

  // Notification
  const handleNotification = () => {
    // Success
    notyf.open({
      type: "error",
      message: `${project.name} telah dipadam.`,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  // Notification error
  const handleFinancialInfoError = () => {
    notyf.open({
      type: "error",
      message: `Sila lengkapkan maklumat kewangan projek.`,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handlePriorityInfoError = () => {
    notyf.open({
      type: "error",
      message: `Nombor keutamaan telah didaftarkan, sila pilih nombor susunan keutamaan lain.`,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const initialize = async (props) => {
    if (!isEmpty(projectId)) {
      if (applicationType === "sambungan" || applicationType === "sedia-ada") {
        try {
          const response = await dispatch(getProjectBasicInfo(props));
          const data = response.payload;

          let projectCategory = null;
          if (applicationType === "sambungan") {
            projectCategory = categories.find((item) => item.id === 2);
          }
          if (applicationType === "sedia-ada") {
            projectCategory = categories.find((item) => item.id === 3);
          }

          const newValues = {
            parent_project_id: data?.id,
            rmk_id: rmkActiveApplication.number,
            ministry_id: Number(user?.ministry?.code) || null,
            department_id: user?.department?.id || null,
            branch_id: user?.branch?.id || null,
            main_project_id: data?.main_project_id,
            project_category_id: projectCategory?.id,
            name: data?.name,
            description: data?.description,
            key_performance_indicator: data?.key_performance_indicator,
            justification: data?.justification,
            approval_stages_to: "Kabinet",
            benefits: data?.benefits,
            application_status: "1",
          };

          const newResponse = await dispatch(createProject(newValues));

          const newProject = newResponse?.payload;
          navigate(`/permohonan/butiran-projek/${newProject?.uuid}`);
        } catch (error) {
          console.error("Error initializing project:", error);
        }
      } else {
        dispatch(fetchProjectDetails(props));
      }
    } else {
      dispatch(resetProjectsReducer());
    }
  };

  // const initialize = async (props) => {
  //   if (!isEmpty(projectId)) {
  //     if (applicationType === "sambungan" || applicationType === "sedia-ada") {
  //       const response = await dispatch(getProjectBasicInfo(props));
  //       const data = response.payload;
  //       console.log(data);
  //       let projectCategory = null;

  //       if (applicationType === "sambungan") {
  //         projectCategory = categories.find((item) => item.id === 2);
  //       }
  //       if (applicationType === "sedia-ada") {
  //         projectCategory = categories.find((item) => item.id === 3);
  //       }

  //       const newValues = {
  //         parent_project_id: data?.id,
  //         rmk_id: rmkActiveApplication.number,
  //         ministry_id: Number(user?.ministry?.code) || null,
  //         department_id: user?.department?.id || null,
  //         branch_id: user?.branch?.id || null,
  //         main_project_id: data?.main_project_id,
  //         project_category_id: projectCategory?.id,
  //         name: data?.name,
  //         description: data?.description,
  //         key_performance_indicator: data?.key_performance_indicator,
  //         justification: data?.justification,
  //         approval_stages_to: "Kabinet",
  //         benefits: data?.benefits,
  //         application_status: "1",
  //       };
  //       const newResponse = await dispatch(createProject(newValues));
  //       const newProject = await newResponse?.payload;
  //       navigate(`/permohonan/butiran-projek/${newProject?.uuid}`);

  //       return false;
  //     } else {
  //       dispatch(fetchProjectDetails(props));
  //     }
  //   } else {
  //     dispatch(resetProjectsReducer());
  //   }
  // };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const props = {
      source: source,
      project_id: routeParams.projectId,
      params: {
        rmk_id: rmkActiveApplication.number,
      },
    };

    dispatch(setIsLoading());

    const fetchData = async () => {
      try {
        await dispatch(resetComponentsState());
        const results = await Promise.allSettled([
          dispatch(fetchMainSectors()),
          dispatch(getMinistries(props)),
          dispatch(getDepartments(props)),
          dispatch(getExecutingAgencies(props)),
          dispatch(getTargetGroups(props)),
          dispatch(getPrograms(props)),
          dispatch(getDistrictItems(props)),
          dispatch(getParliamentItems(props)),
          dispatch(getStateLegislativeAssembly(props)),
          dispatch(getSustainableDevelopmentGoals(props)),
          dispatch(getUpenSections(props)),
          dispatch(getProjectScope(props)),
          dispatch(getMainSectorOptions(props)),
          dispatch(getMainProjects(props)),
        ]);
        const responseCategories = await dispatch(getCategories());

        results.forEach((result, index) => {
          if (result.status === "rejected") {
            console.error(`Error in action ${index}:`, result.reason);
          }
        });

        const dataCategories = responseCategories.payload;

        console.log("All API calls completed");

        if (!isEmpty(dataCategories)) {
          await initialize(props);
        } else {
          console.error("Categories is empty");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Wrap the async logic in an IIFE
    (async () => {
      await fetchData();
    })();

    return () => {
      source.cancel("Component unmounted, canceling requests");
    };
  }, [dispatch, rmkActiveApplication, routeParams]);

  useEffect(() => {
    if (!isEmpty(rmkActiveApplication)) {
      const { name, short_form, fifth_year, first_year } = rmkActiveApplication;
      const value = `${name} (${short_form}): ${first_year}-${fifth_year}`;
      setRmkInfo(value);
    }
  }, [rmkActiveApplication]);

  useEffect(() => {
    if (!isEmpty(project)) {
      if (
        applicationStages === 0 ||
        applicationStages === "" ||
        project.stages === "1" ||
        user.roles.includes("superadmin")
      ) {
        setEnableBtnSave(true);
      } else {
        setEnableBtnSave(false);
      }
    } else {
      setEnableBtnSave(true);
    }
    return () => {};
  }, [applicationStages, project]);

  useEffect(() => {
    const isSuperadmin = user.roles.includes("superadmin");
    const isApprovalOfficer = user.roles.includes("ministry_approval_stages");
    const isSent = project?.stages !== null;

    setIsSuperadmin(isSuperadmin);
    setShowReturnButton(isApprovalOfficer && isSent && project.stages < 4);

    return () => {};
  }, [user, project]);

  useEffect(() => {
    if (isEmpty(project)) return;

    const { return_applications } = project;

    const isAcknowledgedFalse = return_applications.some(
      (item) => item.is_acknowledged === false,
    );

    setReturnApplicationNotification(isAcknowledgedFalse);
    return () => {};
  }, [project]);

  if (isLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <ReturnApplication
        showReturnApplication={showReturnApplication}
        closeReturnApplication={() => setShowReturnApplication(false)}
      />

      <ReturnApplicationContent
        showReturnApplicationContent={showReturnApplicationContent}
        closeReturnApplicationContent={() =>
          setShowReturnApplicationContent(false)
        }
      />

      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        message={`${project.name}`}
        confirmRemove={handleConfirmRemove}
        // btnLoading={btnLoading}
      />

      <Helmet title='Daftar Permohonan' />
      <Container fluid className='p-0'>
        <div>
          <div className='d-flex'>
            <div>
              <h3 style={{ fontWeight: 700 }}>{rmkInfo}</h3>
              <b className='fw-bold fs-4 mb-0'>
                Daftar Projek{" "}
                <span className='fw-bolder'>
                  {project.application_status === "1" &&
                    `(${applicationStatus.toLocaleUpperCase()})`}
                </span>
              </b>
              <p className='text-sm text-muted'>
                Sila isi ruangan yang diperlukan. Ruangan bertanda * adalah
                wajib.
              </p>
            </div>
            <div className='align-self-end ms-auto'>
              <div className='m-2 position-relative'>
                <Tooltip title='Perubahan Maklumat' placement='top' arrow>
                  <IconButton
                    className='p-0'
                    onClick={handleShowReturnApplicationContent}
                  >
                    <Icon
                      path={mdiComment}
                      size={1.2}
                      color={
                        returnApplicationNotification
                          ? palette.danger
                          : palette.secondary
                      }
                    />
                    {returnApplicationNotification && (
                      <IoAlertOutline
                        className='position-absolute'
                        size={17}
                        color={palette.white}
                        style={{ top: 4, right: 6 }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <BasicInfo enableBtnSave={enableBtnSave} />

          {!isEmpty(project) && (
            <React.Fragment>
              <MainAccordion
                enableBtnSave={enableBtnSave}
                handleEnableBtn={handleEnableBtn}
              />

              <div
                className={`d-flex ${
                  isSuperadmin
                    ? "justify-content-center"
                    : "justify-content-center"
                } mt-3`}
              >
                {(isSuperadmin ||
                  project?.application_status === "1" ||
                  project?.application_status === "5") && (
                  <Tooltip title='Padam projek' placement='right' arrow>
                    <IconButton
                      size='small'
                      color='error'
                      onClick={() => handleRemoveItem(project)}
                    >
                      <Icon path={mdiTrashCan} size={1} />
                    </IconButton>
                  </Tooltip>
                )}

                <div className='d-flex justify-content-evenly gap-3 mx-auto'>
                  {/* printing */}
                  <div>{/* <Application /> */}</div>

                  {showReturnButton && (
                    <Button variant='danger' onClick={handleReturnApplication}>
                      <span style={{ fontSize: 12 }}>Tolak</span>
                    </Button>
                  )}

                  {/* <Button variant='light'>
                      <span style={{ fontSize: 12 }}>Batal</span>
                    </Button> */}

                  {enableBtnSave && (
                    <Button variant='primary'>
                      <span style={{ fontSize: 12 }}>Simpan Draf</span>
                    </Button>
                  )}

                  <Button
                    variant='success'
                    onClick={handleShowSummary}
                    disabled={isEmpty(projectId) ? true : false}
                  >
                    <span style={{ fontSize: 12 }}>Semak</span>
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
