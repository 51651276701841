import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { getComponent } from "./projects/financialDetailsSlice";
import { setKPLBSection } from "../slices/admin-micro-project/userSlice";
import { createMainProject } from "./rmk-information/mainProjectsSlice";

export const getMinistries = createAsyncThunk(
  "utils/getMinistries",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/ministries/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getDepartments = createAsyncThunk(
  "utils/getDepartments",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/departments/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getBranches = createAsyncThunk("utils/getBranches", async () => {
  const response = await axios.get(`/utils/branches/get`);

  const data = await response.data;

  return data;
});

export const getExecutingAgencies = createAsyncThunk(
  "utils/getExecutingAgencies",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/executing-agencies/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getCategories = createAsyncThunk(
  "utils/getCategories",
  async () => {
    const response = await axios.get(`/utils/categories/get`);

    const data = await response.data;

    return data;
  },
);

export const getTargetGroups = createAsyncThunk(
  "utils/getTargetGroups",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/target-groups/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getPrograms = createAsyncThunk(
  "utils/getPrograms",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(`/utils/programs/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getMainProjects = createAsyncThunk(
  "utils/getMainProjects",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`/utils/main-projects/get?with=query`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProgramMainProjects = createAsyncThunk(
  "utils/getProgramMainProjects",
  async (props) => {
    try {
      const { params } = props;
      const response = await axios.get(`/utils/main-projects/get`, {
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      console.error(error);
    }
  },
);

export const getUpenSections = createAsyncThunk(
  "utils/getUpenSections",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/upen-sections/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getRmkItems = createAsyncThunk(
  "utils/getRmkItems",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/rmk-items/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getDistrictItems = createAsyncThunk(
  "utils/getDistrictItems",
  async (source) => {
    try {
      const response = await axios.get(`/utils/district-items/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getParliamentItems = createAsyncThunk(
  "utils/getParliamentItems",
  async (source) => {
    try {
      const response = await axios.get(`/utils/parliament-items/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getStateLegislativeAssembly = createAsyncThunk(
  "utils/getStateLegislativeAssembly",
  async (source) => {
    try {
      const response = await axios.get(
        `/utils/state-legislative-assembly-items/get`,
        {
          cancelToken: source.token,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getUsers = createAsyncThunk("utils/getUsers", async (props) => {
  try {
    const { params, source } = props;
    const response = await axios.get(`/utils/user-items/get`, {
      params,
      cancelToken: source.token,
    });

    const data = await response.data;

    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(error.message);
    } else {
      console.error(error);
    }
  }
});

export const getUpenOfficers = createAsyncThunk(
  "utils/getUpenOfficers",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/upen-officers/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getKPLBOfficers = createAsyncThunk(
  "utils/getKPLBOfficers",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/utils/kplb-officers/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getSustainableDevelopmentGoals = createAsyncThunk(
  "utils/getSustainableDevelopmentGoals",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(
        `/utils/sustainable-development-goal-items/get`,
        {
          cancelToken: source.token,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProjectScope = createAsyncThunk(
  "utils/getProjectScope",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`utils/project-scope/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getCreditScoreCriteriaList = createAsyncThunk(
  "utils/getCreditScoreCriteriaList",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`utils/credit-score-criteria/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getOptions = createAsyncThunk(
  "utils/getOptions",
  async (props) => {
    try {
      const { source, params } = props;

      const rmkData = await axios.get(`/utils/rmk-items/get`, {
        cancelToken: source.token,
      });

      const ministryData = await axios.get(`utils/ministries/get`, {
        params,
        cancelToken: source.token,
      });

      const departmentData = await axios.get(`/utils/departments/get`, {
        params,
        cancelToken: source.token,
      });

      const programData = await axios.get(`utils/programs/get`, {
        params,
        cancelToken: source.token,
      });

      // const program_ids = programData.data.map((program) => program.id);

      // const mainProjectData = await axios.get(`utils/main-projects/get`, {
      //   params: {
      //     program_ids: program_ids,
      //     rmk_id: params.rmk_id,
      //   },
      //   cancelToken: source.token,
      // });

      const upenSectionData = await axios.get(`/utils/upen-sections/get`, {
        cancelToken: source.token,
      });

      const data = {
        rmkItems: rmkData?.data,
        ministryItems: ministryData?.data,
        departmentItems: departmentData.data,
        programItems: programData.data,
        // mainProjectItems: mainProjectData.data,
        upenSectionItems: upenSectionData.data,
      };

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProgressStatusOptions = createAsyncThunk(
  "utils/getProgressStatusOptions",
  async (props) => {
    try {
      const { source } = props;

      const response = await axios.get(`utils/progress-status-options/get`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getMainSectorOptions = createAsyncThunk(
  "utils/getMainSectorOptions",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`utils/main-sector-options/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const checkPriorityNumber = createAsyncThunk(
  "utils/checkPriorityNumber",
  async (props) => {
    try {
      const { params } = props;

      const response = await axios.get(`utils/check-project-priority`, {
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      console.error(error);
    }
  },
);

const initialState = {
  ceilingItems: [],
  certifyFinancial: [],
  rejectedComponents: [],
  ceilingFlowYearly: [],
  error: {},
  checkBoxItems: {
    sustainableDevelopmentGoals: [],
  },
  tabValue: {
    dashboardTab: "0",
    reportProfileTab: "0",
  },
  dropdownItems: {
    ministries: [],
    departments: [],
    branches: [],
    executingAgencies: [],
    categories: [],
    groups: [],
    programs: [],
    mainProjects: [],
    upen_sections: [],
    kplb_officers: [],
    rmkItems: [],
    districtItems: [],
    parliamentItems: [],
    stateLegislativeAssemblyItems: [],
    userItems: [],
    upenOfficers: [],
    projectScopeItems: [],
    creditScoreCriteriaList: [],
    progressStatusOptions: [],
    mainSectorOptions: [],
  },
  dropDownItemLoading: false,
  virementSource: {
    isLoading: false,
    data: null,
  },
  bendahari: [],
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    createCeilingItems: (state, action) => {
      const data = action.payload;
      state.ceilingItems = data;
    },
    createCertifyFinancial: (state, action) => {
      const data = action.payload;
      state.certifyFinancial = data;
    },
    updateCertifyFinancialItem: (state, action) => {
      const { id, amount } = action.payload;
      state.certifyFinancial = state.certifyFinancial.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            amount: amount,
          };
        }
        return item;
      });
    },

    createRejectedScopeList: (state, action) => {
      const data = action.payload;
      state.rejectedComponents = data;
    },
    addComponent: (state, action) => {
      const { id, cost_description, ceiling_flow_details } = action.payload;

      state.rejectedComponents = state.rejectedComponents.filter(
        (item) => item.id !== id,
      );

      ceiling_flow_details.forEach((item) => {
        const newValues = {
          ...item,
          cost_description: cost_description,
        };
        state.certifyFinancial.push(newValues);
      });
    },

    removeComponent: (state, action) => {
      const id = action.payload;
      state.certifyFinancial = state.certifyFinancial.filter(
        (item) => item.financial_detail_id !== id,
      );
    },

    resetComponentsState: (state, action) => {
      state.certifyFinancial = [];
      state.rejectedComponents = [];
    },

    resetDropdownItems: () => initialState.dropdownItems,

    setDashboardTab: (state, action) => {
      state.tabValue.dashboardTab = action.payload.toString();
    },

    setReportTab: (state, action) => {
      state.tabValue.reportProfileTab = action.payload.toString();
    },

    setBendahari: (state, action) => {
      state.bendahari = action.payload;
    },

    setUpdateBendahari: (state, action) => {
      const { code, api_status, isDisabled } = action.payload;
      state.bendahari = state.bendahari.map((item) => {
        if (item.code === code) {
          return {
            ...item,
            isDisabled: isDisabled,
            api_status: api_status,
          };
        }

        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMinistries.fulfilled, (state, action) => {
      state.dropdownItems.ministries = action.payload;
    });

    builder.addCase(getDepartments.fulfilled, (state, action) => {
      state.dropdownItems.departments = action.payload;
    });

    builder.addCase(getExecutingAgencies.fulfilled, (state, action) => {
      state.dropdownItems.executingAgencies = action.payload;
    });
    builder.addCase(getBranches.fulfilled, (state, action) => {
      state.dropdownItems.branches = action.payload;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.dropdownItems.categories = action.payload;
    });
    builder.addCase(getTargetGroups.fulfilled, (state, action) => {
      state.dropdownItems.groups = action.payload;
    });
    builder.addCase(getPrograms.fulfilled, (state, action) => {
      state.dropdownItems.programs = action.payload;
    });

    builder.addCase(getMainProjects.fulfilled, (state, action) => {
      state.dropdownItems.mainProjects = action.payload;
    });

    builder.addCase(getUpenSections.fulfilled, (state, action) => {
      state.dropdownItems.upen_sections = action.payload;
    });

    builder.addCase(getKPLBOfficers.fulfilled, (state, action) => {
      state.dropdownItems.kplb_officers = action.payload;
    });

    builder.addCase(getRmkItems.fulfilled, (state, action) => {
      state.dropdownItems.rmkItems = action.payload;
    });

    builder.addCase(getDistrictItems.fulfilled, (state, action) => {
      state.dropdownItems.districtItems = action.payload;
    });

    builder.addCase(getParliamentItems.fulfilled, (state, action) => {
      state.dropdownItems.parliamentItems = action.payload;
    });

    builder.addCase(getStateLegislativeAssembly.fulfilled, (state, action) => {
      state.dropdownItems.stateLegislativeAssemblyItems = action.payload;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.dropdownItems.userItems = action.payload;
    });

    builder.addCase(getUpenOfficers.fulfilled, (state, action) => {
      state.dropdownItems.upenOfficers = action.payload;
    });

    builder.addCase(getProjectScope.fulfilled, (state, action) => {
      state.dropdownItems.projectScopeItems = action.payload;
    });

    builder.addCase(
      getSustainableDevelopmentGoals.fulfilled,
      (state, action) => {
        state.checkBoxItems.sustainableDevelopmentGoals = action.payload;
      },
    );

    builder.addCase(getCreditScoreCriteriaList.fulfilled, (state, action) => {
      state.dropdownItems.creditScoreCriteriaList = action.payload;
    });

    builder.addCase(getOptions.fulfilled, (state, action) => {
      const {
        rmkItems,
        ministryItems,
        departmentItems,
        programItems,
        mainProjectItems,
        upenSectionItems,
      } = action.payload;

      state.dropdownItems.rmkItems = rmkItems;
      state.dropdownItems.ministries = ministryItems;
      state.dropdownItems.departments = departmentItems;
      state.dropdownItems.programs = programItems;
      state.dropdownItems.mainProjects = mainProjectItems;
      state.dropdownItems.upen_sections = upenSectionItems;
    });

    builder.addCase(getComponent.fulfilled, (state, action) => {
      state.rejectedComponents.push(action.payload);
    });

    builder.addCase(getProgressStatusOptions.fulfilled, (state, action) => {
      state.dropdownItems.progressStatusOptions = action.payload;
    });

    builder.addCase(getMainSectorOptions.fulfilled, (state, action) => {
      state.dropdownItems.mainSectorOptions = action.payload;
    });

    builder.addCase(setKPLBSection.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.dropdownItems.mainProjects = state.dropdownItems.mainProjects.map(
        (item) => {
          if (item.id === id) {
            return action.payload;
          }
          return item;
        },
      );
    });

    builder.addCase(createMainProject.fulfilled, (state, action) => {
      state.dropdownItems.mainProjects.push(action.payload);
    });
  },
});

export const {
  createCeilingItems,
  createCertifyFinancial,
  addComponent,
  removeComponent,
  updateCertifyFinancialItem,
  createRejectedScopeList,
  resetComponentsState,
  resetDropdownItems,
  setDashboardTab,
  setReportTab,
  setBendahari,
  setUpdateBendahari,
} = utilsSlice.actions;

export default utilsSlice.reducer;
