import React, { useEffect, useState } from "react";
import {
  Card,
  Accordion,
  Table,
  Button,
  InputGroup,
  Form,
} from "react-bootstrap";
import usePalette from "../../../../../hooks/usePalette";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Tooltip } from "@mui/material";

// Icons
import Icon from "@mdi/react";
import { mdiPencil, mdiCheckBold, mdiCloseThick } from "@mdi/js";

// Options
import {
  PROJECT_TYPE_OPTIONS,
  PROJECT_CATEGORY_OPTIONS,
} from "../../../../../constants";

// redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";

const ProjectBrief = ({ project, isAdminLevel, showNotification }) => {
  const palette = usePalette();
  const dispatch = useDispatch();

  const [mainProjectOptions, setMainProjectOptions] = useState([]);
  const [isEdit, setIsEdit] = useState({
    program_id: false,
    main_project_id: false,
    section_upen_id: false,
    code: false,
    name: false,
    description: false,
    project_type_id: false,
  });

  const {
    btnLoading,
    user,
    programs,
    mainProjects,
    upenSections,
    branches,
    categories,
  } = useSelector((state) => ({
    user: state.userReducer.user,
    btnLoading: state.projectReducer.btnLoading,
    programs: state.utilsReducer.dropdownItems.programs,
    mainProjects: state.utilsReducer.dropdownItems.mainProjects,

    upenSections: state.utilsReducer.dropdownItems.upen_sections,
    branches: state.utilsReducer.dropdownItems.branches,
    categories: state.utilsReducer.dropdownItems.categories,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: project.code || "",
      program_id: project.program_id || "",
      branch_id: project.branch_id || "",
      main_project_id: project.main_project_id || "",
      section_upen_id: project.section_upen_id || "",
      name: project.name || "",
      description: project.description || "",
      project_type_id: project.project_type_id || "",
      project_category_id: project.project_category_id || "",
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        id: project.uuid,
      };

      await dispatch(updateProject(newValues));

      const hideInput = async (field) => {
        await new Promise((resolve) => {
          setIsEdit((prevIsEdit) => ({
            ...prevIsEdit,
            [field]: false,
          }));
          resolve();
        });
      };

      const keys = Object.keys(values);

      const hideAllInputs = async () => {
        for (const key of keys) {
          await hideInput(key);
        }
      };

      hideAllInputs();
      showNotification();
    },
  });

  const handleSaveForm = () => {
    formik.handleSubmit();
  };

  const handleEditField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: true,
    });
  };

  const handleCloseField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: false,
    });
  };

  const handleSaveInput = (field) => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (!isEmpty(mainProjects)) {
      const options = mainProjects.filter(
        (item) => item.program_id === Number(project.program_id),
      );
      setMainProjectOptions(options);
    }
  }, [programs, mainProjects]);

  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>PROJEK BRIEF</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <Card className='mb-0'>
          <Card.Body>
            <div className='px-3 py-2 table-responsive'>
              <Table size='sm' striped className='m-0 project-overview'>
                <tbody>
                  <tr>
                    <th>RMK</th>
                    <td>{`${project.rmk?.name} (${project.rmk?.short_form})`}</td>
                  </tr>
                  <tr>
                    <th>Kod Projek</th>
                    <td>
                      {isEdit.code ? (
                        <>
                          <InputGroup size='sm'>
                            <Form.Control
                              type='text'
                              name='code'
                              value={formik.values.code}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() => handleCloseField("code")}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        </>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {`${project.program?.prefix}${project.program?.ministry_code}-${project.executing_agency?.code}-000000-${project.program?.code}-${project.main_project?.code}${project.code}-00000`}
                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah kod sub projek'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() => handleEditField("code")}
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Kementerian</th>
                    <td>{project?.ministry?.value}</td>
                  </tr>
                  <tr>
                    <th>Program</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.program_id ? (
                          <Form.Select
                            size='sm'
                            className='rounded-2'
                            name='program_id'
                            value={formik.values.program_id}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue("program_id", Number(value));
                              const options = mainProjects.filter(
                                (item) => item.program_id === Number(value),
                              );
                              setMainProjectOptions(options);
                            }}
                          >
                            {programs.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <>
                            {project.program
                              ? `${project.program.prefix}${project.ministry.code} ${project.program.code} - ${project.program.name}`
                              : "-"}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Projek Utama</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.main_project_id ? (
                          <InputGroup size='sm'>
                            <Form.Select
                              className='rounded-2'
                              name='main_project_id'
                              value={formik.values.main_project_id}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "main_project_id",
                                  Number(value),
                                );
                              }}
                            >
                              {mainProjectOptions.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {`${item.code} - ${item.label}`}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                setIsEdit({
                                  ...isEdit,
                                  program_id: false,
                                  main_project_id: false,
                                });
                              }}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        ) : (
                          <>
                            {project?.main_project
                              ? `${project?.main_project?.code} - ${project?.main_project?.name}`
                              : "-"}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Ubah Program & Projek Utama'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        setIsEdit({
                                          ...isEdit,
                                          program_id: true,
                                          main_project_id: true,
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Cawangan (Jika Berkaitan)</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.branch_id ? (
                          <InputGroup size='sm'>
                            <Form.Select
                              className='rounded-2'
                              name='branch_id'
                              value={formik.values.branch_id}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "branch_id",
                                  Number(value),
                                );
                              }}
                            >
                              {branches.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                setIsEdit({
                                  ...isEdit,
                                  branch_id: false,
                                });
                              }}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        ) : (
                          <>
                            {project?.branch ? `${project.branch?.label}` : "-"}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Cawangan'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        setIsEdit({
                                          ...isEdit,
                                          branch_id: true,
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Nama Projek</th>
                    <td>
                      {isEdit.name ? (
                        <>
                          <Form.Control
                            as='textarea'
                            type='text'
                            name='name'
                            className='mb-1'
                            style={{ minHeight: 75, maxHeight: 105 }}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <div className='d-flex justify-content-end'>
                            <Button
                              size='sm'
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              size='sm'
                              variant='secondary'
                              onClick={() => handleCloseField("name")}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {project.name}
                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah Nama Projek'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() => handleEditField("name")}
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Keterangan Projek</th>
                    <td>
                      {isEdit.description ? (
                        <>
                          <Form.Control
                            as='textarea'
                            type='text'
                            name='description'
                            className='mb-1'
                            style={{ minHeight: 130, maxHeight: 150 }}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <div className='d-flex justify-content-end'>
                            <Button
                              size='sm'
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              size='sm'
                              variant='secondary'
                              onClick={() => handleCloseField("description")}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {project.description}

                          <>
                            <Tooltip
                              title='Ubah Keterangan Projek'
                              placement='left'
                              arrow
                            >
                              <span>
                                <Icon
                                  path={mdiPencil}
                                  size={0.7}
                                  color={palette.primary}
                                  onClick={() => handleEditField("description")}
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </Tooltip>
                          </>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Jenis Projek</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.project_type_id ? (
                          <InputGroup size='sm'>
                            <Form.Select
                              className='rounded-2'
                              name='project_type_id'
                              value={formik.values.project_type_id}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "project_type_id",
                                  Number(value),
                                );
                              }}
                            >
                              {PROJECT_TYPE_OPTIONS.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("project_type_id")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        ) : (
                          <>
                            {project?.project_type?.name}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Ubah jenis projek'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        handleEditField("project_type_id")
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Kategori Projek</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.project_category_id ? (
                          <InputGroup size='sm'>
                            <Form.Select
                              className='rounded-2'
                              name='project_category_id'
                              value={formik.values.project_category_id}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "project_category_id",
                                  Number(value),
                                );
                              }}
                            >
                              {categories.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("project_category_id")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        ) : (
                          <>
                            {project?.project_category?.value}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Ubah jenis projek'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        handleEditField("project_category_id")
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Seksyen UPEN</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.section_upen_id ? (
                          <InputGroup size='sm'>
                            <Form.Select
                              className='rounded-2'
                              name='section_upen_id'
                              value={formik.values.section_upen_id}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "section_upen_id",
                                  Number(value),
                                );
                              }}
                            >
                              {upenSections.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.label} ({item.code})
                                </option>
                              ))}
                            </Form.Select>
                            <div className='d-flex justify-content-end'>
                              <Button
                                size='sm'
                                className='mx-1'
                                variant='success'
                                onClick={() => handleSaveForm()}
                                disabled={btnLoading}
                              >
                                <Icon path={mdiCheckBold} size={0.7} />
                              </Button>
                              <Button
                                size='sm'
                                variant='secondary'
                                onClick={() =>
                                  handleCloseField("section_upen_id")
                                }
                              >
                                <Icon path={mdiCloseThick} size={0.7} />
                              </Button>
                            </div>
                          </InputGroup>
                        ) : (
                          <>
                            {project.section_upen_id
                              ? project?.upen_section?.name
                              : "-"}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Ubah Seksyen UPEN'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        handleEditField("section_upen_id")
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Sektor</th>
                    <td>{project.main_sector?.name}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectBrief;
