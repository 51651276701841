import React, { useEffect, useRef, useState } from "react";
import usePalette from "../../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiArrowExpandAll } from "@mdi/js";

// Components
import ExpandedMapView from "../modals/ExpandedMapView";
import WebMapArcgis from "./WebMapArcgis";

const MapForm = () => {
  const palette = usePalette();

  const [expandMap, setExpandMap] = useState(false);

  return (
    <React.Fragment>
      <ExpandedMapView
        expandMap={expandMap}
        closeMap={() => setExpandMap(false)}
      />

      <div className='mb-3 position-relative'>
        {!expandMap && (
          <div
            className='position-absolute esri-widget--button esri-widget esri-interactive'
            style={{ top: "16.5%", left: 15, zIndex: 2 }}
          >
            <Icon
              path={mdiArrowExpandAll}
              size='20px'
              onClick={() => setExpandMap(true)}
            />
          </div>
        )}

        <div style={{ height: 520 }}>
          <WebMapArcgis />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapForm;
