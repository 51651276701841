import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import parse from "html-react-parser";
import {
  Card,
  Badge,
  Button,
  Table,
  Accordion,
  Row,
  Col,
  Form,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import numeral from "numeral";
import _, { isEmpty } from "lodash";

// Icons
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiPencil, mdiCheck, mdiNoteTextOutline, mdiCircle } from "@mdi/js";

// Components
import CeilingFlowForm from "../modals/CeilingFlowForm";
import NotesSection from "../modals/NotesSection";

// Redux
import { createCeilingItems } from "../../../../../redux/slices/utilsSlice";
import { removeCeilingFlow } from "../../../../../redux/slices/projects/financialDetailsSlice";
import { Divider } from "@mui/material";
import usePalette from "../../../../../hooks/usePalette";

const RowItems = ({
  row,
  handleEditItem,
  handleRemoveItem,
  handleOpenNotesSection,
  handleMatchId,
  handleClearMatchId,
  matchId,
  ceilingFlow,
  enableBtnSave,
}) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [open, setOpen] = useState(false);

  const { ceilingBalance, project } = useSelector((state) => ({
    ceilingBalance: state.ceilingBalanceReducer.ceilingBalance,
  }));

  return (
    <React.Fragment>
      <tr style={{ "& > *": { borderBottom: "unset" } }}>
        <td>{row.cost_description}</td>
        <td className='text-truncate text-end'>
          {`RM ${numeral(row.amount).format("0,0.00")}`}
        </td>
        <td className='text-center'>
          <Tooltip
            title='Lhat catatan'
            placement='top'
            followCursor
            arrow
            className='p-0'
          >
            <span>
              <IconButton
                onClick={() => handleOpenNotesSection(row)}
                className='button-animated position-relative'
                style={{ backgroundColor: "transparent" }}
              >
                <Icon
                  className=''
                  path={mdiNoteTextOutline}
                  size={1}
                  color={palette.primary}
                />
                {ceilingFlow.find((item) => item.id === row.id)
                  .ceiling_flow_detail_notes.length !== 0 && (
                  <Icon
                    className='position-absolute'
                    path={mdiCircle}
                    size={0.55}
                    style={{ top: 5, right: 5 }}
                    color='#ef5350'
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </td>
        <td>
          <div className='d-flex justify-content-center'>
            <Tooltip title='Edit' placement='top' arrow>
              <span>
                <IconButton
                  color='primary'
                  size='small'
                  aria-label='edit'
                  onClick={() => handleEditItem(row)}
                  disabled={!enableBtnSave}
                >
                  <MdEdit fontSize='large' />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Padam' placement='top' arrow>
              <span>
                <IconButton
                  color='error'
                  size='small'
                  aria-label='padam'
                  onClick={() => handleRemoveItem(row)}
                  disabled={!enableBtnSave}
                >
                  <MdDelete fontSize='large' />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

const TableCeilingFlowInfo = ({
  notifyError,
  activeRmk,
  project,
  handleNotification,
  enableBtnSave,
}) => {
  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [accordionItem, setAccordionItem] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [costByYear, setCostByYear] = useState([]);
  const [item, setItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [matchId, setMatchId] = useState(null);
  const [ceilingFlowInfo, setCeilingFlowInfo] = useState([]);

  const [value, setValue] = useState([]);

  const { first_year, second_year, third_year, fourth_year, fifth_year } =
    activeRmk;

  const sortData = value.sort((a, b) => a - b);

  const handleChange = (val) => {
    setValue(val);
  };

  const groupedByYear = ceilingFlowInfo.reduce((acc, item) => {
    const { year } = item;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});

  const result = Object.entries(groupedByYear).map(([year, data]) => ({
    year: year,
    data,
  }));

  const totalCeilingAmount = ceilingFlowInfo.reduce(
    (acc, flow) => acc + parseFloat(flow.amount),
    0,
  );

  const handleOpenForm = (year) => {
    setOpenForm(true);
    setSelectedYear(year);
  };

  const handleEditCeilingFlow = (item) => {
    const { year } = item;
    setSelectedYear(year);
    setItem(item);
    setOpenForm(true);
    setIsEdit(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setItem({});
    setSelectedYear("");
    setIsEdit(false);
  };

  const handleRemoveItem = (item) => {
    dispatch(removeCeilingFlow(item));
  };

  const handleOpenNotesSection = (item) => {
    setOpenNotes(true);
    setItem(item);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
    setItem({});
  };

  const handleMatchId = (id) => {
    setMatchId(id);
  };

  const handleClearMatchId = () => {
    setMatchId(null);
  };

  useEffect(() => {
    sortData.map((year) => {
      result.map((row) => {
        if (row.year === year.toString()) {
          const arrCost = row.data;
          const total = arrCost.reduce(
            (acc, item) => acc + parseFloat(item.amount),
            0,
          );

          const value = {
            year: year,
            total: total,
          };

          setCostByYear((cost) => [...cost, value]);
        }
      });
    });

    return () => {
      setCostByYear([]);
    };
  }, [value]);

  useEffect(() => {
    if (!isEmpty(project)) {
      const updatedArray = project.financial_details.map((financial) => {
        const updatedChildren = financial.ceiling_flow_details.map((flow) => ({
          ...flow,
          financial_detail_id: financial.id,
          cost_description: financial.cost_description,
        }));
        return { ...financial, ceiling_flow_details: updatedChildren };
      });
      const childrenArray = updatedArray
        .map((financial) => financial.ceiling_flow_details)
        .reduce((acc, flow) => acc.concat(flow), []);

      setCeilingFlowInfo(childrenArray);

      const selectedYear = childrenArray.map((child) => child.year);

      const uniqueNumbers = [...new Set(selectedYear)];

      setValue(uniqueNumbers);
      dispatch(createCeilingItems(childrenArray));
    }
  }, [project]);

  return (
    <React.Fragment>
      <CeilingFlowForm
        openCeilingFlowForm={openForm}
        toggleCeilingFlowForm={handleCloseForm}
        selectedYear={selectedYear}
        data={item}
        ceilingFlowInfo={groupedByYear[selectedYear]}
        isEdit={isEdit}
        handleNotification={handleNotification}
      />

      <NotesSection
        openNotes={openNotes}
        handleCloseNotes={handleCloseNotes}
        data={item}
        section={"ceiling_flow"}
      />

      <Card.Header className='pb-0'>
        <Card.Title>Aliran Siling</Card.Title>
        <Card.Text className='text-sm'>
          Sila isi maklumat aliran siling dengan teliti.
        </Card.Text>
      </Card.Header>
      <Card.Body className='m-0'>
        <ToggleButtonGroup
          className='mb-3'
          type='checkbox'
          value={value}
          onChange={handleChange}
        >
          {[first_year, second_year, third_year, fourth_year, fifth_year].map(
            (year, idx) => {
              const findValue = costByYear.find((item) => item.year === year);

              return (
                <ToggleButton
                  disabled={isEmpty(findValue) ? false : true}
                  key={idx}
                  variant='outline-secondary'
                  id={`year-${year}`}
                  value={year}
                >
                  Tahun {year}
                </ToggleButton>
              );
            },
          )}
        </ToggleButtonGroup>
        <div className='mb-3 text-truncate'>
          {sortData.length === 0 ? (
            <div
              className={`text-center border-bottom ${
                notifyError.ceiling_flow ? "bg-error-form" : ""
              }`}
            >
              <span
                className={`fst-italic ${
                  notifyError.ceiling_flow ? "text-error" : ""
                }`}
              >
                {notifyError.ceiling_flow
                  ? "Sila isi maklumat aliran siling"
                  : "Maklumat Tidak Tersedia"}
              </span>
            </div>
          ) : (
            <Accordion activeKey={value} alwaysOpen>
              {sortData.map((year, idx) => {
                const totalAmountFlowPerYear = costByYear.find(
                  (item) => item.year === year,
                );

                return (
                  <Accordion.Item key={year} eventKey={year}>
                    <div className='position-relative'>
                      <Accordion.Header>
                        <span className='fw-bold'>Tahun {year}</span>
                        <span className='ms-4'>
                          Jumlah Siling:{" "}
                          {`RM ${
                            !isEmpty(totalAmountFlowPerYear)
                              ? numeral(totalAmountFlowPerYear.total).format(
                                  "0,0.00",
                                )
                              : "RM 0.00"
                          }`}
                        </span>
                      </Accordion.Header>
                      {enableBtnSave && (
                        <Button
                          variant='secondary'
                          className='button-header'
                          size='sm'
                          onClick={() => handleOpenForm(year)}
                        >
                          + Maklumat
                        </Button>
                      )}
                    </div>
                    <Accordion.Body>
                      {result.map((row) => {
                        return (
                          <React.Fragment key={row.year}>
                            {row.year === year.toString() && (
                              <React.Fragment>
                                <div className='table-responsive'>
                                  <Table size='sm' style={{ fontSize: 12 }}>
                                    <thead>
                                      <tr>
                                        <th className='text-truncate'>
                                          Keterangan Kos Dipohon
                                        </th>
                                        <th className='text-end text-truncate'>
                                          Siling
                                        </th>
                                        <th
                                          className='text-center'
                                          style={{ width: 400 }}
                                        >
                                          Catatan
                                        </th>
                                        <th className='text-center'>
                                          Tindakan
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {row.data?.map((row, idx) => {
                                        return (
                                          <RowItems
                                            key={row.id}
                                            row={row}
                                            handleEditItem={
                                              handleEditCeilingFlow
                                            }
                                            handleRemoveItem={handleRemoveItem}
                                            handleOpenNotesSection={
                                              handleOpenNotesSection
                                            }
                                            handleMatchId={handleMatchId}
                                            handleClearMatchId={
                                              handleClearMatchId
                                            }
                                            matchId={matchId}
                                            ceilingFlow={ceilingFlowInfo}
                                            enableBtnSave={enableBtnSave}
                                          />
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          )}
        </div>
        <div className='mb-3'>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column sm={4} className='text-end'>
              Jumlah Aliran Siling
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                className='text-end fw-bold'
                type='text'
                placeholder='RM 0.00'
                readOnly
                value={`RM ${numeral(totalCeilingAmount).format("0,0.00")}`}
                isInvalid={notifyError?.balance}
                style={{ width: "40%" }}
              />
              <Form.Control.Feedback type='invalid' style={{ width: "60%" }}>
                {notifyError?.balance}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </div>
      </Card.Body>
    </React.Fragment>
  );
};

export default TableCeilingFlowInfo;
