import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const createSupportDocument = createAsyncThunk(
  "projects/createSupportDocument",
  async (values) => {
    try {
      const response = await axios.post(`/support-document/store`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateSupportDocument = createAsyncThunk(
  "projects/updateSupportDocument",
  async (values) => {
    const { id } = values;
    try {
      const response = await axios.put(
        `/support-document/update/${id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const previewSupportDocumentFiles = createAsyncThunk(
  "projects/previewSupportDocumentFiles",
  async (props) => {
    try {
      const { id, source } = props;
      const response = await axios.get(
        `/support-document-files/preview/${id}`,
        {
          cancelToken: source.token,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const removeSupportDocument = createAsyncThunk(
  "projects/removeSupportDocument",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.delete(`/support-document/delete/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

export const storeSupportDocumentFile = createAsyncThunk(
  "projects/storeSupportDocumentFile",
  async (values) => {
    try {
      const { id } = values;

      const response = await axios.post(
        `/support-document-file/upload/${id}`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);
