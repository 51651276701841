import React, { useRef } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";

const Background = () => {
  const { project } = useSelector((state) => ({
    project: state.jppnReducer.application,
  }));

  return (
    <React.Fragment>
      <div>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>2.0</span>
          Latar Belakang
        </Form.Label>
        {JSON.parse(project?.background)?.map((item, idx) => {
          return (
            <div key={idx} className='d-flex flex-row ps-4'>
              <div className='me-3'>2.{idx + 1}</div>
              <p>{item?.description}</p>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Background;
