import React from "react";
import { Card, Accordion, Table, Row, Col } from "react-bootstrap";
import WebMapArcgis from "./maps/WebMapArcgis";

const ProjectLocation = ({ project }) => {
  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>MAKLUMAT GIS</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <Card className='mb-0'>
          <Card.Body>
            <div className='py-3 px-3'>
              <div className='position-relative mb-3' style={{ height: 550 }}>
                <WebMapArcgis project={project} />
              </div>

              <Table size='sm'>
                <thead className='text-uppercase' style={{ fontSize: 11 }}>
                  <tr>
                    <th className='text-center'>Daerah</th>
                    <th className='text-center'>Parlimen</th>
                    <th className='text-center'>Dewan Undangan Negeri (DUN)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='text-center'>
                      {project?.site_informations?.district?.value}
                    </td>
                    <td className='text-center'>
                      {project?.site_informations?.parliament?.value}
                    </td>
                    <td className='text-center'>
                      {
                        project?.site_informations?.state_legislative_assembly
                          ?.value
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className='px-3 pb-3 table-responsive'>
              <Table size='sm' striped className='m-0 project-overview'>
                <tbody>
                  <tr>
                    <th>Pemilikan Tapak</th>
                    <td>{project?.site_informations?.site_ownership || "-"}</td>
                  </tr>
                  <tr>
                    <th>Geran</th>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th>No Rujukan Geran</th>
                    <td>-</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectLocation;
