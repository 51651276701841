import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import numeral from "numeral";

const ExpandFinancialDetails = ({ show, onHide }) => {
  const { data } = useSelector((state) => ({
    data: state.dashboardReducer.overall.charts.financialReportByMinistries,
  }));

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        size='xl'
        centered
        keyboard={false}
        backdrop='static'
      >
        <Modal.Header className='border-0'>
          <h4 className='text-uppercase' style={{ fontWeight: 700 }}>
            Prestasi Perbelanjaan Keseluruhan
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className='table-responsive'>
            <Table>
              <thead className='text-uppercase'>
                <tr>
                  <th rowSpan={2} className='align-middle text-center'>
                    Agensi
                  </th>
                  <th className='text-center'>
                    Peruntukan
                    <div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Peruntukan Disemak<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Perbelanjaan<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Baki Peruntukan<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Perbelanjaan + Terkini<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Baki Terkini<div>(RM)</div>
                  </th>
                </tr>
                <tr>
                  <th className='text-center py-0'>(A)</th>
                  <th className='text-center py-0'>(B)</th>
                  <th className='text-center py-0'>(C)</th>
                  <th className='text-center py-0'>(D)</th>
                  <th className='text-center py-0'>(E)</th>
                  <th className='text-center py-0'>(F)</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => {
                  const balance =
                    parseFloat(item.fund) - parseFloat(item.expenditure);
                  const latest_balance =
                    parseFloat(item.fund) -
                    parseFloat(item.auditted_expenditure);
                  return (
                    <tr>
                      <td className='text-truncate fw-bolder'>{`${item.code} - ${item.name}`}</td>
                      <td className='text-end'>
                        {numeral(item.initial_fund).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(item.fund).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(item.auditted_expenditure).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(balance).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(item.expenditure).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(latest_balance).format("0,0.00")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex border-0'>
          <Button variant='light' onClick={onHide}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ExpandFinancialDetails;
