import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Form,
  Row,
  Accordion,
  Button,
  Spinner,
} from "react-bootstrap";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Checkbox,
  Divider,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import _, { isEmpty } from "lodash";
import usePalette from "../../../../hooks/usePalette";

// Styles
import {
  customStyles,
  customStylesForm,
} from "../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiHelpCircle, mdiCheck, mdiPlus } from "@mdi/js";
import { goalIcons } from "../../../../components/common/sDgs";

// Components
import LoadingState from "../../../../components/common/LoadingState";
import NewOptionWithSelect from "../../../../components/common/NewOptionWithSelect";
import MainProjectForm from "./modals/MainProjectForm";

// Redux
import {
  createProject,
  updateProject,
} from "../../../../redux/slices/projects/projectSlice";
import {
  createMainProject,
  updateMainProject,
  getLastCode,
  resetLastCode,
} from "../../../../redux/slices/rmk-information/mainProjectsSlice";
import { getProgramMainProjects } from "../../../../redux/slices/utilsSlice";

const BasicInfo = ({ enableBtnSave }) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const routeParams = useParams();
  const navigate = useNavigate();
  const palette = usePalette();

  const { type, projectId, applicationCategory, applicationType } = routeParams;

  const [checked, setChecked] = useState([1]);
  const [isEdit, setIsEdit] = useState(false);
  const [showDepartmentInput, setShowDepartmentInput] = useState(false);
  const [showBranchInput, setShowBranchInput] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [departmentValue, setDepartmentValue] = useState(null);

  const [programOptions, setProgramOptions] = useState([]);
  const [programValue, setProgramValue] = useState(null);
  const [mainProjectOptions, setMainProjectOptions] = useState([]);
  const [mainProjectValue, setMainProjectValue] = useState(null);
  const [goalValues, setGoalValues] = useState([]);
  const [noSubProject, setNoSubProject] = useState(false);

  const [resetProgramValue, setResetProgramValue] = useState(true);
  const [strategicDirectionOptions, setStrategicDirectionOptions] = useState(
    [],
  );
  const [mainProjectDescriptionValue, setMainProjectDescriptionValue] =
    useState("");
  const [showMainProjectForm, setShowMainProjectForm] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const {
    isLoading,
    btnLoading,
    applicationStatus,
    user,
    programs,
    mainProjects,
    project,
    ministries,
    departments,
    categories,
    rmkActiveApplication,
    goals,
    mainSectorOptions,
  } = useSelector((state) => ({
    user: state.userReducer.user,

    isLoading: state.projectReducer.isLoading,
    btnLoading: state.projectReducer.btnLoading,

    project: state.projectReducer.project,
    applicationStatus: state.projectReducer.applicationStatus,

    ministries: state.utilsReducer.dropdownItems.ministries,
    departments: state.utilsReducer.dropdownItems.departments,
    categories: state.utilsReducer.dropdownItems.categories,
    programs: state.utilsReducer.dropdownItems.programs,
    mainProjects: state.utilsReducer.dropdownItems.mainProjects,

    goals: state.utilsReducer.checkBoxItems.sustainableDevelopmentGoals,

    rmkActiveApplication: state.rmkReducer.rmkActiveApplication,
    mainSectorOptions: state.utilsReducer.dropdownItems.mainSectorOptions,
  }));

  const isSuperAdmin = user.roles.includes("superadmin");
  const isUser = user.roles.includes("user");

  const handleGetId = (id) => {
    navigate(`/permohonan/butiran-projek/${id}`);
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      approval_stages_to: project.approval_stages_to || "Kabinet",
      key_performance_indicator: project.key_performance_indicator || "",
      goals: project.sustainable_development_goals || [],
      ministry_id: project.ministry || user?.ministry || null,
      department_id: project.department || user?.department || null,
      branch: project.branch || user?.profile?.branch || null,
      project_category_id: !isEmpty(project.project_category)
        ? project.project_category
        : categories.find((item) => item.id === 1),
      program: project.program || programValue,
      name: project.name || "",
      main_sector_id: project?.main_project || null,
      previous_project_progress: project?.previous_project_progress || "",
    },
    validationSchema: Yup.object().shape({
      main_sector_id: Yup.object()
        .required()
        .typeError("Sila pilih bidang keutamaan"),
      strategic_directions: Yup.array()
        .min(1, "Sila pilih sekurang-kurangnya 1 pilihan")
        .required("Sila pilih halatuju strategi")
        .nullable(),
      key_performance_indicator: Yup.string()
        .required("Sila isi petunjuk prestasi utama (KPI)")
        .min(20, "Mesti melebihi 20 perkataan"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          ...values,
          id: projectId,
          strategic_directions: values.strategic_directions,
          key_performance_indicator: values.key_performance_indicator,
          main_sector_id: values.main_sector_id.id,
          ministry_id: Number(values.ministry_id.code),
          department_id: values?.department_id?.id || null,
          branch_id: values.branch?.id,
          project_category_id: values.project_category_id.id,
          sustainable_development_goals: values.goals,
          program_id: values?.program?.id || null,
          main_project_id: values.main_project?.id,
          previous_project_progress: values?.previous_project_progress,
        };
        await dispatch(updateProject(newValues));
        handleNotification("edited", newValues);
      } else {
        const newValues = {
          ...values,
          rmk_id: rmkActiveApplication.number,
          program_id: values.program?.id || null,
          application_status: "1",
          project_category_id: values.project_category_id.id,
          strategic_directions: values.strategic_directions,
          main_sector_id: values.main_sector_id.id,
          ministry_id: Number(values.ministry_id?.code),
          department_id: values.department_id?.id,
          branch_id: values.branch?.id,
          sustainable_development_goals: values.goals,
          main_project_id: values.main_project?.id || null,
          previous_project_progress: values?.previous_project_progress,
        };
        let getUuid = null;
        await dispatch(createProject(newValues)).then((res) => {
          const { uuid } = res.payload;
          getUuid = uuid;
        });

        handleNotification("created", newValues);
        handleGetId(getUuid);
      }
    },
  });

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat projek berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat projek berjaya dikemaskini.`;
        break;

      default:
        msg = `Projek Profil berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleChangeMinistry = (value) => {
    setProgramOptions([]);
    setDepartmentValue(null);

    formik.setFieldValue("ministry_id", value);
    if (!resetProgramValue) formik.setFieldValue("program_id", null);

    const { code } = value;
    const departmentOptions = value.departments;

    setDepartmentOptions(departmentOptions);

    const filteredPrograms = programs.filter(
      (program) =>
        program.ministry_code === code || program.ministry_code === null,
    );
    setProgramOptions(filteredPrograms);
  };

  const handleChangeDepartment = (value) => {
    if (!resetProgramValue) formik.setFieldValue("program_id", null);

    setDepartmentValue(value);
    formik.setFieldValue("department_id", value);

    if (isEmpty(value)) {
      setBranchOptions([]);
    } else {
      setBranchOptions(value.branches);
    }

    if (!isEmpty(project.department_id)) {
      setProgramOptions([]);

      const filteredPrograms = programs.filter(
        (program) =>
          program?.department_id === value.id ||
          program?.ministry_code?.toString() === null,
      );
      setProgramOptions(filteredPrograms);
    } else {
      let filteredPrograms = [];

      const listOfMinistryPrograms = programs.filter(
        (program) =>
          program?.ministry_code?.toString() ===
          formik.values?.ministry_id.code,
      );

      if (isEmpty(value)) {
        filteredPrograms = listOfMinistryPrograms;
      } else {
        const test = listOfMinistryPrograms.map((item) => {
          if (item.department_id === value.id) {
            filteredPrograms.push(item);
          } else if (item.department_id === null) {
            filteredPrograms.push(item);
          }
        });

        // if (matchObject) {
        //   filteredPrograms.push(matchObject);
        // } else {
        //   filteredPrograms = listOfMinistryPrograms.filter(
        //     (program) => program.department_id === null,
        //   );
        // }
      }
      setProgramOptions(filteredPrograms);
    }
  };

  const handleSelectBranch = (value) => {
    if (isEmpty(value)) {
      formik.setFieldValue("main_project", "");
      formik.setFieldValue("branch", value);
      return;
    }

    formik.setFieldValue("branch", value);

    // const filteredMainProjects = mainProjects.filter((item) => {
    //   return item?.branch_id === value?.id;
    // });

    // const groupedData = _.groupBy(filteredMainProjects, "program_id");

    // const programId = Object.keys(groupedData)[0];
    // const filteredPrograms = programOptions.filter(
    //   (program) => program.id === Number(programId),
    // );

    // console.log(programOptions);

    // setProgramOptions(filteredPrograms);
    // setMainProjectOptions(filteredMainProjects);
  };

  const createOption = (id, label, value) => ({
    id,
    label,
    value,
  });

  const handleSelectProgram = async (value) => {
    formik.setFieldValue("program", value);
  };

  const handleSelectMainProject = (value) => {
    formik.setFieldValue("main_project", value);
    setMainProjectDescriptionValue(value?.description || "");
  };

  const handleSelectGoal = (obj) => {
    const value = Number(obj.index_no);

    const isExisted = formik.values.goals.includes(value);

    if (isExisted) {
      formik.setFieldValue(
        "goals",
        formik.values.goals.filter((item) => item !== value),
      );
    } else {
      const values = [...formik.values.goals, value];

      formik.setFieldValue("goals", values);
    }
  };

  const handleSelectSector = (value) => {
    formik.setFieldValue("main_sector_id", value);
    formik.setFieldValue("strategic_directions", []);
  };

  const handleCreate = async (inputValue, type) => {
    if (type === "created") {
      let mainProjectCode = "0000";

      const lastCodeResponse = await dispatch(
        getLastCode({
          program_id: formik.values?.program?.id,
        }),
      );
      mainProjectCode = lastCodeResponse.payload;

      const newValues = {
        program_id: formik.values?.program?.id || null,
        name: inputValue,
        code: mainProjectCode,
        department_id: formik.values?.department_id?.id || null,
        branch_id: formik.values?.branch?.id || null,
      };

      setTimeout(async () => {
        try {
          const { payload } = await dispatch(createMainProject(newValues));

          setMainProjectOptions((prev) => [...prev, payload]);
          formik.setFieldValue("main_project", payload);
        } catch (error) {
          console.error("Error creating main project:", error);
        }
      }, 1000); // Adjust delay as needed
    }

    if (type === "updated") {
      const newValues = {
        program_id: formik.values?.main_project?.id || null,
        name: inputValue,
        code: formik.values?.main_project?.code,
        department_id: formik.values?.department_id?.id || null,
        branch_id: formik.values?.branch?.id || null,
        description: mainProjectDescriptionValue,
      };
    }
  };

  useEffect(() => {
    const options = formik.values?.main_sector_id?.strategic_directions || [];
    setStrategicDirectionOptions(options);

    const props = {
      params: {
        program_id: formik.values?.program?.id || null,
        rmk_id: rmkActiveApplication?.number,
      },
    };

    dispatch(getProgramMainProjects(props));
  }, [formik.values]);

  useEffect(() => {
    if (!user) return;
    const { ministry, department, roles, profile } = user;
    const { branch } = profile;
    const isSuperadmin = roles.some((role) => role === "superadmin");

    if (isSuperadmin) {
      setShowBranchInput(true);
    } else {
      setShowBranchInput(!isEmpty(branch));
    }

    setDepartmentValue(formik.values?.department_id);

    setResetProgramValue(isSuperadmin && !isEmpty(projectId));

    let filteredMainProjects = mainProjects.filter((item) => {
      if (showBranchInput) {
        return item.branch_id === branch?.id;
      } else if (item.department_id === department?.id) {
        return true;
      } else {
        return (
          item.ministry_id === ministry?.code && item.department_id === null
        );
      }
    });

    if (!isEmpty(project)) {
      const { ministry, department } = project;
      handleChangeMinistry(ministry);
      handleChangeDepartment(department);
      handleSelectMainProject(project.main_project);
      handleSelectProgram(project.program);
      setMainProjectOptions(filteredMainProjects);
    } else {
      setMainProjectOptions(filteredMainProjects);

      let filteredPrograms = programs.filter((item) => {
        return showBranchInput
          ? item.id === filteredMainProjects[0]?.program_id
          : item.ministry_code === ministry?.code;
      });

      if (filteredPrograms.length === 1) {
        handleSelectProgram(filteredPrograms[0]);
      }

      setProgramOptions(filteredPrograms);
    }
  }, [user, programs, mainProjects, projectId, project]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (!isEmpty(routeParams.projectId)) {
      const selectedOption = mainSectorOptions.find(
        (item) => item.id === project.main_sector?.id,
      );

      setStrategicDirectionOptions(selectedOption?.strategic_directions);

      const newValue = {
        ...selectedOption,
        label: selectedOption?.name,
        value: selectedOption?.name,
      };

      formik.setFieldValue("main_sector_id", selectedOption ? newValue : null);

      const items = project.strategic_directions?.map(
        (item) => item.strategic_direction_id,
      );

      formik.setFieldValue("strategic_directions", items);

      setIsEdit(true);

      setIsDisabled(project?.application_sent);
    }

    return () => {
      isMounted = false;
      setIsEdit(false);
    };
  }, [mainSectorOptions, project]);

  return (
    <>
      <MainProjectForm
        show={showMainProjectForm}
        onHide={() => setShowMainProjectForm(false)}
        data={{
          program: null,
          name: "",
          description: "",
          code: "",
          ministry: user?.ministry || null,
          department: user?.department || null,
          branch: user?.profile?.branch || null,
          rmk: rmkActiveApplication,
        }}
      />
      <Card
        className='mb-0 remove-outline-item'
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <Card.Header className='pb-0'>
          <Card.Title>Maklumat Asas</Card.Title>
        </Card.Header>
        <Card.Body className='pt-2'>
          <Form
            noValidate
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Form.Group className='mb-3'>
              <Form.Label>Kementerian</Form.Label>

              <Select
                name='ministry_id'
                isSearchable
                options={ministries}
                placeholder='Pilih Kementerian...'
                value={formik.values.ministry_id}
                onChange={(value) => handleChangeMinistry(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled={!isSuperAdmin}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                    backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                  }),
                }}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Jabatan (Jika berkaitan)</Form.Label>
              <Select
                name='department_id'
                isSearchable
                isClearable
                options={departmentOptions}
                styles={customStylesForm}
                placeholder='Pilih Jabatan'
                value={departmentValue}
                onChange={(value) => handleChangeDepartment(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled={!isSuperAdmin}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                    backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                  }),
                }}
              />
            </Form.Group>

            {showBranchInput && (
              <Form.Group className='mb-3'>
                <Form.Label>Cawangan (Jika berkaitan)</Form.Label>

                <Select
                  name='branch'
                  isSearchable
                  isClearable
                  options={branchOptions}
                  styles={customStylesForm}
                  placeholder='Pilih Cawangan'
                  value={formik.values?.branch}
                  onChange={(value) => handleSelectBranch(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                  isDisabled={!isSuperAdmin}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                    }),
                  }}
                />
              </Form.Group>
            )}

            <Form.Group className='mb-3'>
              <Form.Label>
                Program
                <span className='ms-1'>
                  <Tooltip
                    title={
                      <div className='text-start'>Ditentukan kemudian.</div>
                    }
                    placement='right'
                    arrow
                  >
                    <span>
                      <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                    </span>
                  </Tooltip>
                </span>
              </Form.Label>
              <Select
                name='program'
                isSearchable
                isClearable
                options={programOptions}
                placeholder='Pilih Program...'
                value={formik.values.program}
                onChange={(value) => handleSelectProgram(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                    backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                  }),
                }}
              />
              {formik.errors.program_id && (
                <p className='mt-1 custom-feedback-invalid'>
                  {formik.errors.program_id}
                </p>
              )}
            </Form.Group>

            {/* <Form.Group className='mb-3'>
            <div>
              <Form.Label>Projek Utama</Form.Label>
              <span className='ms-1'>
                <Tooltip
                  title={
                    <div className='text-start'>
                      Program yang diluluskan untuk dilaksana oleh Kementerian
                      dalam {rmkActiveApplication?.short_form}, sila tambah
                      program sekiranya tidak tersenarai.
                    </div>
                  }
                  placement='right'
                  arrow
                >
                  <span>
                    <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                  </span>
                </Tooltip>
              </span>
            </div>
            <Select
              name='main_project'
              isSearchable
              options={mainProjectOptions}
              styles={customStylesForm}
              placeholder='Pilih Projek Utama...'
              value={formik.values.main_project}
              onChange={(value) => handleSelectMainProject(value)}
              noOptionsMessage={() => "Tiada pilihan"}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#ced4da",
                  backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                }),
              }}
            />
            {formik.errors.main_project && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.main_project}
              </p>
            )}
          </Form.Group> */}

            <Form.Group className='mb-3'>
              <div>
                <Form.Label>Projek Utama</Form.Label>
                <span className='ms-1'>
                  <Tooltip
                    title={
                      <div className='text-start'>
                        Senarai Program. Sila tambah program sekiranya tidak
                        tersenarai.
                      </div>
                    }
                    placement='right'
                    arrow
                  >
                    <span>
                      <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                    </span>
                  </Tooltip>
                </span>
              </div>
              <div className='d-flex'>
                <Select
                  name='main_project'
                  className='flex-grow-1'
                  isSearchable
                  options={mainProjectOptions}
                  styles={customStylesForm}
                  placeholder='Pilih Projek Utama...'
                  value={formik.values.main_project}
                  onChange={(value) => handleSelectMainProject(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                  isDisabled={isDisabled}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                    }),
                  }}
                />
                <Button
                  onClick={() => setShowMainProjectForm(true)}
                  disabled={isDisabled}
                >
                  <Icon path={mdiPlus} size='18px' />
                </Button>
              </div>
              {/* <NewOptionWithSelect
              placeholder='Sila taip projek utama baharu untuk tambah jika tidak tersenarai...'
              options={mainProjectOptions}
              onChange={(val) => handleSelectMainProject(val)}
              onCreateOption={(val) => handleCreate(val, "created")}
              isLoading={mainProjectInputLoading}
              value={formik.values?.main_project}
            /> */}
              {formik.errors.main_project && (
                <p className='mt-1 custom-feedback-invalid'>
                  {formik.errors.main_project}
                </p>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <div>
                <Form.Label>Keterangan Projek Utama</Form.Label>
                <span className='ms-1'>
                  <Tooltip
                    title={
                      <div className='text-start'>
                        Latar belakang mengenai Program.
                      </div>
                    }
                    placement='right'
                    arrow
                  >
                    <span>
                      <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                    </span>
                  </Tooltip>
                </span>
              </div>
              <Form.Control
                as='textarea'
                name='main_project_description'
                style={{
                  minHeight: 80,
                  maxHeight: 120,
                }}
                value={mainProjectDescriptionValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setMainProjectDescriptionValue(value);
                }}
                disabled
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <div className='mb-2'>
                <div>
                  <Form.Label className='mb-0'>Nama Projek</Form.Label>
                  <span className='ms-1'>
                    <Tooltip
                      title={<div className='text-start'>Nama Projek</div>}
                      placement='right'
                      arrow
                    >
                      <span>
                        <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                      </span>
                    </Tooltip>
                  </span>
                </div>
                <Form.Group controlId='project_name_checkbox'>
                  <Form.Check
                    id='project_name_checkbox'
                    type='checkbox'
                    label={
                      <span className='text-sm'>
                        Sila tandakan jika projek utama adalah nama projek
                      </span>
                    }
                    checked={noSubProject}
                    onChange={() => {
                      setNoSubProject(!noSubProject);
                      if (noSubProject) {
                        formik.setFieldValue("name", "");
                      } else {
                        formik.setFieldValue(
                          "name",
                          formik.values.main_project?.value,
                        );
                      }
                    }}
                    disabled={
                      isEmpty(formik.values?.main_project) || isDisabled
                    }
                  />
                </Form.Group>
              </div>
              <Form.Control
                as='textarea'
                name='name'
                style={{
                  minHeight: 80,
                  maxHeight: 120,
                }}
                value={formik.values.name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={
                  formik.touched.name && !formik.errors.name ? true : false
                }
                isInvalid={
                  formik.touched.name && formik.errors.name ? true : false
                }
                disabled={
                  noSubProject ||
                  isEmpty(formik.values?.main_project) ||
                  isDisabled
                }
                placeholder={
                  isEmpty(formik.values?.main_project) &&
                  "Sila pilih projek utama"
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Kategori Permohonan Projek</Form.Label>
                  {/* {isSuperAdmin ? ( */}
                  <Select
                    name='project_category_id'
                    isSearchable
                    options={categories}
                    placeholder='Pilih kategori projek...'
                    value={formik.values?.project_category_id}
                    onChange={(value) =>
                      formik.setFieldValue("project_category_id", value)
                    }
                    noOptionsMessage={() => "Tiada pilihan"}
                    isDisabled={isSuperAdmin ? false : true || isDisabled}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Kelulusan Projek</Form.Label>
                  <Form.Control
                    type='text'
                    name='approval_stages_to'
                    value={formik.values.approval_stages_to}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                    style={{ minHeight: 38 }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className='mb-3'>
              <div>
                <Form.Label>
                  Kedudukan Semasa Program/Projek (Program/Projek sambungan
                  sahaja)
                </Form.Label>
                <span className='ms-1'>
                  <Tooltip
                    title={
                      <div className='text-start'>
                        Pencapaian Program/Projek semasa RMK sebelumnya.
                      </div>
                    }
                    placement='right'
                    arrow
                  >
                    <span>
                      <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                    </span>
                  </Tooltip>
                </span>
              </div>
              <Form.Control
                as='textarea'
                name='previous_project_progress'
                style={{
                  minHeight: 70,
                  maxHeight: 100,
                }}
                value={formik.values?.previous_project_progress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={project?.project_category_id !== 2 || isDisabled}
              />
              <Form.Control.Feedback type='invalid'>
                {/* {formik.errors.key_performance_indicator} */}
              </Form.Control.Feedback>
            </Form.Group>

            <div className='mb-3'>
              <Form.Label>
                <span className='fst-italic'>
                  Sustainable Development Goals
                </span>{" "}
                (SDG)
              </Form.Label>
              {project?.application_sent ? (
                <Row className=''>
                  {project?.sustainable_development_goals?.map((item, idx) => {
                    return (
                      <Col
                        key={idx}
                        xs={3}
                        sm={2}
                        className='d-flex justify-content-center mb-3'
                      >
                        <div
                          className='position-relative'
                          style={{
                            height: "auto",
                            width: 130,
                          }}
                        >
                          <img
                            className='mb-1 flex-fill w-100 h-100'
                            alt='image_not_found'
                            src={goalIcons[item]}
                          />
                          <div
                            className={`position-absolute`}
                            style={{
                              bottom: 10,
                              right: 10,
                              width: "20%",
                              height: "20%",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#263238",
                            }}
                          >
                            <Icon path={mdiCheck} color='#FFFFFF' />
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <Row className=''>
                  {goals?.map((item, idx) => {
                    const isSelected = (formik.values.goals || []).includes(
                      item.index_no,
                    );
                    const bounceClass = isSelected ? "bounce-in" : "bounce-out";
                    return (
                      <Col
                        key={idx}
                        xs={3}
                        sm={2}
                        className='d-flex justify-content-center mb-3'
                      >
                        <div
                          className='position-relative card-animated'
                          style={{
                            cursor: "pointer",
                            height: "auto",
                            width: 130,
                          }}
                          onClick={() => handleSelectGoal(item)}
                        >
                          <img
                            className='mb-1 flex-fill w-100 h-100'
                            alt='image_not_found'
                            src={goalIcons[item.index_no]}
                          />
                          <div
                            className={`position-absolute ${bounceClass}`}
                            style={{
                              bottom: 10,
                              right: 10,
                              width: "20%",
                              height: "20%",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#263238",
                            }}
                          >
                            <Icon path={mdiCheck} color='#FFFFFF' />
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </div>

            <Form.Group className='mb-3'>
              <div>
                <Form.Label>KPI Program dan Projek</Form.Label>
                <span className='ms-1'>
                  <Tooltip
                    title={
                      <div className='text-start'>
                        Pencapaian Program/Projek semasa RMK sebelumnya.
                      </div>
                    }
                    placement='right'
                    arrow
                  >
                    <span>
                      <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                    </span>
                  </Tooltip>
                </span>
              </div>
              <Form.Control
                as='textarea'
                name='key_performance_indicator'
                style={{
                  minHeight: 70,
                  maxHeight: 100,
                }}
                value={formik.values.key_performance_indicator}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={
                  formik.touched.key_performance_indicator &&
                  !formik.errors.key_performance_indicator
                    ? true
                    : false
                }
                isInvalid={
                  formik.touched.key_performance_indicator &&
                  formik.errors.key_performance_indicator
                    ? true
                    : false
                }
                disabled={isDisabled}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.key_performance_indicator}
              </Form.Control.Feedback>
            </Form.Group>

            <div className='mt-3'>
              <Form.Group className='mb-3'>
                <div>
                  <Form.Label>Bidang Keutamaan</Form.Label>
                  <span className='ms-1'>
                    <Tooltip
                      title={
                        <div className='text-start'>
                          Sila Rujuk Pekeliling{" "}
                          {rmkActiveApplication?.short_form}
                        </div>
                      }
                      placement='right'
                      arrow
                    >
                      <span>
                        <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                      </span>
                    </Tooltip>
                  </span>
                </div>
                <Select
                  name='main_sector_id'
                  isSearchable
                  isClearable
                  options={mainSectorOptions}
                  placeholder='Pilih Bidang Keutamaan...'
                  value={formik.values?.main_sector_id}
                  onChange={(value) => handleSelectSector(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                  isDisabled={isDisabled}
                  onBlur={() => {
                    formik.setFieldTouched("main_sector_id", true);
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                    }),
                  }}
                />
                {formik.errors?.main_sector_id &&
                  formik.touched?.main_sector_id && (
                    <p className='mt-1 custom-feedback-invalid'>
                      {formik.errors?.main_sector_id}
                    </p>
                  )}
              </Form.Group>

              <Form.Label className='mb-0'>
                Keterangan Bidang Keutamaan
              </Form.Label>
              {formik.touched.strategic_directions &&
                formik.errors.strategic_directions && (
                  <p className='my-0 custom-feedback-invalid'>
                    {formik.errors.strategic_directions}
                  </p>
                )}
              {formik.touched?.main_sector_id &&
              formik.errors?.main_sector_id ? (
                <p className='mb-0 custom-feedback-invalid'>
                  Sila pilih Bidang Keutamaan
                </p>
              ) : (
                <>
                  {strategicDirectionOptions?.map((item, idx) => {
                    const isChecked =
                      formik.values?.strategic_directions?.includes(item.id);
                    return (
                      <Form.Group
                        key={item.id}
                        controlId={item.id}
                        className='mt-2'
                      >
                        <Form.Check
                          name='strategic_directions'
                          label={item.name}
                          value={item.id}
                          type='checkbox'
                          disabled={isDisabled}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);

                            const check =
                              formik.values.strategic_directions.includes(
                                value,
                              );

                            if (!check) {
                              const values = [
                                ...formik.values.strategic_directions,
                                value,
                              ];

                              formik.setFieldValue(
                                "strategic_directions",
                                values,
                              );
                            } else {
                              formik.setFieldValue(
                                "strategic_directions",
                                formik.values.strategic_directions.filter(
                                  (item) => item !== value,
                                ),
                              );
                            }
                          }}
                          checked={isChecked}
                        />
                      </Form.Group>
                    );
                  })}
                </>
              )}
            </div>

            {enableBtnSave && (
              <div className='d-flex justify-content-end'>
                {isEmpty(project) && (
                  <div className='me-2 align-self-center'>
                    <Tooltip
                      title={
                        <div className='text-center'>
                          sila click butang cipta profil untuk isi maklumat
                          projek selebihnya
                        </div>
                      }
                      placement='left'
                      arrow
                    >
                      <span>
                        <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                      </span>
                    </Tooltip>
                  </div>
                )}

                <Button
                  className='align-center px-3'
                  type='submit'
                  variant='primary'
                  disabled={btnLoading ? true : false}
                  style={{ width: 98 }}
                >
                  {btnLoading ? (
                    <Icon
                      className=''
                      path={mdiLoading}
                      size={0.8}
                      spin={true}
                    />
                  ) : (
                    <span style={{ fontSize: 12 }}>
                      {isEdit ? "Kemas Kini" : "Cipta Profil"}
                    </span>
                  )}
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default BasicInfo;
