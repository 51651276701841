import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEmpty } from "lodash";
import Select from "react-select";
import { customStylesForm } from "../../../components/common/customStyleSelect";
import axios from "axios";
import { Tooltip } from "@mui/material";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

// Components
import ModalDelete from "../../../components/common/ModalDelete";
import MainProjects from "./components/tables/MainProjects";
import MainProjectForm from "./components/modals/MainProjectForm";
import MoreSearchField from "./components/MoreSearchField";

// Redux
import {
  getMinistries,
  getDepartments,
  getBranches,
  getPrograms,
  getRmkItems,
} from "../../../redux/slices/utilsSlice";
import {
  fetchMainProjects,
  removeMainProjects,
  setKeywords,
} from "../../../redux/slices/rmk-information/mainProjectsSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});

  const [programValue, setProgramValue] = useState(null);
  const [showCanvas, setShowCanvas] = useState(false);
  const [programFilter, setProgramFilter] = useState(null);
  const [rmkFilter, setRmkFilter] = useState(null);
  const [searchFields, setSearchFields] = useState(null);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);

  const { btnLoading, programs, rmkItems, activeRmk, keywords } = useSelector(
    (state) => ({
      keywords: state.mainProjectReducer.keywords,
      btnLoading: state.mainProjectReducer.btnLoading,
      programs: state.utilsReducer.dropdownItems.programs,
      rmkItems: state.utilsReducer.dropdownItems.rmkItems,
      activeRmk: state.rmkReducer.activeRmk,
    }),
  );

  const handleSelectProgram = (value) => {
    setProgramValue(value);
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setItem(null);
    setIsEdit(false);
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    setItem({});
    setIsEdit(false);
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeMainProjects(data.id));
    // handleNotification("error", data);
    handleCloseRemove();
  };

  const handleFilterProgram = (value) => {
    setProgramFilter(value);
  };

  const handleFilterRmk = (value) => {
    setRmkFilter(value);
  };

  const goToPage = debounce((value) => {
    setPage(value);
  }, 800);

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToLastPage = (page) => {
    setPage(page);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleSearchMoreField = (values) => {
    setSearchFields(values);
    setPage(1);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        rmk_id: rmkFilter?.number,
      },
    };

    Promise.all([
      dispatch(getPrograms(props)),
      dispatch(getMinistries(props)),
      dispatch(getDepartments(props)),
      dispatch(getBranches(props)),
      dispatch(getRmkItems(props)),
    ])
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });

    const props2 = {
      source: source,
      params: {
        page: page,
        per_page: perPage,
        keywords: keywords,
        program_id: programFilter?.id,
        rmk_id: rmkFilter?.number,
        ministry_ids: searchFields?.ministries_code || [],
        department_ids: searchFields?.department_ids || [],
      },
    };

    dispatch(fetchMainProjects(props2));

    return () => {
      source.cancel();
    };
  }, [dispatch, page, keywords, programFilter, rmkFilter, searchFields]);

  useEffect(() => {
    if (isEmpty(activeRmk)) return;

    setRmkFilter(activeRmk);
  }, [activeRmk]);

  return (
    <React.Fragment>
      <MoreSearchField
        showCanvas={showCanvas}
        closeCanvas={() => setShowCanvas(false)}
        searchMoreField={handleSearchMoreField}
      />
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <MainProjectForm
        showForm={openForm}
        closeForm={handleCloseForm}
        data={item}
        isEdit={isEdit}
      />

      <Helmet title='Senarai RMK' />
      <Container fluid className='p-0'>
        <h4>
          {`Senarai Projek Utama ${rmkFilter?.name} : ${rmkFilter?.first_year} - ${rmkFilter?.fifth_year}`}
        </h4>

        <Card className='mt-3'>
          <Card.Header className='pb-0'>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Projek Utama
              </Button>
            </div>
            <div className='d-flex gap-2'>
              <Tooltip title='Lebih Pilihan' placement='top' arrow>
                <Button variant='secondary' onClick={handleShowCanvas}>
                  <Icon path={mdiFilterVariant} size={0.8} />
                </Button>
              </Tooltip>
              <Form.Group>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  options={rmkItems}
                  styles={customStylesForm}
                  placeholder='Pilih RMK...'
                  value={rmkFilter}
                  onChange={(value) => handleFilterRmk(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                />
              </Form.Group>

              {/* <Form.Group style={{ width: 300 }}>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  isClearable
                  options={programs}
                  styles={customStylesForm}
                  placeholder='Pilih Program...'
                  value={programFilter}
                  onChange={(value) => handleFilterProgram(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                />
              </Form.Group> */}
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari...'
                      value={keywords}
                      onChange={(e) => {
                        dispatch(setKeywords(e.target.value));
                        // dispatch(setPage(1));
                        goToFirstPage();
                      }}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <MainProjects
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              perPage={perPage}
              page={page}
              goToPage={goToPage}
              goToFirstPage={goToFirstPage}
              previousPage={previousPage}
              nextPage={nextPage}
              goToLastPage={goToLastPage}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
