import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiCheckCircle } from "@mdi/js";
import { IoAlert } from "react-icons/io5";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";

import { getTargetApi } from "../../../../../utils/common/mock-data";

// Redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";

const ProjectInfoForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  handleNotification,
  project,
  projectId,
  btnLoading,
  enableBtnSave,
}) => {
  const notyf = useContext(NotyfContext);
  const palette = usePalette();
  const dispatch = useDispatch();

  const [targetGroupOptions, setTargetGroupOptions] = useState([]);
  const [checkSection, setCheckSection] = useState(false);
  const [errors, setErrors] = useState({});

  const { applicationStatus, targetGroups, user } = useSelector((state) => ({
    applicationStatus: state.projectReducer.applicationStatus,
    user: state.userReducer.user,
    targetGroups: state.utilsReducer.dropdownItems.groups,
  }));

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // name: project.name || "",
      description: project?.description || "",
      justification: project?.justification || "",
      benefits: project?.benefits || "",
      project_type_id: project?.project_type_id || null,
      target_groups: project?.target_groups.map((item) => item.id) || [],
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required("Masukkan keterangan projek")
        .min(15, "Keterangan projek mesti lebih dari 15 perkataan."),
      benefits: Yup.string()
        .required("Masukkan faedah projek")
        .min(15, "Faedah projek mesti lebih dari 15 perkataan."),
      justification: Yup.string()
        .required("Masukkan justifikasi projek")
        .min(15, "Justifikasi projek mesti lebih dari 15 perkataan."),
    }),
    onSubmit: async (values) => {
      const newValues = {
        id: projectId,
        description: values.description,
        justification: values.justification,
        benefits: values.benefits,
      };
      await dispatch(updateProject(newValues));

      handleNotification("edited", newValues);
      handleChecked(1);
      handleExpand(2);
    },
  });

  useEffect(() => {
    let isMounted = true;

    if (!isMounted) return;

    if (isEmpty(project)) return;

    //Target Groups
    formik.setFieldValue("target_groups", project.target_groups);

    return () => {
      isMounted = false;
    };
  }, [project]);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(1)}
      >
        {/* {isEmpty(formik.errors) ? (
          <Icon
            className='me-1'
            path={mdiCheckCircle}
            color={palette.success}
            style={{ height: 28, width: 28 }}
          />
        ) : ( */}
        <span
          className='number'
          // style={{ color: palette.danger, borderColor: palette.danger }}
        >
          1.
        </span>
        {/* )} */}
        Maklumat Projek
        {!isEmpty(formik.errors) && (
          <IoAlert size={24} color={palette.danger} />
        )}
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Body className='m-0'>
            <Card.Text className='text-sm'>Sila isi maklumat projek.</Card.Text>
            <Form
              noValidate
              autoComplete='off'
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Keterangan Projek *</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='description'
                      style={{
                        minHeight: 75,
                        maxHeight: 95,
                      }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={project?.application_sent}
                      isValid={
                        formik.touched.description && !formik.errors.description
                          ? true
                          : false
                      }
                      isInvalid={
                        formik.touched.description && formik.errors.description
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Faedah Projek</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='benefits'
                      style={{
                        height: 120,
                        minHeight: 120,
                        maxHeight: 200,
                      }}
                      value={formik.values.benefits}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={project?.application_sent}
                      isValid={
                        formik.touched.benefits && !formik.errors.benefits
                          ? true
                          : false
                      }
                      isInvalid={
                        formik.touched.benefits && formik.errors.benefits
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.benefits}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Justifikasi Projek</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='justification'
                      style={{
                        height: 120,
                        minHeight: 120,
                        maxHeight: 200,
                      }}
                      value={formik.values.justification}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={project?.application_sent}
                      isValid={
                        formik.touched.justification &&
                        !formik.errors.justification
                          ? true
                          : false
                      }
                      isInvalid={
                        formik.touched.justification &&
                        formik.errors.justification
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.justification}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {enableBtnSave && (
                <div className='d-flex justify-content-end mt-4'>
                  <Button
                    className='px-3'
                    type='submit'
                    variant='primary'
                    disabled={!isEmpty(project) ? false : true}
                  >
                    {btnLoading ? (
                      <Icon
                        className='mx-3'
                        path={mdiLoading}
                        size={0.8}
                        spin={true}
                      />
                    ) : (
                      <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                        Simpan maklumat projek
                      </span>
                    )}
                  </Button>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectInfoForm;
