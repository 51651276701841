import React, { useState } from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { NineColumns } from "../../../../../components/common/tableLoading";
import { isEmpty } from "lodash";
// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

const MainProjects = ({
  handleEditItem,
  handleRemoveItem,
  page,
  perPage,
  goToPage,
  goToFirstPage,
  previousPage,
  nextPage,
  goToLastPage,
}) => {
  const [pageValue, setPageValue] = useState("1");

  const { isLoading, mainProjects, meta, links, user } = useSelector(
    (state) => ({
      isLoading: state.mainProjectReducer.isLoading,
      mainProjects: state.mainProjectReducer.mainProjects,
      meta: state.mainProjectReducer.meta,
      links: state.mainProjectReducer.links,
      user: state.userReducer.user,
    }),
  );

  const isSuperadminOrAdmin = user.roles.some(
    (role) => role === "superadmin" || role === "admin",
  );

  const groupDataByProgram = (data) => {
    const groupedData = data.reduce((accumulator, currentItem) => {
      const programId = currentItem.program_id;
      if (!accumulator[programId]) {
        accumulator[programId] = {
          program: currentItem.program,
          main_projects: [currentItem],
        };
      } else {
        accumulator[programId].main_projects.push(currentItem);
      }
      return accumulator;
    }, {});
    return Object.values(groupedData);
  };

  const groupedMainProjects = groupDataByProgram(mainProjects);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table hover size='sm'>
          <thead
            className='custom-table-head text-uppercase'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th>Bil</th>
              <th className='text-center' style={{ width: "10%" }}>
                Kod Program & Projek Utama
              </th>
              <th>Program</th>
              <th>Projek Utama</th>
              <th>Keterangan</th>
              <th>Kementerian</th>
              <th>Jabatan / Agensi</th>
              <th>Cawangan</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading ? (
            <NineColumns />
          ) : (
            <React.Fragment>
              {isEmpty(mainProjects) ? (
                <tbody>
                  <tr>
                    <td colSpan={9} className='text-center'>
                      Maklumat tidak tersedia
                    </td>
                  </tr>
                </tbody>
              ) : (
                // <tbody className='border-end border-start'>
                //   {groupedMainProjects
                //     .toSorted(
                //       (a, b) =>
                //         Number(a.program?.ministry_code) -
                //         Number(b.program?.ministry_code),
                //     )
                //     .map((group) => {
                //       const { program } = group;
                //       return (
                //         <React.Fragment key={program?.id}>
                //           <tr className='text-uppercase custom-table-row'>
                //             <td className='text-center'>
                //               <span className='mx-1'>
                //                 {program?.prefix}
                //                 {program?.ministry_code}
                //               </span>
                //               <span className='me-1'>{program?.code}</span>
                //             </td>
                //             <td colSpan={5}> {program?.name}</td>
                //           </tr>
                //           {group.main_projects
                //             .toSorted((a, b) => Number(a.code) - Number(b.code))
                //             .map((item, idx) => (
                //               <tr key={item.id}>
                //                 <td className='text-center px-2'>
                //                   <div
                //                     className='container-fluid rounded-1 illustration'
                //                     style={{ fontWeight: 700 }}
                //                   >
                //                     {item.code}
                //                   </div>
                //                 </td>
                //                 <td>{item.name}</td>
                //                 <td>{item.department?.value}</td>
                //                 <td>{item.branch?.value}</td>
                //                 <td>
                //                   <div className='d-flex justify-content-center'>
                //                     <Tooltip title='Edit' placement='top' arrow>
                //                       <IconButton
                //                         color='info'
                //                         size='small'
                //                         aria-label='Edit'
                //                         onClick={() => handleEditItem(item)}
                //                       >
                //                         <MdModeEditOutline fontSize='large' />
                //                       </IconButton>
                //                     </Tooltip>
                //                     {isSuperadminOrAdmin && (
                //                       <Tooltip
                //                         title='Padam'
                //                         placement='top'
                //                         arrow
                //                       >
                //                         <IconButton
                //                           color='error'
                //                           size='small'
                //                           aria-label='Padam'
                //                           onClick={() => handleRemoveItem(item)}
                //                           disabled={!isSuperadminOrAdmin}
                //                         >
                //                           <MdDelete fontSize='large' />
                //                         </IconButton>
                //                       </Tooltip>
                //                     )}
                //                   </div>
                //                 </td>
                //               </tr>
                //             ))}
                //         </React.Fragment>
                //       );
                //     })}
                // </tbody>
                <tbody>
                  {mainProjects
                    .toSorted((a, b) => Number(a.code) - Number(b.code))
                    .map((item, idx) => (
                      <tr key={item.id}>
                        <td className='text-center'>
                          {(page - 1) * perPage + idx + 1}.
                        </td>
                        <td className='text-center px-2'>
                          <div
                            className='container-fluid rounded-1 illustration'
                            style={{ fontWeight: 700, fontSize: 11 }}
                          >
                            {item.program && (
                              <>
                                <span className='me-1'>
                                  {item.program.prefix}
                                  {item.program.ministry_code}
                                </span>
                                <span className='me-1'>
                                  {item.program.code}
                                </span>
                                {item.code}
                              </>
                            )}
                          </div>
                        </td>
                        <td>{item.program?.name}</td>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>{item.ministry?.short_form}</td>
                        <td>{item.department?.value}</td>
                        <td>{item.branch?.value}</td>
                        <td>
                          <div className='d-flex justify-content-center'>
                            <Tooltip title='Edit' placement='top' arrow>
                              <IconButton
                                color='info'
                                size='small'
                                aria-label='Edit'
                                onClick={() => handleEditItem(item)}
                              >
                                <MdModeEditOutline fontSize='large' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Padam' placement='top' arrow>
                              <IconButton
                                color='error'
                                size='small'
                                aria-label='Padam'
                                onClick={() => handleRemoveItem(item)}
                              >
                                <MdDelete fontSize='large' />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </React.Fragment>
          )}
        </Table>
      </div>
      <div className='d-flex justify-content-between pt-3'>
        <div>
          <span className=''>
            Halaman{" "}
            <strong>
              {meta.current_page} daripada {meta.last_page}
            </strong>
          </span>
          <span className='ms-3 me-2'>Pergi ke halaman:</span>
          <Form.Control
            className='d-inline-block'
            value={pageValue}
            onChange={(e) => {
              const value = Number(e.target.value);
              setPageValue(e.target.value);
              if (
                isEmpty(e.target.value) ||
                value <= 0 ||
                value > meta.last_page ||
                isNaN(value)
              )
                return false;

              goToPage(value);
            }}
            style={{ width: "75px" }}
          />
        </div>
        <div>
          <Pagination className='float-end remove-outline-item'>
            <Pagination.First
              onClick={() => goToFirstPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={links.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => goToLastPage(meta.last_page)}
              disabled={links.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainProjects;
