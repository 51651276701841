import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, Form, Modal, Placeholder } from "react-bootstrap";
import Select from "react-select";
import { isEmpty, last } from "lodash";
import { useDispatch, useSelector } from "react-redux";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// redux
import {
  getLastCode,
  createMainProject,
  updateMainProject,
  resetLastCode,
} from "../../../../../redux/slices/rmk-information/mainProjectsSlice";
import { Skeleton } from "@mui/material";

const MainProjectForm = ({ showForm, closeForm, isEdit, data }) => {
  const dispatch = useDispatch();

  const [programOptions, setProgramOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [lastCodeValue, setLastCodeValue] = useState("");

  const {
    ministries,
    departments,
    branches,
    lastCode,
    btnLoading,
    programs,
    rmkItems,
  } = useSelector((state) => ({
    ministries: state.utilsReducer.dropdownItems.ministries,
    departments: state.utilsReducer.dropdownItems.departments,
    programs: state.utilsReducer.dropdownItems.programs,
    branches: state.utilsReducer.dropdownItems.branches,
    lastCode: state.mainProjectReducer.lastCode,
    btnLoading: state.mainProjectReducer.btnLoading,
    rmkItems: state.utilsReducer.dropdownItems.rmkItems,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      program: data?.program || null,
      name: data?.name || "",
      description: data?.description || "",
      code: data?.code || "",
      ministry: data?.ministry || null,
      department: data?.department || null,
      branch: data?.branch || null,
      rmk: data?.rmk || null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Sila isi nama program"),
      code: Yup.string()
        .required("Sila isi kod projek utama")
        .min(4, "Kod tidak boleh kurang daripada 4 aksara")
        .max(4, "Kod tidak boleh lebih dari 4 aksara"),
      rmk: Yup.object().required().typeError("Sila pilih RMK"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          program_id: values.program?.id || null,
          name: values.name,
          code: values.code,
          description: values.description,
          ministry_id: values.ministry?.code || null,
          department_id: values.department?.id || null,
          branch_id: values.branch?.id || null,
          rmk_id: values?.rmk?.number,
        };
        await dispatch(updateMainProject(newValues));
      } else {
        const newValues = {
          program_id: values.program?.id || null,
          name: values.name,
          code: values.code,
          description: values.description,
          rmk_id: values?.rmk?.number,
          ministry_id: values.ministry?.code || null,
          department_id: values.department?.id || null,
          branch_id: values.branch?.id || null,
        };
        await dispatch(createMainProject(newValues));
      }
      handleCloseForm();
    },
  });

  const handleCloseForm = () => {
    closeForm();
    formik.resetForm();
    dispatch(resetLastCode());
  };

  const handleSelectMinistry = (value) => {
    formik.setFieldValue("ministry", value);

    const newOptions = departments.filter(
      (dept) => Number(dept.ministry_code) === Number(value.code),
    );
    setDepartmentOptions(newOptions);
  };

  const handleSelectProgram = async (value) => {
    formik.setFieldValue("program", value);

    if (isEmpty(value)) {
      dispatch(resetLastCode());
      formik.setFieldValue("code", "");
      return;
    }

    if (!isEdit) {
      const params = {
        program_id: value.id,
      };

      await dispatch(getLastCode(params));
    }

    const newOptions = departments.filter(
      (dept) => dept.ministry_code === Number(value.ministry_code),
    );

    setDepartmentOptions(newOptions);
  };

  const handleSelectDepartment = (value) => {
    formik.setFieldValue("department", value);

    if (isEmpty(value)) return;

    const newOptions = branches.filter(
      (branch) => branch.department_id === Number(value.id),
    );

    setBranchOptions(newOptions);
  };

  const handleSelectBranch = (value) => {
    formik.setFieldValue("branch", value);
  };

  useEffect(() => {
    if (!isEmpty(formik.values.program) && !isEmpty(lastCode)) {
      const suggestedCode = String(parseInt(lastCode, 10) + 1).padStart(
        lastCode.length,
        "0",
      );
      formik.setFieldValue("code", suggestedCode);
    }
  }, [formik.values.program, lastCode]);

  useEffect(() => {
    if (isEmpty(data)) return;
    formik.setFieldValue("code", data.code);
    handleSelectProgram(data.program);
    if (!isEmpty(data.department)) {
      handleSelectDepartment(data.department);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(programs)) {
      const sortedByMinistry = programs.toSorted(
        (a, b) => a.ministry_code - b.ministry_code,
      );

      // Sort within each ministry_code group by code
      const sortedData = sortedByMinistry.slice().sort((a, b) => {
        if (a.ministry_code === b.ministry_code) {
          return a.code.localeCompare(b.code); // Use `localeCompare` for string comparison
        }
        return 0;
      });
      setProgramOptions(sortedData);
    }
  }, [programs]);

  return (
    <Modal
      show={showForm}
      onHide={handleCloseForm}
      centered
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Projek Utama</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>

        <Form
          autoCorrect='off'
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>RMKe</Form.Label>
            <Select
              name='program'
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              styles={customStylesForm}
              options={rmkItems}
              isClearable
              value={formik.values?.rmk}
              onChange={(value) => formik.setFieldValue("rmk", value)}
              noOptionsMessage={() => "Tiada Piihan"}
              isDisabled={isEdit}
            />
            {formik.errors.rmk && isEmpty(formik.values?.rmk) && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.rmk}
              </p>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Program</Form.Label>
            <Select
              name='program'
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              styles={customStylesForm}
              options={programOptions}
              isClearable
              value={formik.values.program}
              onChange={(value) => handleSelectProgram(value)}
              noOptionsMessage={() => "Tiada Piihan"}
            />
            {formik.errors.program && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.program}
              </p>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kod Projek Utama</Form.Label>
            {btnLoading ? (
              <Placeholder as='p' animation='glow'>
                <Placeholder
                  className='rounded-1'
                  xs={12}
                  style={{ height: 28 }}
                />
              </Placeholder>
            ) : (
              <Form.Control
                type='text'
                name='code'
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.code && formik.errors.code ? true : false
                }
                disabled={btnLoading}
              />
            )}

            <Form.Control.Feedback type='invalid'>
              {formik.errors.code}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Projek Utama</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='name'
              style={{ minHeight: 75, maxHeight: 105 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              isInvalid={
                formik.touched.name && formik.errors.name ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Keterangan Projek Utama</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='description'
              style={{ minHeight: 75, maxHeight: 105 }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kementerian</Form.Label>
            <Select
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              styles={customStylesForm}
              options={ministries}
              isClearable
              value={formik.values.ministry}
              onChange={(value) => handleSelectMinistry(value)}
              noOptionsMessage={() => "Tiada Piihan"}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Jabatan / Agensi (Jika berkaitan)</Form.Label>
            <Select
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              styles={customStylesForm}
              options={departmentOptions}
              isClearable
              value={formik.values.department}
              onChange={(value) => handleSelectDepartment(value)}
              noOptionsMessage={() => "Tiada Piihan"}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Cawangan (Jika berkaitan)</Form.Label>
            <Select
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              styles={customStylesForm}
              options={branchOptions}
              isClearable
              value={formik.values.branch}
              onChange={(value) => handleSelectBranch(value)}
              noOptionsMessage={() => "Tiada Piihan"}
            />
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleCloseForm}>
              Batal
            </Button>

            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MainProjectForm;
