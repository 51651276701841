import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// redux
import {
  createSustainableDevelopmentGoal,
  updateSustainableDevelopmentGoal,
} from "../../../../../redux/slices/maintenance/sustainableDevelopmentGoalsSlice";

const SustainableDevelopmentGoalForm = ({
  showForm,
  closeForm,
  data,
  isEdit,
}) => {
  const dispatch = useDispatch();

  const [ministry, setMinistry] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { btnLoading } = useSelector((state) => ({
    btnLoading: state.sdgReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      index_no: data.index_no || "",
      goal: data.goal || "",
      description: data.description || "",
    },
    validationSchema: Yup.object().shape({
      goal: Yup.string().required("Sila isi matlamat"),
      index_no: Yup.number()
        .typeError("Nilai mesti nombor")
        .required("Sila isi nombor index, sila rujuk laman web rasmi SDG"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          goal: values.goal,
          index_no: values.index_no,
          description: values.description,
        };
        await dispatch(updateSustainableDevelopmentGoal(newValues));
        handleCloseModal();
      } else {
        const newValues = {
          goal: values.goal,
          index_no: values.index_no,
          description: values.description,
        };
        await dispatch(createSustainableDevelopmentGoal(newValues));
        handleCloseModal();
      }
    },
  });

  const handleCloseModal = () => {
    closeForm();
    formik.resetForm();
  };

  return (
    <Modal
      show={showForm}
      onHide={handleCloseModal}
      backdrop='static'
      keyboard={false}
      centered
    >
      <Modal.Body>
        <h4>Tambah maklumat pembangunan mampan.</h4>
        <Form
          autoCorrect='off'
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>
              Matlamat{" "}
              <span className='fst-italic text-muted text-sm'>
                (Sila rujuk lawan web rasmi SDG)
              </span>
            </Form.Label>
            <Form.Control
              type='text'
              name='goal'
              value={formik.values.goal}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.goal && !formik.errors.goal ? true : false
              }
              isInvalid={
                formik.touched.goal && formik.errors.goal ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.goal}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>
              Nombor Index{" "}
              <span className='fst-italic text-muted text-sm'>
                (Sila rujuk lawan web rasmi SDG)
              </span>
            </Form.Label>
            <Form.Control
              type='text'
              name='index_no'
              value={formik.values.index_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.index_no && !formik.errors.index_no
                  ? true
                  : false
              }
              isInvalid={
                formik.touched.index_no && formik.errors.index_no ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.index_no}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Keterangan Matlamat (Jika berkaitan)</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='description'
              style={{ minHeight: 75, maxHeight: 105 }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleCloseModal}>
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SustainableDevelopmentGoalForm;
