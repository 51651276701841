import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Form } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEmpty } from "lodash";

// Styles
import NotyfContext from "../../../contexts/NotyfContext";

// Components
import ApplicationStatistics from "./components/ApplicationStatistics";
import Projects from "./components/tables/Projects";
import Filters from "./components/Filters";
import MoreSearchField from "./components/MoreSearchField";
import ModalDelete from "../../../components/common/ModalDelete";
import LoadingState from "../../../components/common/LoadingState";
import axios from "axios";

// Redux
import {
  fetchProjects,
  removeProject,
  setPage,
  setPerPage,
} from "../../../redux/slices/projects/projectsSlice";
import { getMinistries } from "../../../redux/slices/utilsSlice";
import useLocalStorage from "../../../hooks/useLocalStorage";

const container = {
  show: {
    transition: {
      staggerChildren: 0.08,
    },
  },
};

const filterItem = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
};

const item = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
};

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [showCanvas, setShowCanvas] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);
  const [selectedRmk, setSelectedRmk] = useState({});
  // const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchFields, setSearchFields] = useState(null);
  const [stagesFilter, setStagesFilter] = useState(null);
  const [keywords, setKeywords] = useState("");

  const {
    isLoading,
    btnLoading,
    page,
    perPage,
    projects,
    rmkList,
    rmkActiveApplication,
  } = useSelector((state) => ({
    isLoading: state.projectReducer.isLoading,
    btnLoading: state.projectReducer.btnLoading,
    projects: state.projectReducer.projects,

    page: state.projectReducer.pagination.page,
    perPage: state.projectReducer.pagination.perPage,

    rmkList: state.rmkReducer.rmkList,
    rmkActiveApplication: state.rmkReducer.rmkActiveApplication,
  }));

  const handleCloseRemove = () => {
    setShowRemove(false);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeProject(data.id));
    handleNotification("error", data);
  };

  const handleSelectRmk = debounce((rmk) => {
    setSelectedRmk(rmk);
  }, 500);

  const handleChangeStatus = debounce((status) => {
    setSelectedStatus(status);
    dispatch(setPage(1));
  }, 500);

  const gotoPage = (num) => {
    dispatch(setPage(num));
  };

  const handleSearch = debounce((text) => {
    dispatch(setKeywords(text));
    dispatch(setPage(1));
  }, 900);

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleHideCanvas = () => {
    setShowCanvas(false);
  };
  const handleSearchMoreField = (values) => {
    setSearchFields(values);
  };

  const handleChangeStages = (value) => {
    setStagesFilter(value);
  };

  const handleChangePerPage = (e) => {
    dispatch(setPerPage(e.target.value));
    dispatch(setPage(1));
  };

  const goToPage = debounce((value) => {
    dispatch(setPage(value));
  }, 800);

  const goToFirstPage = () => {
    dispatch(setPage(1));
  };

  const goToLastPage = (page) => {
    dispatch(setPage(page));
  };

  const nextPage = () => {
    dispatch(setPage(page + 1));
  };

  const previousPage = () => {
    dispatch(setPage(page - 1));
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!isEmpty(rmkActiveApplication)) {
      const rmkValue = {
        ...rmkActiveApplication,
        label: rmkActiveApplication.short_form,
        value: rmkActiveApplication.short_form,
      };

      const props = {
        source: source,
        params: {
          rmk_number: rmkActiveApplication.number,
          application_status: selectedStatus?.id || null,
          per_page: perPage,
          page: page,
          keywords: keywords,
          ministry_ids: searchFields?.ministries_code || [],
          department_ids: searchFields?.department_ids || [],
          is_federal_projects: searchFields?.isFederalProjects?.value || false,
        },
      };

      Promise.all([
        dispatch(fetchProjects(props)),
        dispatch(getMinistries(props)),
      ])
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }
    return () => {
      source.cancel();
    };
  }, [
    dispatch,
    selectedStatus,
    rmkActiveApplication,
    perPage,
    keywords,
    page,
    searchFields,
  ]);

  useEffect(() => {
    if (!btnLoading) {
      handleCloseRemove();
    }
  }, [btnLoading]);

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <React.Fragment>
      <MoreSearchField
        showCanvas={showCanvas}
        closeCanvas={handleHideCanvas}
        searchMoreField={handleSearchMoreField}
      />

      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <Helmet title='Status Permohonan Daftar Projek' />
      <Container fluid className='p-0'>
        <h4 className=''>Status Permohonan Projek</h4>
        <motion.div variants={container} initial='hidden' animate='show'>
          {/* <ApplicationStatistics item={item} /> */}
          <Filters
            filterItem={filterItem}
            handleSearch={handleSearch}
            handleShowCanvas={handleShowCanvas}
            handleChangeStages={handleChangeStages}
            handleChangeStatus={handleChangeStatus}
            stagesFilter={stagesFilter}
            selectedStatus={selectedStatus}
          />
          <Projects
            filterItem={filterItem}
            projects={projects}
            handleRemoveItem={handleRemoveItem}
            handleSelectRmk={handleSelectRmk}
            selectedRmk={selectedRmk}
            handleChangeStatus={handleChangeStatus}
            selectedStatus={selectedStatus}
            page={page}
            perPage={perPage}
            goToPage={goToPage}
            goToFirstPage={goToFirstPage}
            previousPage={previousPage}
            nextPage={nextPage}
            goToLastPage={goToLastPage}
            handleChangePerPage={handleChangePerPage}
          />
        </motion.div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
