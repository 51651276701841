import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import numeral from "numeral";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { TweleColumns } from "../../../../../components/common/tableLoading";

// Icons
import { MdDelete, MdEdit } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFormatColumns, mdiMenuSwap, mdiMenuDown, mdiMenuUp } from "@mdi/js";

// Components
import ToggleColumn from "../ToggleColumn";
import Default from "./body/Default";
import Grouped from "./body/Grouped";
import ProjectPagination from "./ProjectPagination";

// Redux
import {
  setIsLoadingProjects,
  setPage,
  setPerPage,
} from "../../../../../redux/slices/reports/projectReportsSlice";

const Projects = ({
  goToPage,
  handleChangePerPage,
  goToFirstPage,
  previousPage,
  nextPage,
  goToLastPage,
  clickCount,
  columnNo,
  yearValue,
}) => {
  const routeParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { stages } = routeParams;

  const [pageValue, setPageValue] = useState("");
  const [showCanvas, setShowCanvas] = useState(false);
  const [showColumn, setShowColumn] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [tableData, setTableData] = useState([]);

  const { isLoading, projects, selectDraft } = useSelector((state) => ({
    isLoading: state.reportReducer.isLoading.projects,
    projects: state.reportReducer.projects,
    selectDraft: state.reportReducer.selectDraft,
  }));

  const { data, page, perPage, keywords } = projects;

  const handleViewProject = (projectId) => {
    navigate(`/laporan/kemaskini-kemajuan-projek/${projectId}`);
  };

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleSort = (key) => {
    setSortKey(key);
  };

  // Change page
  const paginate = (pageNumber) => {
    dispatch(setPage(pageNumber));
  };

  useEffect(() => {
    const filterNestedData = (data, searchTerm, isDraft) => {
      const lowercasedSearchTerm = searchTerm.toLowerCase();

      return data
        .map((program) => {
          // Filter main_projects within each program
          let filteredProgram = { ...program };
          if (program.main_projects) {
            filteredProgram.main_projects = program.main_projects
              .map((mainProject) => {
                // Filter projects within each mainProject
                let filteredMainProject = { ...mainProject };
                if (mainProject.projects) {
                  filteredMainProject.projects = mainProject.projects.filter(
                    (project) => {
                      if (selectDraft === false) {
                        const hasSentReport = project.reports?.some(
                          (report) => report.is_sent === isDraft,
                        );

                        return (
                          hasSentReport &&
                          project.name
                            .toLowerCase()
                            .includes(lowercasedSearchTerm)
                        );
                      } else {
                        return project.name
                          .toLowerCase()
                          .includes(lowercasedSearchTerm);
                      }
                    },
                  );
                }
                // Only keep the mainProject if it has any projects left
                return filteredMainProject.projects &&
                  filteredMainProject.projects.length > 0
                  ? filteredMainProject
                  : null;
              })
              .filter((mainProject) => mainProject !== null);
          }

          // Only keep the program if it has any mainProjects left
          return filteredProgram.main_projects &&
            filteredProgram.main_projects.length > 0
            ? filteredProgram
            : null;
        })
        .filter((program) => program !== null);
    };

    const filteredData = filterNestedData(data, keywords, selectDraft);

    setTableData(filteredData);
    dispatch(setIsLoadingProjects());
  }, [data, keywords, selectDraft]);

  return (
    <React.Fragment>
      <ToggleColumn
        showCanvas={showCanvas}
        closeCanvas={() => setShowCanvas(false)}
      />

      <div className='table-responsive'>
        <Table className='report-table' size='sm'>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 11 }}
          >
            <tr>
              <th
                rowSpan={2}
                className='text-center align-middle'
                style={{ width: "6%" }}
              >
                Bil.
              </th>
              <th rowSpan={2} className='text-center align-middle'>
                Kod
              </th>
              <th rowSpan={2} style={{ width: "25%" }} className='align-middle'>
                <div className='d-flex'>
                  Nama
                  {/* <span className='ms-2 align-self-end'>
                    {columnNo !== 1 ? (
                      <Icon
                        path={mdiMenuSwap}
                        size={0.7}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("name", "asc", 1)}
                      />
                    ) : (
                      <>
                        {clickCount === 2 && (
                          <Icon
                            path={mdiMenuUp}
                            size={0.7}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort("name", "desc", 1)}
                          />
                        )}
                        {clickCount === 3 && (
                          <Icon
                            path={mdiMenuDown}
                            size={0.7}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort(null, null, null)}
                          />
                        )}
                      </>
                    )}
                  </span> */}
                </div>
              </th>
              <th rowSpan={2} className='align-middle'>
                <div className='d-flex'>
                  Kementerian
                  {/* <span className='ms-2 align-self-end'>
                    {columnNo !== 2 ? (
                      <Icon
                        path={mdiMenuSwap}
                        size={0.7}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("ministry_id", "asc", 2)}
                      />
                    ) : (
                      <>
                        {clickCount === 2 && (
                          <Icon
                            path={mdiMenuUp}
                            size={0.7}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort("ministry_id", "desc", 2)}
                          />
                        )}
                        {clickCount === 3 && (
                          <Icon
                            path={mdiMenuDown}
                            size={0.7}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort(null, null, null)}
                          />
                        )}
                      </>
                    )}
                  </span> */}
                </div>
              </th>
              <th rowSpan={2} className='align-middle'>
                <div className='d-flex'>
                  Agensi Pelaksana
                  {/* <span className='ms-2 align-self-end'>
                    {columnNo !== 3 ? (
                      <Icon
                        path={mdiMenuSwap}
                        size={0.7}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("executing_agency", "asc", 3)}
                      />
                    ) : (
                      <>
                        {clickCount === 2 && (
                          <Icon
                            path={mdiMenuUp}
                            size={0.7}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSort("executing_agency", "desc", 3)
                            }
                          />
                        )}
                        {clickCount === 3 && (
                          <Icon
                            path={mdiMenuDown}
                            size={0.7}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort(null, null, null)}
                          />
                        )}
                      </>
                    )}
                  </span> */}
                </div>
              </th>
              <th colSpan={3} className='text-center th-column border-top-0'>
                Fizikal
              </th>
              <th colSpan={3} className='text-center th-column border-top-0'>
                Kewangan{!isEmpty(yearValue) && ` Tahun ${yearValue}`}
              </th>
              <th rowSpan={2} className='text-center align-middle'>
                Tindakan
              </th>
            </tr>
            <tr>
              <th className='text-center th-column border-end-0'>
                Tarikh Kemas kini
              </th>
              <th className='text-center th-column border-start-0 border-end-0'>
                Status Kemajuan
              </th>
              <th className='text-end th-column border-start-0'>
                Peratus Keseluruhan Semasa
              </th>

              <th className='text-end th-column border-start-0'>
                Peruntukan
                {isEmpty(yearValue) ? " (Terkumpul)" : ` Tahun ${yearValue}`}
                <br />
                (RM)
              </th>
              <th className='text-end th-column border-start-0'>
                Perbelanjaan Sebenar
                {isEmpty(yearValue) ? " (Terkumpul)" : ` Tahun ${yearValue}`}
                <br />
                (RM)
              </th>
              <th className='text-end th-column border-start-0'>
                Baki Semasa
                {isEmpty(yearValue) ? " (Terkumpul)" : ` Tahun ${yearValue}`}
                <br />
                (RM)
              </th>
            </tr>
          </thead>
          {isLoading ? (
            <TweleColumns />
          ) : (
            <tbody>
              {isEmpty(tableData) ? (
                <tr>
                  <td colSpan={11} className='text-center'>
                    <p className='text-muted mb-0'>Maklumat tidak tersedia</p>
                  </td>
                </tr>
              ) : (
                // <Default
                //   projects={projects}
                //   handleViewProject={handleViewProject}
                //   page={page}
                //   perPage={perPage}
                //   sortKey={sortKey}
                //   showColumn={showColumn}
                // />
                <Grouped
                  yearValue={yearValue}
                  projects={tableData}
                  handleViewProject={handleViewProject}
                  page={page}
                  perPage={perPage}
                  sortKey={sortKey}
                  showColumn={showColumn}
                />
              )}
            </tbody>
          )}
        </Table>
      </div>
      {/* {!isEmpty(projects) && !isLoading && (
        <ProjectPagination
          perPage={perPage}
          totalProjects={tableContent?.length}
          currentPage={page}
          paginate={paginate}
        />
      )} */}
    </React.Fragment>
  );
};

export default Projects;
