import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import ProjectScopes from "./components/tables/ProjectScopes";
import ProjectScopeForm from "./components/modals/ProjectScopeForm";

// Redux
import {
  fetchScopeList,
  removeScope,
  setKeywords,
} from "../../../redux/slices/maintenance/projectScopeSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [goToPage, setGoToPage] = useState("");
  const [keywordsInput, setKeywordsInput] = useState("");

  const { keywords, page, perPage } = useSelector((state) => ({
    page: state.projectScopeReducer.page,
    perPage: state.projectScopeReducer.perPage,
    keywords: state.projectScopeReducer.keywords,
  }));

  const handleCloseForm = () => {
    setOpenForm(false);
    setIsEdit(false);
    setItem({});
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = async (id) => {
    await dispatch(removeScope(id));
  };

  const handleSearchKeywords = () => {
    dispatch(setKeywords(keywordsInput));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    setKeywordsInput(keywords);

    const initialize = async () => {
      const props = {
        source: source,
        params: {
          per_page: perPage,
          page: page,
          keywords: keywords,
        },
      };

      await dispatch(fetchScopeList(props));
    };

    setGoToPage(page);

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch, page, perPage, keywords]);

  return (
    <React.Fragment>
      <ProjectScopeForm
        showForm={openForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        item={item}
      />

      <Helmet title='Senarai Skop Projek' />
      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Penyelenggaraan'
          breadcrumbItem='Senarai Skop Projek'
        />
        <Card className='mt-3'>
          <Card.Header className='d-flex justify-content-between'>
            <div className='d-flex align-items-center gap-2'>
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari nama skop projek...'
                      value={keywordsInput}
                      onChange={(e) => setKeywordsInput(e.target.value)}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>
              <Button variant='secondary' onClick={handleSearchKeywords}>
                <MdSearch className='search-icon-2' size={18} />
              </Button>
            </div>

            <Button onClick={handleOpenForm}>
              <MdAdd /> Skop Projek
            </Button>
          </Card.Header>
          <Card.Body>
            <ProjectScopes
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              goToPage={goToPage}
              setGoToPage={setGoToPage}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
