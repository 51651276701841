import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import WebMap from "@arcgis/core/WebMap";
import Search from "@arcgis/core/widgets/Search";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";

const WebMapArcgis = () => {
  const mapRef = useRef(null);
  const view = useRef(null);
  const credential = sessionStorage.getItem("esriCredential");

  const {
    searchProgressStatus,
    user,
    selectMinistry,
    selectDepartment,
    selectProgram,
  } = useSelector((state) => ({
    user: state.userReducer.user,
    searchProgressStatus: state.dashboardReducer.searchProgressStatus,
    selectMinistry: state.dashboardReducer.selectMinistry,
    selectDepartment: state.dashboardReducer.selectDepartment,
    selectProgram: state.dashboardReducer.selectProgram,
  }));

  const popupProjectInfo = {
    title: "{name}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "name",
            label: "Nama Projek",
            visible: true,
          },
        ],
      },
    ],
  };

  const renderer = {
    type: "simple",
    symbol: {
      type: "picture-marker",
      url: "http://static.arcgis.com/images/Symbols/Basic/WhiteStickpin.png",
      width: "23px",
      height: "23px",
    },
    visualVariables: [
      {
        type: "color",
        field: "progress_status",
        stops: [
          { value: 8, color: "#43a047" },
          { value: 7, color: "#81c784" },
          { value: 6, color: "#90caf9" },
          { value: 5, color: "#2196f3" },
          { value: 4, color: "#ffc107" },
          { value: 3, color: "#f44336" },
          { value: 2, color: "#607d8b" },
          { value: 1, color: "#b0bec5" },
        ],
      },
    ],
  };

  useEffect(() => {
    if (!credential) return; // Wait for authentication to complete

    const clusterConfig = {
      type: "cluster",
      clusterRadius: "100px",
      clusterMinSize: "24px",
      clusterMaxSize: "40px",
      popupTemplate: {
        title: "Ringkasan Kluster",
        content: "Kluster ini mempunyai {cluster_count} projek.",
        fieldInfos: [
          {
            fieldName: "cluster_count",
            format: {
              places: 0,
              digitSeparator: true,
            },
          },
        ],
      },
      labelingInfo: [
        {
          deconflictionStrategy: "none",
          labelExpressionInfo: {
            expression: "Text($feature.cluster_count, '#,###')",
          },
          symbol: {
            type: "text",
            color: "#fafafa",
            font: {
              weight: "bold",
              family: "Noto Sans",
              size: "12px",
            },
          },
          labelPlacement: "center-center",
        },
      ],
    };

    const pointLayer = new FeatureLayer({
      url: "https://smepps-geo.sabah.gov.my/arcmanager/rest/services/vw_projects/FeatureServer",
      renderer,
      popupTemplate: popupProjectInfo,
      outFields: ["name"],
      credential: credential, // Use the authenticated credential
    });

    const districtLayer = new FeatureLayer({
      url: "https://services7.arcgis.com/imUjhC2BWfjR1bCA/arcgis/rest/services/SbhDistrictBdy/FeatureServer/0",
    });

    const getDefinitionExpression = () => {
      let expression = "";
      if (!isEmpty(user.user_access_to_executing_agencies)) {
        const departments = user.user_access_to_executing_agencies
          .map((item) => `department_id = ${item.id}`)
          .join(" OR ");
        expression += `${departments}`;
      }
      if (!isEmpty(user.user_access_to_ministries)) {
        const ministries = user.user_access_to_ministries
          .map((item) => `ministry_id = ${item.code}`)
          .join(" OR ");
        expression += `${ministries}`;
      }
      if (searchProgressStatus) {
        expression += expression ? " AND " : "";
        expression += `progress_status = ${searchProgressStatus}`;
      }
      if (selectMinistry) {
        expression += expression ? " AND " : "";
        expression += `ministry_id = ${selectMinistry}`;
      }
      if (selectDepartment) {
        expression += expression ? " AND " : "";
        expression += `department_id = ${selectDepartment}`;
      }
      if (selectProgram) {
        expression += expression ? " AND " : "";
        expression += `program_id = ${selectProgram}`;
      }
      return expression;
    };

    pointLayer.definitionExpression = getDefinitionExpression();

    const webMap = new WebMap({
      basemap: "streets",
      layers: [districtLayer, pointLayer],
    });

    view.current = new MapView({
      container: mapRef.current,
      map: webMap,
      center: [117.017156, 5.489928],
      zoom: 7.5,
    });

    const searchWidget = new Search({
      view: view.current,
      allPlaceholder: "Daerah",
      includeDefaultSources: false,
      sources: [
        {
          layer: districtLayer,
          searchFields: ["Dist_Name"],
          displayField: "Dist_Name",
          exactMatch: false,
          placeholder: "cth: Kota Kinabalu",
        },
      ],
    });

    view.current.ui.add(searchWidget, {
      position: "top-right",
      index: 2,
    });

    return () => {
      if (view.current) {
        view.current.container = null;
      }
    };
  }, [
    credential,
    searchProgressStatus,
    selectMinistry,
    selectDepartment,
    selectProgram,
  ]);

  return (
    <div
      ref={mapRef}
      style={{ height: "100%", width: "100%", padding: 0, margin: 0 }}
    />
  );
};

export default WebMapArcgis;
