import React from "react";
import { Button, Modal } from "react-bootstrap";

// Map
import WebMapArcgis from "../maps/WebMapArcgis";

const ExpandedMapView = ({ expandMap, closeMap }) => {
  return (
    <Modal
      show={expandMap}
      onHide={closeMap}
      centered
      size='xl'
      keyboard={false}
      backdrop='static'
    >
      <Modal.Body style={{ height: "95vh" }}>
        <WebMapArcgis />
      </Modal.Body>
      <Modal.Footer className='border-top-0'>
        <div className='d-flex justify-content-end gap-3'>
          <Button variant='light' onClick={closeMap}>
            Tutup
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpandedMapView;
