import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";

import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { MdAdd } from "react-icons/md";

// Components
import Reports from "./components/tables/Reports";

// Redux
import {
  createReport,
  showReport,
} from "../../../../redux/slices/reports/projectReportSlice";

const Reporting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const notyf = useContext(NotyfContext);

  const [error, setError] = useState(null);

  const { projectId } = routeParams;
  const { report, btnLoading, project } = useSelector((state) => ({
    report: state.reportReducer.report,
    btnLoading: state.reportReducer.btnLoading,
    project: state.reportReducer.project,
  }));

  const handleMakeReport = async () => {
    const newValues = {
      project_id: projectId,
    };
    await dispatch(createReport(newValues)).then(({ payload }) => {
      const { id } = payload;
      navigate(`/laporan/kemaskini-kemajuan-projek/${projectId}/laporan/${id}`);
    });
  };

  const handleItemReport = (item) => {
    if (error) {
      handleNotification();
    } else {
      const { id } = item;
      navigate(`/laporan/kemaskini-kemajuan-projek/${projectId}/laporan/${id}`);
    }
  };

  const handleGoToProfileProject = () => {
    navigate(`/profil-projek/${project.uuid}`);
  };

  // Notification
  const handleNotification = () => {
    let msg = `Sila isi tarikh jangkaan mula dan siap pada setiap skop projek.`;
    let color = "error";

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    const isNull = project.financial_details
      .flatMap((item) => item.project_scope_details)
      .some((item) => item === null);

    if (isNull) {
      const message = `Sila isi jangkaan tarikh mula dan siap terlebih dahulu sebelum membuat laporan di dalam tab Skop Projek`;

      setError(message);
    }
  }, [project]);

  return (
    <React.Fragment>
      <Card>
        <Card.Header className='pb-0'>
          <Table className='mb-0' size='sm' borderless>
            <tbody>
              <tr>
                <td style={{ width: 120, fontWeight: 700 }}>Program</td>
                <td>{project.program?.label}</td>
              </tr>
              <tr>
                <td style={{ width: 120, fontWeight: 700 }}>Projek Utama</td>
                <td>
                  {project.main_project?.code} {project.main_project?.label}
                </td>
              </tr>
              <tr>
                <td style={{ width: 120, fontWeight: 700 }}>Nama Projek</td>
                <td>
                  {project.code} {project?.name}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className='card-actions float-end'>
            <Tooltip placement='left' title='Buat Laporan Baharu' arrow>
              <span>
                <Button
                  onClick={handleMakeReport}
                  variant='secondary'
                  disabled={btnLoading || (error ? true : false)}
                >
                  {btnLoading ? (
                    <Icon
                      className='mx-4'
                      path={mdiLoading}
                      size={0.8}
                      spin={true}
                    />
                  ) : (
                    <span>
                      <MdAdd /> Laporan
                    </span>
                  )}
                </Button>
              </span>
            </Tooltip>
          </div>
        </Card.Header>
        <Card.Body>
          <Reports handleItemReport={handleItemReport} />
          {error && (
            <p>
              <span className='text-error me-1' style={{ fontSize: "12px" }}>
                ** {error}.
              </span>
              <span
                className='fw-bold'
                onClick={handleGoToProfileProject}
                style={{ cursor: "pointer" }}
              >
                Profil projek
              </span>
            </p>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Reporting;
