import React, { useEffect, useRef } from "react";
import { Button, Card, Form, InputGroup, Modal } from "react-bootstrap";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { isEmpty } from "lodash";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { MdCancel } from "react-icons/md";

// Redux
import {
  createMainSector,
  updateMainSector,
} from "../../../../../redux/slices/rmk-information/stateMainSectorSlice";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Form.Control
    {...field}
    {...props}
    type='text'
    as='textarea'
    style={{ minHeight: 60, maxHeight: 85 }}
  />
);

const MainSectorForm = ({
  showForm,
  closeForm,
  isEdit,
  handleNotification,
  btnLoading,
  data,
}) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const formRef = useRef();

  const { rmkOptions } = useSelector((state) => ({
    rmkOptions: state.utilsReducer.dropdownItems.rmkItems,
  }));

  const handleCloseModal = () => {
    closeForm();
  };

  const initialValues = {
    name: data?.name || "",
    description: data?.description || "",
    rmk: rmkOptions?.find((item) => item.number === data?.rmk_id) || null,
    strategic_directions: data?.strategic_directions || [
      {
        name: "",
      },
    ],
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
    return false;
  };

  const handleSelectRmk = (value) => {
    formRef.current.setFieldValue("rmk", value);
  };

  useEffect(() => {
    if (!btnLoading) {
      handleCloseModal();
    }
  }, [btnLoading]);

  return (
    <Modal
      show={showForm}
      onHide={handleCloseModal}
      keyboard={false}
      backdrop='static'
      centered
    >
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Sektor Utama Negeri</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>

        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            const { rmk, ...newValues } = values;

            if (isEdit) {
              newValues.id = data?.id;
              newValues.rmk_id = values?.rmk?.number;

              await dispatch(updateMainSector(newValues));
            } else {
              newValues.rmk_id = values?.rmk?.number;

              await dispatch(createMainSector(newValues));
              handleNotification("success", newValues);
            }

            resetForm();
          }}
        >
          {(props) => (
            <Form noValidate>
              <Form.Group className='mb-3'>
                <Form.Label>Rancangan Malaysia Ke</Form.Label>
                <Select
                  name='rmk'
                  // className='react-select-container'
                  // classNamePrefix='react-select'
                  isSearchable
                  isClearable
                  placeholder='Pilih RMK'
                  options={rmkOptions}
                  value={props.values?.rmk}
                  onChange={(value) => handleSelectRmk(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                    }),
                  }}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Nama Sektor Utama Negeri</Form.Label>
                <Form.Control
                  as='textarea'
                  type='text'
                  name='name'
                  style={{ minHeight: 75, maxHeight: 105 }}
                  value={props.values.name}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Keterangan Sektor Utama Negeri</Form.Label>
                <Form.Control
                  as='textarea'
                  type='text'
                  name='description'
                  style={{ minHeight: 75, maxHeight: 105 }}
                  value={props.values.description}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Form.Group>

              <Form.Label>Halatuju Strategi</Form.Label>
              <FieldArray name='strategic_directions'>
                {({ insert, remove, push }) => (
                  <div>
                    {!isEmpty(props.values.strategic_directions) ? (
                      props.values.strategic_directions.map((item, index) => (
                        <Form.Group
                          key={index}
                          className='position-relative mb-3'
                        >
                          <Field
                            name={`strategic_directions.${index}.name`}
                            component={CustomInputComponent}
                          />

                          <div
                            className='position-absolute'
                            style={{ top: -11, right: -8 }}
                          >
                            <Tooltip placement='top' title='Padam' arrow>
                              <Link to='#' onClick={() => remove(index)}>
                                <MdCancel size={20} color={palette.danger} />
                              </Link>
                            </Tooltip>
                          </div>
                        </Form.Group>
                      ))
                    ) : (
                      <>maklumat tidak tersedia</>
                    )}
                    <div className='d-flex justify-content-center'>
                      <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => push({ name: "" })}
                      >
                        + Halatuju
                      </Button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>

        <div className='mt-3 d-flex justify-content-end gap-3'>
          <Button variant='light' onClick={handleCloseModal}>
            Batal
          </Button>
          <Button
            className='px-3'
            variant='success'
            onClick={(e) => handleSubmit(e)}
            disabled={btnLoading}
          >
            {btnLoading ? (
              <Icon className='mx-3' path={mdiLoading} size={0.8} spin={true} />
            ) : (
              <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>Simpan</span>
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MainSectorForm;
