import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { customStylesForm } from "../../../../../../components/common/customStyleSelect";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// redux
import {
  getMinistries,
  getDepartments,
} from "../../../../../../redux/slices/utilsSlice";
import {
  createBranch,
  updateBranch,
} from "../../../../../../redux/slices/government-structure/branchSlice";
import axios from "axios";

const BranchForm = ({ showForm, closeForm, data, isEdit }) => {
  const dispatch = useDispatch();

  const [ministry, setMinistry] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { btnLoading, ministries, departments } = useSelector((state) => ({
    btnLoading: state.branchReducer.btnLoading,
    ministries: state.utilsReducer.dropdownItems.ministries,
    departments: state.utilsReducer.dropdownItems.departments,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      description: data.description || "",
      department: data.department || null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Sila isi nama jabatan"),
      department: Yup.object()
        .shape()
        .required("Sila pilih jabatan")
        .nullable(),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          name: values.name,
          description: values.description,
          department_id: values.department?.id,
        };
        await dispatch(updateBranch(newValues));
        handleCloseModal();
      } else {
        const newValues = {
          name: values.name,
          description: values.description,
          department_id: values.department?.id,
        };
        await dispatch(createBranch(newValues));
        handleCloseModal();
      }
    },
  });

  const handleCloseModal = () => {
    closeForm();
    formik.resetForm();
  };

  const handleChangeMinistry = (value) => {
    setMinistry(value);

    formik.setFieldValue("department", null);

    if (isEmpty(value)) {
      setDepartmentOptions([]);
    } else {
      setDepartmentOptions(value?.departments);
    }
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(getMinistries(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  // useEffect(() => {
  //   let isMounted = true;

  //   if (isMounted) {
  //     const ministryValue = {
  //       ...data.department?.ministry,
  //       label: data.department?.ministry?.name,
  //       value: data.department?.ministry?.name,
  //     };
  //     setMinistry(ministryValue);

  //     if (isEmpty(ministryValue)) return;

  //     const { code } = ministryValue;
  //     const ministry_code = Number(code);

  //     const newOptions = departments.filter(
  //       (dept) => dept.ministry_code === ministry_code,
  //     );

  //     setDepartmentOptions(newOptions);

  //     const departmentValue = {
  //       ...data.department,
  //       label: data.department?.name,
  //       value: data.department?.name,
  //     };
  //     formik.setFieldValue("department", departmentValue);
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [data]);

  return (
    <Modal
      show={showForm}
      onHide={handleCloseModal}
      backdrop='static'
      keyboard={false}
      centered
    >
      <Modal.Body>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Nama Cawangan</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.name && !formik.errors.name ? true : false
              }
              isInvalid={
                formik.touched.name && formik.errors.name ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Keterangan Cawangan(Jika berkaitan)</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='description'
              style={{ minHeight: 75, maxHeight: 105 }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kementerian</Form.Label>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              isClearable
              options={ministries}
              styles={customStylesForm}
              placeholder='Sila pilih kementerian...'
              noOptionsMessage={() => "Tiada Pilihan"}
              value={ministry}
              onChange={(value) => handleChangeMinistry(value)}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Jabatan</Form.Label>
            <Select
              name='department'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              isClearable
              options={departmentOptions}
              styles={customStylesForm}
              placeholder='Sila pilih jabatan...'
              noOptionsMessage={() => "Tiada Pilihan"}
              value={formik.values.department}
              onChange={(value) => formik.setFieldValue("department", value)}
            />
            {formik.errors.department && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.department}
              </p>
            )}
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleCloseModal}>
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BranchForm;
