import React, { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Quill from "quill";
import Editor from "../../../../../hooks/Editor";

// Styles
import { customStyles } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";
import usePalette from "../../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiHelpCircle } from "@mdi/js";
import { MdCheckCircle } from "react-icons/md";

// Map
import MapForm from "../maps/MapForm";

// Redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";
import { Tooltip } from "@mui/material";
import { updatePhysicalDetails } from "../../../../../redux/slices/projects/physicalDetailsSlice";

const Delta = Quill.import("delta");

const SiteInformationForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  enableBtnSave,
}) => {
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const palette = usePalette();
  const routeParams = useParams();
  const quillRef = useRef();
  const quillRef1 = useRef(null);
  const quillRef2 = useRef(null);

  const { projectId } = routeParams;

  const [stateLegislativeAssemblyOptions, setStateLegislativeAssemblyOptions] =
    useState([]);
  const [showQuillComponent, setShowQuillComponent] = useState(false);
  const [landUseInput, setlandUseInput] = useState(null);
  const [environmentalImpactAreas, setEnvironmentalImpactAreas] =
    useState(null);
  const [eiaMeasures, setEiaMeasures] = useState(null);

  const { project, parliamentItems, districtItems } = useSelector((state) => ({
    project: state.projectReducer.project,
    districtItems: state.utilsReducer.dropdownItems.districtItems,
    parliamentItems: state.utilsReducer.dropdownItems.parliamentItems,
  }));

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      parliament: project.site_informations?.parliament || null,
      state_legislative_assembly:
        project.site_informations?.state_legislative_assembly || null,
      district: project.site_informations?.district || null,
      geran: project.site_informations?.geran || "",
      reference_no: project.site_informations?.reference_no || "",
      site_ownership: project.site_informations?.site_ownership || "",
      area: project.site_informations?.area || "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values) => {
      const { ...newValuesPhysicalDetails } = values;
      newValuesPhysicalDetails.id = project.physical_details.id;
      newValuesPhysicalDetails.environmental_impact_areas =
        environmentalImpactAreas;
      newValuesPhysicalDetails.eia_measures = eiaMeasures;
      await dispatch(updatePhysicalDetails(newValuesPhysicalDetails));

      const newValues = {
        id: projectId,
        site_information: {
          site_information_id: project.site_informations?.id || null,
          project_id: projectId,
          district_id: values.district?.id || null,
          parliament_id: values.parliament?.id || null,
          state_legislative_assembly_id:
            values.state_legislative_assembly?.id || null,
          reference_no: values.reference_no,
          site_ownership: values.site_ownership,
          geran: values.geran,
          land_use: landUseInput || null,
          area: values?.area,
        },
      };

      await dispatch(updateProject(newValues));
      handleChecked(4);
      handleNotification("success");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  const handleSelectParliament = (value) => {
    formik.setFieldValue("parliament", value);

    if (isEmpty(value)) {
      setStateLegislativeAssemblyOptions([]);
      formik.setFieldValue("state_legislative_assembly", null);
      return;
    }
    const options = value.state_legislative_assemblies.map((item) => {
      return {
        ...item,
        label: `${item.code} - ${item.name}`,
        value: `${item.code} - ${item.name}`,
      };
    });

    setStateLegislativeAssemblyOptions(options);
  };

  // Notification
  const handleNotification = (type, values) => {
    // Errors
    if (type === "danger") {
      const errors = Object.values(values);

      errors.map((item) => {
        const strMsg = item;

        notyf.open({
          type: type,
          message: strMsg,
          duration: 7000,
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "top",
          },
        });
      });
      return false;
    }

    // Success
    notyf.open({
      type: type,
      message: "Maklumat lokasi projek berjaya disimpan.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    if (isEmpty(project)) return;

    setlandUseInput(project?.site_informations?.land_use);
    setEnvironmentalImpactAreas(
      project.physical_details?.environmental_impact_areas,
    );
    setEiaMeasures(project.physical_details?.eia_measures);
    setShowQuillComponent(true);
  }, [project]);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(4)}
      >
        {/* {(passedSteps || []).includes(4) ? (
          <MdCheckCircle className='me-2' size={29} color={palette.success} />
        ) : ( */}
        <span className='number'>4.</span>
        {/* )} */}
        Maklumat Lokasi Projek{" "}
        {project.project_type_id === 3 && "(Tidak diperlukan)"}
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Body className='m-0'>
            <Card.Text className='text-sm'>
              Sila isi maklumat lokasi projek.
            </Card.Text>
            <Form noValidate autoComplete='off'>
              <Row>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Parlimen</Form.Label>
                    <Select
                      isSearchable
                      isClearable
                      name='parliament'
                      options={parliamentItems}
                      placeholder='Pilih...'
                      value={formik.values?.parliament}
                      onChange={(value) => handleSelectParliament(value)}
                      noOptionsMessage={() => "Tiada pilihan"}
                      isDisabled={project?.application_sent}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#ced4da",
                          backgroundColor: state.isDisabled
                            ? "#e2e8ee"
                            : "#FFF",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057", // Change text color to black when disabled
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057", // Change placeholder color to black when disabled
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Dewan Undangan Negeri</Form.Label>
                    <Select
                      isSearchable
                      name='state_legislative_assembly'
                      options={stateLegislativeAssemblyOptions}
                      placeholder='Pilih...'
                      value={formik.values?.state_legislative_assembly}
                      onChange={(value) =>
                        formik.setFieldValue(
                          "state_legislative_assembly",
                          value,
                        )
                      }
                      noOptionsMessage={() => "Tiada pilihan"}
                      isDisabled={project?.application_sent}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#ced4da",
                          backgroundColor: state.isDisabled
                            ? "#e2e8ee"
                            : "#FFF",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057", // Change text color to black when disabled
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057", // Change placeholder color to black when disabled
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Daerah</Form.Label>
                    <Select
                      isSearchable
                      name='district'
                      options={districtItems}
                      placeholder='Pilih...'
                      value={formik.values?.district}
                      onChange={(value) =>
                        formik.setFieldValue("district", value)
                      }
                      isDisabled={project?.application_sent}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#ced4da",
                          backgroundColor: state.isDisabled
                            ? "#e2e8ee"
                            : "#FFF",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057", // Change text color to black when disabled
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057", // Change placeholder color to black when disabled
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>No. Geran Tanah</Form.Label>
                    <Form.Control
                      type='text'
                      name='geran'
                      value={formik.values?.geran}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={project?.application_sent}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      No. Fail Rujukan MyeTAPP/JKPTG/No. Hak Milik
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='reference_no'
                      value={formik.values?.reference_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={project?.application_sent}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group as={Row} className='mb-3'>
                <Col sm={3}>
                  <Form.Label>Pemilikan Tapak</Form.Label>
                </Col>
                <Col sm={9}>
                  <Form.Check
                    inline
                    type='radio'
                    id='ownership_yes'
                    name='site_ownership'
                    label='Ya'
                    className='mb-2'
                    value='true'
                    checked={formik.values?.site_ownership === "true"}
                    onChange={(e) =>
                      formik.setFieldValue("site_ownership", e.target.value)
                    }
                    disabled={project?.application_sent}
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='ownership_no'
                    name='project_design'
                    label='Tidak'
                    className='mb-2'
                    value='false'
                    checked={formik.values?.site_ownership === "false"}
                    onChange={(e) =>
                      formik.setFieldValue("site_ownership", e.target.value)
                    }
                    disabled={project?.application_sent}
                  />
                </Col>
              </Form.Group>

              <Form.Group className='mb-3'>
                <div>
                  <Form.Label>Kawasan</Form.Label>
                  <span className='ms-1'>
                    <Tooltip
                      title={
                        <div className='text-start'>
                          Lokasi projek dilaksanakan, iaitu di bandar, luar
                          bandar atau di kedua-dua tempat.
                        </div>
                      }
                      placement='right'
                      arrow
                    >
                      <span>
                        <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                      </span>
                    </Tooltip>
                  </span>
                </div>
                {[
                  "Dalam Bandar",
                  "Luar Bandar",
                  "Dalam Bandar dan Luar Bandar",
                ].map((option, index) => (
                  <Form.Check
                    key={index}
                    type='radio'
                    id={`area-${index}`} // Unique ID for each radio button
                    label={option}
                    name='area' // Same name for all radio buttons to group them
                    value={option}
                    checked={formik.values.area === option}
                    onChange={(e) =>
                      formik.setFieldValue("area", e.target.value)
                    } // Update form value
                    onBlur={formik.handleBlur}
                    disabled={project?.application_sent}
                  />
                ))}
              </Form.Group>

              {showQuillComponent && (
                <div className='mb-3'>
                  <Form.Group className='mb-3'>
                    <div>
                      <Form.Label>
                        Kawasan Sensitif Alam Sekitar (Jika Berkaitan)
                      </Form.Label>
                    </div>
                    <Form.Label>
                      Nyatakan kawasan sensitif (Sila rujuk{" "}
                      <Link
                        to='#'
                        onClick={(e) => {
                          e.preventDefault();
                          const newTab = window.open(
                            "https://drive.google.com/file/d/163qgZ3L54JlvAgNyujaIFRppP0KtUdlf/view?usp=sharing",
                            "_blank",
                          );

                          if (newTab) {
                            newTab.focus();
                          }
                        }}
                      >
                        Lampiran F
                      </Link>
                      ) yang berada di dalam lingkungan 5 Km radius dengan tapak
                      projek yang dicadangkan.
                      <span className='ms-1 fw-bolder'>(Jika Berkaitan)</span>
                    </Form.Label>
                    <div
                      style={{
                        pointerEvents: project?.application_sent
                          ? "none"
                          : "auto",
                      }}
                    >
                      <Editor
                        ref={quillRef1}
                        readOnly={project?.application_sent}
                        defaultValue={environmentalImpactAreas}
                        onTextChange={setEnvironmentalImpactAreas}
                      />
                    </div>
                  </Form.Group>
                  {/* <div className='mb-3'>
                    <Form.Label>
                      Senaraikan langkah-langkah perlindungan alam sekitar yang
                      berkaitan melalui
                      <span className='fst-italic mx-1'>
                        high-level assessment
                      </span>
                      dengan projek yang dicandangkan.
                      <span className='ms-1 fw-bolder'>(Jika Berkaitan)</span>
                    </Form.Label>
                    <Editor
                      ref={quillRef2}
                      readOnly={project?.application_sent}
                      defaultValue={eiaMeasures}
                      onTextChange={setEiaMeasures}
                    />
                  </div> */}
                </div>
              )}

              {showQuillComponent && (
                <Form.Group className='mb-3'>
                  <div>
                    <Form.Label>Syarat Kegunaan Tanah/Sekatan</Form.Label>
                    <span className='ms-1'>
                      <Tooltip
                        title={
                          <div className='text-start'>
                            Syarat kegunaan tanah yang dinyatakan dalam geran
                            tanah. Contoh: pertanian, perniagaan, komersil.
                            Nyatakan sekiranya ada sekatan terhadap tanah
                            berkenaan seperti kaveat.
                          </div>
                        }
                        placement='right'
                        arrow
                      >
                        <span>
                          <Icon
                            path={mdiHelpCircle}
                            size={0.6}
                            className='m-0'
                          />
                        </span>
                      </Tooltip>
                    </span>
                  </div>

                  <div
                    style={{
                      pointerEvents: project?.application_sent
                        ? "none"
                        : "auto",
                    }}
                  >
                    <Editor
                      ref={quillRef}
                      readOnly={project?.application_sent}
                      defaultValue={landUseInput}
                      onTextChange={setlandUseInput}
                    />
                  </div>
                </Form.Group>
              )}
            </Form>

            <MapForm />

            {enableBtnSave && (
              <div className='d-flex justify-content-end'>
                <Button
                  type='submit'
                  variant='primary'
                  onClick={handleSubmit}
                  // disabled={(passedSteps || []).includes(2) ? false : true}
                >
                  Simpan
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default SiteInformationForm;
