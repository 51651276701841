import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import { setUploadProgress } from "./projectReportsSlice";

export const createReport = createAsyncThunk(
  "reports/createReport",
  async (values) => {
    try {
      const response = await axios.post(`/project-report/create`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const showReport = createAsyncThunk(
  "reports/showReport",
  async (props) => {
    try {
      const { source, reportId } = props;
      const response = await axios.get(`/project-report/show/${reportId}`, {
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const updateReport = createAsyncThunk(
  "reports/updateReport",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/project-report/update/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.error(error);
    }
  },
);

export const removeReport = createAsyncThunk(
  "reports/removeReport",
  async (params, { rejectWithValue }) => {
    try {
      const { project_id, id } = params;
      const response = await axios.post(
        `/project-report/delete/${project_id}/${id}`,
      );

      const data = await response.data;

      return id;
    } catch (error) {
      console.error(error);
      return rejectWithValue({
        message: error.message,
        code: error.code,
        status: error.response?.status,
      });
    }
  },
);

export const storeReportAttachment = createAsyncThunk(
  "reports/storeReportAttachment",
  async (values, { dispatch }) => {
    const { id, temp_id } = values;
    try {
      const response = await axios.post(
        `/project-report/store-attachment/${id}`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch(setUploadProgress({ fileId: temp_id, progress: percent }));
          },
        },
      );

      const data = await response.data.data;

      const result = {
        ...data,
        temp_id: temp_id,
        uploading: false,
      };

      return result;
    } catch (error) {
      console.error(error);
    }
  },
);

export const previewReportAttachments = createAsyncThunk(
  "reports/previewReportAttachments",
  async (props) => {
    try {
      const { source, reportId } = props;

      const response = await axios.get(
        `/project-report/preview-attachments/${reportId}`,
        { cancelToken: source.token },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const removeReportAttachment = createAsyncThunk(
  "reports/removeReportAttachment",
  async (id) => {
    try {
      const response = await axios.delete(
        `/project-report/remove-attachment/${id}`,
      );

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getTotalProgressStatusToDate = createAsyncThunk(
  "reports/getTotalProgressStatusToDate",
  async (props) => {
    try {
      const { params, source } = props;

      const response = await axios.get(`/project-report/progress-status/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
