import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Table, Button, Badge, Card } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import usePalette from "../../../../../hooks/usePalette";
import { Link } from "react-router-dom";
import axios from "axios";

// Icons
import { MdDelete, MdAttachment, MdEdit } from "react-icons/md";

// Components
import SupportDocumentForm from "../modals/SupportDocumentForm";

// Redux
import {
  removeSupportDocument,
  previewSupportDocumentFiles,
} from "../../../../../redux/slices/projects/supportDocumentsSlice";

const SupportDocuments = ({ enableBtnSave }) => {
  const dispatch = useDispatch();

  const palette = usePalette();

  const [showForm, setShowForm] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [data, setData] = useState([]);

  const project = useSelector((state) => state.projectReducer.project);

  const handleCloseForm = () => {
    setShowForm(false);
    setIsEdit(false);
    setEditItem({});
  };

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleRemoveRow = async (item) => {
    await dispatch(removeSupportDocument(item));
  };

  const handleOpenAttachments = (row) => {
    setRowId(row);
    setShowAttachments(true);
  };

  const handleCloseView = () => {
    setShowAttachments(false);
  };

  const handleEditRow = (row) => {
    setIsEdit(true);
    setShowForm(true);
    setEditItem(row);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const response = project.support_documents.map(async (document) => {
      const props = {
        source: source,
        id: document.id,
      };

      try {
        const { payload } = await dispatch(previewSupportDocumentFiles(props));

        const item = {
          ...document,
          attachments: payload,
        };

        return item;
      } catch (error) {
        // Handle errors here if necessary
        console.error("Error fetching file:", error);
        throw error; // Re-throw the error if needed
      }
    });

    Promise.all(response)
      .then((items) => {
        // items is an array containing the results for each document

        setData(items);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      setData([]);
      source.cancel();
    };
  }, [dispatch, project]);

  return (
    <React.Fragment>
      <SupportDocumentForm
        showForm={showForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        projectId={project.id}
        editItem={editItem}
        project={project}
      />

      <Card.Header className='pb-0'>
        {enableBtnSave && (
          <div className='card-actions float-end'>
            <Button size='sm' variant='secondary' onClick={handleOpenForm}>
              + Maklumat
            </Button>
          </div>
        )}

        <Card.Title>Dokumen Sokongan</Card.Title>
        <Card.Text className='text-sm'>
          Sila muat naik dokumen sokongan jika ada.
        </Card.Text>
      </Card.Header>
      <Card.Body className='m-0'>
        <div className='table-responsive'>
          <Table size='sm' style={{ fontSize: 12 }}>
            <thead>
              <tr>
                <th className='text-center' style={{ width: "10%" }}>
                  No.
                </th>
                <th>Tajuk</th>
                <th>Keterangan</th>
                <th className='text-center'>Lampiran</th>
                <th className='text-center' style={{ width: "20%" }}>
                  Tindakan
                </th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(data) ? (
                <tr>
                  <td colSpan={5} className='text-center'>
                    Tiada Maklumat
                  </td>
                </tr>
              ) : (
                <>
                  {data.map((row, idx) => (
                    <tr key={row.id}>
                      <td className='text-center'>{idx + 1}</td>
                      <td>{row.title}</td>
                      <td>{row.description}</td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          {isEmpty(row.attachments) ? (
                            <MdAttachment size={24} />
                          ) : (
                            <React.Fragment>
                              <MdAttachment size={24} color={palette.info} />
                              <Badge
                                bg=''
                                className='badge-soft-info ms-1 align-self-center'
                                style={{ size: 9 }}
                              >
                                {row.attachments.length}
                              </Badge>
                            </React.Fragment>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          <Tooltip
                            title={project?.application_sent ? "Lihat" : "Ubah"}
                            placement='top'
                            arrow
                          >
                            <IconButton
                              color='primary'
                              size='small'
                              aria-label='edit'
                              onClick={() => handleEditRow(row)}
                            >
                              <MdEdit fontSize='large' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Padam' placement='top' arrow>
                            <IconButton
                              color='error'
                              size='small'
                              aria-label='padam'
                              onClick={() => handleRemoveRow(row)}
                              disabled={project?.application_sent}
                            >
                              <MdDelete fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </React.Fragment>
  );
};

export default SupportDocuments;
