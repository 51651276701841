import React, { useEffect, useState } from "react";
import { Button, Card, Form, FormControl, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import format from "date-fns/format";
import ReactDatePicker from "react-datepicker";

// Styles
import usePalette from "../../../../../hooks/usePalette";

import {
  createProjectScopeDetails,
  updateProjectScopeDetails,
} from "../../../../../redux/slices/projects/physicalDetailsSlice";

const ImplementationForm = ({ openForm, closeForm, data }) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [yearOptions, setYearOptions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const monthOptions = [
    "Januari",
    "Februari",
    "Mac",
    "April",
    "Mei",
    "Jun",
    "Julai",
    "Ogos",
    "September",
    "Oktober",
    "November",
    "Disember",
  ];

  const { btnLoading, project } = useSelector((state) => ({
    btnLoading: state.projectReducer.btnLoading,
    project: state.projectReducer.project,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values) => {
      // if (isEmpty(data.project_scope_details)) {
      //   const newValues = {
      //     financial_detail_id: data.id,
      //     ...values,
      //   };
      //   await dispatch(createProjectScopeDetails(newValues));
      // } else {
      const newValues = {
        financial_detail_id: data.id,
        id: data.project_scope_details?.id || null,
        expected_start_date: new Date(startDate),
        expected_completion_date: new Date(endDate),
      };
      await dispatch(updateProjectScopeDetails(newValues));
      // }
      handleCloseModal();
    },
  });

  const handleCloseModal = () => {
    closeForm();
    formik.resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  useEffect(() => {
    let isMounted = true;

    if (!isMounted) return;

    const { rmk } = project;
    const { first_year, second_year, third_year, fourth_year, fifth_year } =
      rmk;

    setYearOptions([
      first_year,
      second_year,
      third_year,
      fourth_year,
      fifth_year,
    ]);

    if (isEmpty(data.project_scope_details)) {
      setStartDate(new Date(`${first_year}-01-01`));
      setEndDate(new Date(`${first_year}-01-01`));
    } else {
      setStartDate(new Date(data.project_scope_details.expected_start_date));
      setEndDate(new Date(data.project_scope_details.expected_completion_date));
    }

    return () => {
      isMounted = false;
    };
  }, [data]);

  return (
    <Modal
      show={openForm}
      onHide={handleCloseModal}
      centered
      keyboard={false}
      backdrop='static'
    >
      <Modal.Body>
        <div>
          <p className='fw-bold fs-4'>Tambah Maklumat Jadual Perlaksanaan</p>
        </div>
        <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Skop Projek</Form.Label>
            <Form.Control
              name='project_scope'
              type='text'
              defaultValue={data.cost_description}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-3 custom-date-range-picker-form'>
            <Form.Label>Tarikh Jangka Mula</Form.Label>
            <div>
              <Form.Control
                as={ReactDatePicker}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className='d-flex justify-content-center mx-1'>
                    <Button
                      className='me-2'
                      variant='secondary'
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </Button>
                    <Form.Select
                      className='me-2'
                      value={new Date(date).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {yearOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Select
                      value={monthOptions[new Date(date).getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(monthOptions.indexOf(value))
                      }
                    >
                      {monthOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>

                    <Button
                      className='ms-2'
                      variant='secondary'
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </Button>
                  </div>
                )}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText='Pilih Tarikh'
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                isInvalid={
                  formik.touched.expected_start_date &&
                  formik.errors.expected_start_date
                    ? true
                    : false
                }
              />
              {/* {formik.touched.expected_start_date &&
                formik.errors.expected_start_date && (
                  <p className='mb-0 custom-feedback-invalid'>
                    {formik.errors.expected_start_date}
                  </p>
                )} */}
            </div>
          </Form.Group>
          <Form.Group className='mb-3 custom-date-range-picker-form'>
            <Form.Label>Tarikh Jangka Siap</Form.Label>
            <div>
              <Form.Control
                as={ReactDatePicker}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className='d-flex justify-content-center mx-1'>
                    <Button
                      className='me-2'
                      variant='secondary'
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </Button>
                    <Form.Select
                      className='me-2'
                      value={new Date(date).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {yearOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Select
                      value={monthOptions[new Date(date).getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(monthOptions.indexOf(value))
                      }
                    >
                      {monthOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>

                    <Button
                      className='ms-2'
                      variant='secondary'
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </Button>
                  </div>
                )}
                selected={endDate}
                minDate={new Date(startDate)}
                onChange={(date) => setEndDate(date)}
                placeholderText='Pilih Tarikh'
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                isInvalid={
                  formik.touched.expected_start_date &&
                  formik.errors.expected_start_date
                    ? true
                    : false
                }
              />
              {/* {formik.touched.expected_start_date &&
                formik.errors.expected_start_date && (
                  <p className='mb-0 custom-feedback-invalid'>
                    {formik.errors.expected_start_date}
                  </p>
                )} */}
            </div>
          </Form.Group>
          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              variant='light'
              onClick={handleCloseModal}
              disabled={btnLoading}
            >
              Batal
            </Button>
            <Button variant='success' type='submit' disabled={btnLoading}>
              Simpan
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ImplementationForm;
