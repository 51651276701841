import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const createProject = createAsyncThunk(
  "project/createProject",
  async (values) => {
    try {
      const response = await axios.post(`/projects`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchProjectDetails = createAsyncThunk(
  "project/fetchProjectDetails",
  async (props) => {
    try {
      const { project_id, source } = props;

      const response = await axios.get(`/projects/${project_id}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

export const getProjectBasicInfo = createAsyncThunk(
  "project/getProjectBasicInfo",
  async (props) => {
    try {
      const { project_id, source } = props;

      const response = await axios.get(`/projects/${project_id}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

export const updateProject = createAsyncThunk(
  "project/updateProject",
  async (values) => {
    const { id } = values;
    try {
      const response = await axios.put(`/projects/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
      throw new Error(error.message || "Failed to update project");
    }
  },
);

export const getApprovedProjects = createAsyncThunk(
  "project/getApprovedProject",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`/approved-projects/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Log the canceled request
        console.log("Request canceled:", error.message);
      } else {
        // Handle other errors
        console.error("Error:", error);
      }
    }
  },
);

export const getApprovedProjectsWithFinancialInformation = createAsyncThunk(
  "project/getApprovedProjectsWithFinancialInformation",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `/approved-projects-with-financial-information/get`,
        {
          params,
          cancelToken: source.token,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Log the canceled request
        console.log("Request canceled:", error.message);
      } else {
        // Handle other errors
        console.error("Error:", error);
      }
    }
  },
);

export const storeAttachment = createAsyncThunk(
  "project/storeAttachment",
  async (values) => {
    try {
      const response = await axios.post(`/attachment/upload`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const previewProjectDesigns = createAsyncThunk(
  "project/previewProjectDesigns",
  async (id) => {
    try {
      const response = await axios.get(`/project-designs/preview/${id}`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const deleteAttachment = createAsyncThunk(
  "project/deleteAttachment",
  async (id) => {
    try {
      const response = await axios.delete(`/attachment/delete/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getMicroProjects = createAsyncThunk(
  "project/getMicroProjects",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`/micro-projects`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getApprovedMicroProjectsStatistics = createAsyncThunk(
  "project/getMicroProjectsApplicationStatus",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(
        `/micro-project/get-approved-micro-projects-statistics`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
