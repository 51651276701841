import Goal1 from "../../assets/img/sdg/E-WEB-Goal-01.png";
import Goal2 from "../../assets/img/sdg/E-WEB-Goal-02.png";
import Goal3 from "../../assets/img/sdg/E-WEB-Goal-03.png";
import Goal4 from "../../assets/img/sdg/E-WEB-Goal-04.png";
import Goal5 from "../../assets/img/sdg/E-WEB-Goal-05.png";
import Goal6 from "../../assets/img/sdg/E-WEB-Goal-06.png";
import Goal7 from "../../assets/img/sdg/E-WEB-Goal-07.png";
import Goal8 from "../../assets/img/sdg/E-WEB-Goal-08.png";
import Goal9 from "../../assets/img/sdg/E-WEB-Goal-09.png";
import Goal10 from "../../assets/img/sdg/E-WEB-Goal-10.png";
import Goal11 from "../../assets/img/sdg/E-WEB-Goal-11.png";
import Goal12 from "../../assets/img/sdg/E-WEB-Goal-12.png";
import Goal13 from "../../assets/img/sdg/E-WEB-Goal-13.png";
import Goal14 from "../../assets/img/sdg/E-WEB-Goal-14.png";
import Goal15 from "../../assets/img/sdg/E-WEB-Goal-15.png";
import Goal16 from "../../assets/img/sdg/E-WEB-Goal-16.png";
import Goal17 from "../../assets/img/sdg/E-WEB-Goal-17.png";

export const goalIcons = [
  null,
  Goal1,
  Goal2,
  Goal3,
  Goal4,
  Goal5,
  Goal6,
  Goal7,
  Goal8,
  Goal9,
  Goal10,
  Goal11,
  Goal12,
  Goal13,
  Goal14,
  Goal15,
  Goal16,
  Goal17,
];
