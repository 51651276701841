import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LoadingState from "../../../../components/common/LoadingState";
import { isEmpty } from "lodash";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";
import parse from "html-react-parser";
import axios from "axios";
import { goalIcons } from "../../../../components/common/sDgs";
import EditorNoTools from "../../../../hooks/EditorNoTools";
import { useReactToPrint } from "react-to-print";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiSend, mdiClose, mdiPrinter } from "@mdi/js";
import { MdAttachment, MdMap } from "react-icons/md";

// Components
import SuccessNotification from "../../../../components/common/SuccessNotification";
import Application from "../../../export/Application";

// redux
import {
  updateProject,
  fetchProjectDetails,
} from "../../../../redux/slices/projects/projectSlice";

const SummaryProjectForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const quillRef1 = useRef(null);
  const quillRef2 = useRef(null);
  const quillRef3 = useRef(null);
  const quillRef4 = useRef(null);
  const quillRef5 = useRef(null);
  const attachmentARef = useRef(null);
  const attachmentBRef = useRef(null);

  const { projectId } = routeParams;

  const [showSuccess, setShowSuccess] = useState(false);
  const [ceilingFlowInfo, setCeilingFlowInfo] = useState([]);
  const [stages, setStages] = useState(0);
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [upenSectionProject, setUpenSectionProject] = useState([]);
  const [errors, setErrors] = useState({});
  const [tooltipTitle, setTooltipTitle] = useState("");
  const [selectedRmk, setSelectedRmk] = useState([]);
  const [fundingSourceValue, setFundingSourceValue] = useState("");
  const [showSendButton, setShowSendButton] = useState(true);
  const [showQuillComponent, setShowQuillComponent] = useState(false);

  const colSpan = 6;

  const { btnLoading, project, user, isLoading } = useSelector((state) => ({
    project: state.projectReducer.project,
    btnLoading: state.projectReducer.btnLoading,
    isLoading: state.projectReducer.isLoading,

    user: state.userReducer.user,
  }));

  const { first_year, second_year, third_year, fourth_year, fifth_year } =
    selectedRmk;

  const groupedByYear = ceilingFlowInfo?.reduce(
    (acc, item) => {
      const { year } = item;

      if (year === first_year) {
        acc[first_year].push(item);
      } else if (year === second_year) {
        acc[second_year].push(item);
      } else if (year === third_year) {
        acc[third_year].push(item);
      } else if (year === fourth_year) {
        acc[fourth_year].push(item);
      } else if (year === fifth_year) {
        acc[fifth_year].push(item);
      }
      return acc;
    },
    {
      [first_year]: [],
      [second_year]: [],
      [third_year]: [],
      [fourth_year]: [],
      [fifth_year]: [],
    },
  );

  const ceilingFlow = Object.entries(groupedByYear).map(([year, data]) => ({
    year: year,
    data,
  }));

  const handleBack = () => {
    navigate(`/permohonan/butiran-projek/${project.uuid}`);
  };

  const handleSubmitApplication = async () => {
    const selected_financial_details = project.financial_details.map(
      (item) => item,
    );
    const selected_ceiling_flow_details = project.financial_details.flatMap(
      (item) => item.ceiling_flow_details,
    );

    const newValues = {
      id: project.uuid,
      application_status: applicationStatus,
      stages: stages,
      selected_financial_details: selected_financial_details,
      selected_ceiling_flow_details: selected_ceiling_flow_details,
      application_sent: true,
      application_sent_by: user.id,
    };
    await dispatch(updateProject(newValues));
    setShowSuccess(true);
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  const printAttachmentA = () => {
    return attachmentARef.current;
  };

  const handlePrintA = useReactToPrint({
    documentTitle: "Lampiran_a",
  });

  const printAttachmentB = () => {
    return attachmentBRef.current;
  };

  const handlePrintB = useReactToPrint({
    documentTitle: "Lampiran_b",
  });

  useEffect(() => {
    if (isEmpty(user)) return;
    if (isEmpty(project)) return;

    const isApprovalOfficer = user?.roles?.includes("ministry_approval_stages");

    if (isApprovalOfficer && project?.stages?.toString() === "3") {
      setShowSendButton(true);
    } else if (project?.application_status?.toString() === "1") {
      setShowSendButton(true);
    } else if (project?.application_status?.toString() === "5") {
      setShowSendButton(true);
    } else {
      setShowSendButton(false);
    }

    setShowQuillComponent(true);
  }, [user, project]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !isEmpty(project)) {
      const {
        financial_details,
        executing_agency,
        financially_responsible_agency,
        owner_agency,
        applicant_agency,
        upen_section,
        stages,
        rmk,
      } = project;

      const updatedArray = financial_details?.map((financial) => {
        const updatedChildren = financial.ceiling_flow_details?.map((flow) => ({
          ...flow,
          financial_detail_id: financial.id,
          cost_description: financial.cost_description,
        }));
        return { ...financial, ceiling_flow_details: updatedChildren };
      });

      if (Number(project.stages) === 1 || Number(project.stages) === 0) {
        setApplicationStatus(2);
        setStages(3);
      }

      if (Number(project.stages) === 2) {
        setApplicationStatus(2);
        setStages(3);
      }

      if (Number(project.stages) === 3) {
        setApplicationStatus(2);
        setStages(4);
      }

      const childrenArray = updatedArray
        ?.map((financial) => financial.ceiling_flow_details)
        ?.reduce((acc, flow) => acc.concat(flow), []);

      setCeilingFlowInfo(childrenArray);

      const errorMessages = {};

      if (isEmpty(financial_details)) {
        errorMessages.financial_details = "Sila isi maklumat kewangan";
      }

      if (isEmpty(executing_agency)) {
        errorMessages.executing_agency = "Sila isi agensi pelaksana";
      }

      if (isEmpty(financially_responsible_agency)) {
        errorMessages.financially_responsible_agency =
          "Sila isi agensi bertanggungjawab dalam bahagian kewangan";
      }

      if (isEmpty(upen_section)) {
        errorMessages.upen_section = "Sila isi seksyen UPEN";
      }

      setErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
      setSelectedRmk(rmk);

      if (project?.funding_sources.includes(1)) {
        setFundingSourceValue("Kumpulan Wang Negeri");
      }
      if (project?.funding_sources.includes(2)) {
        setFundingSourceValue("Pinjaman Persekutuan");
      }
      if (project?.funding_sources.includes(3)) {
        setFundingSourceValue("Bayaran Balik Persekutuan");
      }
    }
    return () => {
      isMounted = false;
    };
  }, [project]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (isEmpty(project)) {
      const props = {
        source: source,
        project_id: projectId,
      };
      dispatch(fetchProjectDetails(props));
    }
    return () => {
      source.cancel();
    };
  }, [dispatch, project]);

  if (isLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <SuccessNotification
        showSuccess={showSuccess}
        handleCloseSuccess={handleCloseSuccess}
        goBackUrl={"/senarai-projek/status-permohonan"}
        primaryMessage={`Permohonan ${project.name}`}
        secondaryMessage={`senarai permohonan`}
      />
      <Card
        style={{
          paddingTop: 40,
          paddingRight: 80,
          paddingLeft: 80,
          paddingBottom: 40,
        }}
      >
        <Card.Body ref={attachmentARef} className='position-relative'>
          <style type='text/css' media='print'>
            {`
              @page { 
                size: A4;
                padding-top: 20mm;
                padding-bottom: 20mm;
                padding-left: 20mm;
                padding-right: 20mm;
              }

              tr {
                page-break-inside: avoid; /* Prevent row splits across pages */
              }

              .page-break {
                page-break-before: always; /* Force page break before the element */
              }
  
              /* Make sure page numbers are shown on each page */
              body {
                font-family: Arial, sans-serif;
                counter-reset: page;
              }
            `}
          </style>
          <h5
            className='position-absolute fw-bolder'
            style={{ top: 15, right: 20 }}
          >
            LAMPIRAN A
          </h5>
          <h5 className='text-center mb-1 mt-3'>
            {`Keterangan Mengenai Maklumat Projek`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-1'>
            {`${project.rmk?.name} : ${first_year}-${fifth_year}`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-5'>
            {"(Pembiayaan Kerajaan Negeri)".toLocaleUpperCase()}
          </h5>

          <Table bordered size='sm' style={{ fontSize: 12 }}>
            <tbody>
              {/* Project Profile */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='text-center' style={{ width: "5%" }}>
                  Bil.
                </td>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Profil
                </th>
                <th className='border-start-0' colSpan={colSpan}></th>
              </tr>
              <tr>
                <td className='text-center'>1</td>
                <td className='fw-bold'>Kementerian</td>
                <td colSpan={colSpan}>{project?.ministry?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>2</td>
                <td className='fw-bold'>Jabatan</td>
                <td colSpan={colSpan}>{project?.department?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>3</td>
                <td className='fw-bold'>Program</td>
                <td colSpan={colSpan}>{project?.program?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>4</td>
                <td className='fw-bold'>Projek Utama</td>
                <td colSpan={colSpan}>{project?.main_project?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>5</td>
                <td className='fw-bold'>Nama Projek</td>
                <td colSpan={colSpan}>{project?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>6</td>
                <td className='fw-bold'>Keterangan Projek</td>
                <td colSpan={colSpan}>{project?.description}</td>
              </tr>
              <tr>
                <td className='text-center'>7</td>
                <td className='fw-bold'>KPI Program dan Projek</td>
                <td colSpan={colSpan}>{project?.key_performance_indicator}</td>
              </tr>
              <tr>
                <td className='text-center'>8</td>
                <td className='fw-bold'>Justifikasi Projek</td>
                <td colSpan={colSpan}>{project?.justification}</td>
              </tr>
              <tr>
                <td className='text-center'>9</td>
                <td className='fw-bold'>
                  Matlamat Pembangunan Mampan
                  <p className='mb-0 text-sm fst-italic'>
                    Sustainable Development Goal (SDG)
                  </p>
                </td>
                <td colSpan={colSpan} className='pt-2'>
                  <Row className=''>
                    {project?.sustainable_development_goals?.map(
                      (item, idx) => {
                        return (
                          <Col key={idx} className='mb-3'>
                            <div
                              style={{
                                height: "auto",
                                width: 64,
                              }}
                            >
                              <img
                                className='mb-1 flex-fill w-100 h-100'
                                alt='image_not_found'
                                src={goalIcons[item]}
                              />
                            </div>
                          </Col>
                        );
                      },
                    )}
                  </Row>
                </td>
              </tr>
              {/* Sustainable Development Goal */}
              {/* {project?.strategic_directions?.map((item, idx) => {
                const lastIndex = project?.strategic_directions.length - 1;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      lastIndex === idx ? "" : "border-bottom-0"
                    } border-top-0`}
                  >
                    <td className='ps-0' colSpan={colSpan}></td>
                  </tr>
                );
              })} */}

              <tr>
                <td className='text-center'>10</td>
                <td className='fw-bold'>Bidang Keutamaan</td>
                <td colSpan={colSpan}>{project?.main_sector?.name}</td>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  11
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  Keterangan Bidang Keutamaan
                </td>
              </tr>
              {/* Strategic directions */}
              {project?.strategic_directions?.map((item, idx) => {
                const lastIndex = project?.strategic_directions.length - 1;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      lastIndex === idx ? "" : "border-bottom-0"
                    } border-top-0`}
                  >
                    <td className='ps-0' colSpan={colSpan}>
                      <ul className='mb-0 pe-0 ps-4'>
                        <li>{item.data?.name}</li>
                      </ul>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className='text-center'>12</td>
                <td className='fw-bold'>Keutamaan Projek</td>
                <td colSpan={colSpan}>{project?.priority}</td>
              </tr>
              <tr>
                <td className='text-center'>13</td>
                <td className='fw-bold'>Kategori Projek</td>
                <td colSpan={colSpan}>
                  {`${project?.project_category?.value} (${project?.project_category?.code})`}
                </td>
              </tr>
              <tr>
                <td className='text-center'>14</td>
                <td className='fw-bold'>Jenis Projek</td>
                <td colSpan={colSpan}>{project?.project_type?.name}</td>
              </tr>

              {/* Financial Infomation */}
              <tr
                className='custom-table-head page-break'
                style={{ height: 40 }}
              >
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Kewangan
                  {errors?.financial_details && (
                    <p className='text-error mb-0'>
                      {errors?.financial_details}
                    </p>
                  )}
                </th>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.financial_details?.length + 2 || 0}
                >
                  15
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.financial_details?.length + 2 || 0}
                >
                  Skop dan Kos Dipohon
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={4}
                >
                  Skop
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={3}
                >
                  Nilai
                  <div>(RM)</div>
                </td>
              </tr>
              {project?.financial_details?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={4}>
                    <span className='ms-4'>{item.cost_description}</span>
                  </td>
                  <td colSpan={3} className='text-center'>
                    {`${numeral(item.cost_required).format("0,0.00")}`}
                  </td>
                </tr>
              ))}
              <tr>
                <td className='text-end' colSpan={4}>
                  Jumlah
                </td>
                <td className='text-center' colSpan={3}>
                  {`${numeral(
                    project?.financial_details?.reduce(
                      (acc, financial) =>
                        acc + parseFloat(financial.cost_required),
                      0,
                    ),
                  ).format("0,0.00")}`}
                </td>
              </tr>
              <tr>
                <td className='text-center' rowSpan={2}>
                  16
                </td>
                <td className='fw-bold' rowSpan={2}>
                  Aliran Siling
                </td>
                {ceilingFlow?.map((item) => (
                  <td
                    key={item.year}
                    className='text-center'
                    style={{ backgroundColor: "#eff2f7" }}
                  >
                    Tahun {item.year}
                    <div>(RM)</div>
                  </td>
                ))}
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Jumlah Siling
                  <div>(RM)</div>
                </td>
              </tr>
              <tr>
                {ceilingFlow?.map((item) => {
                  const amount = item.data.reduce(
                    (acc, flow) => acc + parseFloat(flow.amount),
                    0,
                  );
                  return (
                    <td key={item.year} className='text-center'>
                      {`${numeral(amount).format("0,0.00")}`}
                    </td>
                  );
                })}
                <td className='text-center'>
                  {`${numeral(
                    project?.financial_details
                      ?.flatMap((obj1) =>
                        obj1.ceiling_flow_details?.flatMap((obj2) =>
                          parseFloat(obj2.amount),
                        ),
                      )
                      .reduce((acc, amount) => acc + parseFloat(amount)),
                  ).format("0,0.00")}`}
                </td>
              </tr>

              <tr>
                <td className='text-center'>17</td>
                <td className='fw-bold'>Sumber Pembiayaan</td>
                <td colSpan={colSpan}>{fundingSourceValue}</td>
              </tr>

              {/* Physical Information  */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Fizikal
                </th>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.financial_details?.length + 1 || 0}
                >
                  18
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.financial_details?.length + 1 || 0}
                >
                  Skop Projek
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={3}
                >
                  Skop
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Mula (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Siap (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tempoh
                </td>
              </tr>

              {project?.financial_details?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={3}>{item.cost_description}</td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_start_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_completion_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {item.project_scope_details?.expected_completion_date &&
                      formatDistanceStrict(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        {
                          addSuffix: false,
                          locale: ms,
                        },
                      )}
                  </td>
                </tr>
              ))}

              <tr>
                <td className='text-center'>19</td>
                <td className='fw-bold'>
                  Perancangan Tahap Pelaksanaan {project?.rmk?.short_form}
                </td>
                <td colSpan={colSpan}>
                  {showQuillComponent && (
                    <div
                      style={{
                        pointerEvents: "none",
                      }}
                      tabIndex='-1'
                    >
                      <EditorNoTools
                        ref={quillRef1}
                        readOnly={true}
                        defaultValue={
                          project?.physical_details?.level_of_implementation
                        }
                      />
                    </div>
                  )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>18</td>
                <td className='fw-bold'>Spesifikasi Teknikal</td>
                <td colSpan={colSpan}>
                  {showQuillComponent && (
                    <div
                      style={{
                        pointerEvents: "none",
                      }}
                      tabIndex='-1'
                    >
                      <EditorNoTools
                        ref={quillRef2}
                        readOnly={true}
                        defaultValue={
                          project?.physical_details?.technical_specification
                        }
                      />
                    </div>
                  )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>20</td>
                <td className='fw-bold'>Reka Bentuk</td>
                <td colSpan={colSpan}>
                  {project?.physical_details?.project_design_status === 1 &&
                    "Tidak Diperlukan"}
                  {project?.physical_details?.project_design_status === 2 &&
                    "Belum Disediakan"}
                  {project?.physical_details?.project_design_status === 3 &&
                    "Telah Disediakan"}
                </td>
              </tr>

              <tr>
                <td className='text-center'>21</td>
                <td className='fw-bold'>Tarikh Tender (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_tender_date) &&
                    format(
                      new Date(project.physical_details?.expected_tender_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>22</td>
                <td className='fw-bold'>Tarikh Mula Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_start_date) &&
                    format(
                      new Date(project.physical_details?.expected_start_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>23</td>
                <td className='fw-bold'>Tarikh Siap Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(
                    project.physical_details?.expected_completion_date,
                  ) &&
                    format(
                      new Date(
                        project.physical_details?.expected_completion_date,
                      ),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>24</td>
                <td className='fw-bold'>Keluasan Tapak</td>
                <td colSpan={colSpan}>
                  {numeral(project.physical_details?.site_width).format(
                    "0,0.00",
                  )}{" "}
                  ekar
                </td>
              </tr>

              <tr>
                <td className='text-center'>25</td>
                <td className='fw-bold'>
                  Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
                </td>
                <td colSpan={colSpan}>
                  {project.physical_details?.accordance_with_local_plans
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>

              <tr>
                <td className='text-center'>26</td>
                <td className='fw-bold'>Cadangan Berkaitan Utiliti</td>
                <td colSpan={colSpan}>
                  {project.physical_details?.utility_related ? "Ya" : "Tidak"}
                </td>
              </tr>

              <tr>
                <td className='text-center'>27</td>
                <td className='fw-bold'>Daerah</td>
                <td colSpan={colSpan}>
                  {project.site_informations?.district?.value}
                </td>
              </tr>

              <tr>
                <td className='text-center'>28</td>
                <td className='fw-bold'>Parlimen</td>
                <td colSpan={colSpan}>
                  {project.site_informations?.parliament?.value}
                </td>
              </tr>

              <tr>
                <td className='text-center'>29</td>
                <td className='fw-bold'>Dewan Undangan Negeri (DUN)</td>
                <td colSpan={colSpan}>
                  {project.site_informations?.state_legislative_assembly?.value}
                </td>
              </tr>

              <tr>
                <td className='text-center'>30</td>
                <td className='fw-bold'>Kawasan</td>
                <td colSpan={colSpan}>{project.site_informations?.area}</td>
              </tr>

              <tr>
                <td className='text-center'>31</td>
                <td className='fw-bold'>Kawasan Sensitif Alam Sekitar</td>
                <td colSpan={colSpan}>
                  {showQuillComponent && (
                    <div
                      style={{
                        pointerEvents: "none",
                      }}
                      tabIndex='-1'
                    >
                      <EditorNoTools
                        ref={quillRef3}
                        readOnly={true}
                        defaultValue={
                          project?.physical_details?.environmental_impact_areas
                        }
                      />
                    </div>
                  )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>32</td>
                <td className='fw-bold'>Syarat Kegunaan Tanah/Sekatan</td>
                <td colSpan={colSpan}>
                  {showQuillComponent && (
                    <div
                      style={{
                        pointerEvents: "none",
                      }}
                      tabIndex='-1'
                    >
                      <EditorNoTools
                        ref={quillRef4}
                        readOnly={true}
                        defaultValue={project?.site_informations?.land_use}
                      />
                    </div>
                  )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>33</td>
                <td className='fw-bold'>Geran</td>
                <td colSpan={colSpan}>{project.site_informations?.geran}</td>
              </tr>

              <tr>
                <td className='text-center'>34</td>
                <td className='fw-bold'>
                  No. Fail Rujukan MyeTAPP/KKPTG/No. Hak Milik
                </td>
                <td colSpan={colSpan}>
                  {project.site_informations?.reference_no}
                </td>
              </tr>

              {/* Others */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Lain
                </th>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={
                    !isEmpty(project?.human_resources_requirements)
                      ? project?.human_resources_requirements?.length + 1
                      : 2
                  }
                >
                  35
                </td>
                <td
                  className='fw-bold'
                  rowSpan={
                    !isEmpty(project?.human_resources_requirements)
                      ? project?.human_resources_requirements?.length + 1
                      : 2
                  }
                >
                  Keperluan Sumber Manusia
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Bilangan Pertambahan Perjawatan
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Nama Jawatan
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Gred Jawatan
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={3}
                >
                  Justifikasi
                </td>
              </tr>
              {isEmpty(project.human_resources_requirements) && (
                <tr>
                  <td colSpan={6} className='text-center'>
                    Tiada tambahan staff
                  </td>
                </tr>
              )}
              {project?.human_resources_requirements?.map((item) => (
                <tr key={item.id}>
                  <td className='text-center'>{item.number_of_staff}</td>
                  <td>{item.position_name}</td>
                  <td className='text-center'>{item.position_gred}</td>
                  <td className='text-center' colSpan={3}>
                    {item.justification}
                  </td>
                </tr>
              ))}
              <tr>
                <td className='text-center'>36</td>
                <td className='fw-bold'>
                  Seksyen UPEN
                  {errors?.upen_section && (
                    <p className='mb-0 text-error'>{errors?.upen_section}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.upen_section?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>37</td>
                <td className='fw-bold'>
                  Agensi Permohonan
                  {errors?.applicant_agency && (
                    <p className='mb-0 text-error'>{errors.applicant_agency}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.applicant_agency?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>38</td>
                <td className='fw-bold'>
                  Agensi Pemilik
                  {errors?.owner_agency && (
                    <p className='mb-0 text-error'>{errors.owner_agency}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.owner_agency?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>39</td>
                <td className='fw-bold'>
                  Agensi Bertanggungjawab (Kewangan)
                  {errors?.executing_agency && (
                    <p className='mb-0 text-error'>
                      {errors.financially_responsible_agency}
                    </p>
                  )}
                </td>
                <td colSpan={colSpan}>
                  {project?.financially_responsible_agency?.value}
                </td>
              </tr>
              <tr>
                <td className='text-center'>40</td>
                <td className='fw-bold'>
                  Agensi Pelaksana
                  {errors?.executing_agency && (
                    <p className='mb-0 text-error'>{errors.executing_agency}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.executing_agency?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>41</td>
                <td className='fw-bold'>Maklumat Tambahan</td>
                <td colSpan={colSpan}>
                  {showQuillComponent && (
                    <div
                      style={{
                        pointerEvents: "none",
                      }}
                      tabIndex='-1'
                    >
                      <EditorNoTools
                        ref={quillRef5}
                        readOnly={true}
                        defaultValue={project?.additional_information}
                      />
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card
        style={{
          paddingTop: 40,
          paddingRight: 80,
          paddingLeft: 80,
          paddingBottom: 40,
        }}
      >
        <Card.Body className='position-relative' ref={attachmentBRef}>
          <style type='text/css' media='print'>
            {`
              @page { 
                size: A4;
                padding-top: 20mm;
                padding-bottom: 40mm;
                padding-left: 20mm;
                padding-right: 20mm;
              }

              tr {
                page-break-inside: avoid; /* Prevent row splits across pages */
              }

              .page-break {
                page-break-before: always; /* Force page break before the element */
              }
  
              /* Make sure page numbers are shown on each page */
              body {
                font-family: Arial, sans-serif;
                counter-reset: page;
              }
            `}
          </style>
          <h5
            className='position-absolute fw-bolder'
            style={{ top: 15, right: 20 }}
          >
            LAMPIRAN B
          </h5>
          <h5 className='text-center fw-bolder mb-4 mt-2 text-uppercase'>
            Penyediaan {project?.rmk?.name}
          </h5>
          <h5 className='text-center fw-bolder text-uppercase mb-4'>
            Kandungan Ringkasan Projek / Project Brief
          </h5>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                I.
              </div>
              <div className='text-uppercase fw-bolder'>Pengenalan</div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.introduction}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                II.
              </div>
              <div className='text-uppercase fw-bolder'>
                Nama dan Latar Belakang Projek
              </div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.background}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                III.
              </div>
              <div className='text-uppercase fw-bolder'>Objektif Projek</div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.objective}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                IV.
              </div>
              <div className='text-uppercase fw-bolder'>Maklumat Tapak</div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.site_information}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                V.
              </div>
              <div className='text-uppercase fw-bolder'>Komponen Projek</div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.component_project}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                VI.
              </div>
              <div className='text-uppercase fw-bolder'>
                Anggaran Kos Projek
              </div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.cost_estimation}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                VII.
              </div>
              <div className='text-uppercase fw-bolder'>
                Output dan Faedah yang dijangka daripada Projek
              </div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.expected_output_and_benefit}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                VIII.
              </div>
              <div className='text-uppercase fw-bolder'>Justifikasi Projek</div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.justification}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                VIIII.
              </div>
              <div className='text-uppercase fw-bolder'>
                Keperluan Tenaga Manusia
              </div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.additional_manpower}
              </p>
            </div>
          </div>
          <div className='mb-3'>
            <div className='d-flex flex-col'>
              <div className='fw-bolder mb-2' style={{ width: 50 }}>
                X.
              </div>
              <div className='text-uppercase fw-bolder'>
                Jadual Pelaksanaan dan aliran tunai progran/projek
              </div>
            </div>
            <div className='d-flex flex-col'>
              <p style={{ paddingLeft: 50, textAlign: "justify" }}>
                {project?.project_brief?.implementation_and_cash_flow}
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>

      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-3'>
          <Button
            variant='secondary'
            onClick={() => handlePrintA(printAttachmentA)}
          >
            <Icon className='me-1' path={mdiPrinter} size={0.8} />
            Lampiran A
          </Button>
          <Button
            variant='secondary'
            onClick={() => handlePrintB(printAttachmentB)}
          >
            <Icon className='me-1' path={mdiPrinter} size={0.8} />
            Lampiran B
          </Button>
        </div>

        <div className='d-flex gap-3'>
          <Button variant='light' onClick={handleBack} disabled={btnLoading}>
            Kembali
          </Button>
          {showSendButton && (
            <Tooltip placement='top' title={tooltipTitle} arrow>
              <Button
                variant='success'
                onClick={handleSubmitApplication}
                disabled={btnLoading}
              >
                <Icon
                  className='me-2'
                  path={btnLoading ? mdiLoading : mdiSend}
                  size={0.8}
                  spin={btnLoading}
                />
                {user?.roles?.includes("ministry_approval_stages")
                  ? "Peraku & Hantar"
                  : "Hantar"}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SummaryProjectForm;
