import React, { useEffect, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useParams } from "react-router-dom";
import { debounce, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Components
import LoadingState from "../../../components/common/LoadingState";
import TableProjects from "./components/table/TableProjects";
import Filters from "./components/Filters";
import MoreSearchField from "./components/MoreSearchField";
import ApplicationStatistics from "./components/cards/ApplicationStatistics";

// Redux
import {
  getCerityProjects,
  getCerityStatistics,
} from "../../../redux/slices/certify/projectsSlice";
import {
  setPage,
  setPerPage,
  setStagesFilter,
  setSearchFields,
} from "../../../redux/slices/certify/certifySlice";
import { getMinistries } from "../../../redux/slices/utilsSlice";

const container = {
  show: {
    transition: {
      staggerChildren: 0.08,
    },
  },
};

const filterItem = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
};

const item = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
};

const MainPage = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const { stages } = routeParams;

  const [currentStages, setCurrentStages] = useState("");
  const [certifiedBy, setCertifiedBy] = useState("");
  const [action, setAction] = useState("");
  const [stagesName, setStagesName] = useState("");
  const [showCanvas, setShowCanvas] = useState(false);

  const [statusFilter, setStatusFilter] = useState(null);

  const [keywords, setKeywords] = useState("");
  // const [searchFields, setSearchFields] = useState(null);

  const {
    projects,
    page,
    perPage,
    stagesFilter,
    searchFields,
    rmkActiveApplication,
  } = useSelector((state) => ({
    projects: state.certifyReducer.projects,
    page: state.certifyReducer.paginations.page,
    perPage: state.certifyReducer.paginations.perPage,
    rmkActiveApplication: state.rmkReducer.rmkActiveApplication,
    stagesFilter: state.certifyReducer.filters.stagesFilter,
    searchFields: state.certifyReducer.filters.searchFields,
  }));

  const handleSearch = debounce((text) => {
    setKeywords(text);
  }, 900);

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleHideCanvas = () => {
    setShowCanvas(false);
  };

  const goToPage = debounce((value) => {
    dispatch(setPage(value));
  }, 800);

  const goToFirstPage = () => {
    dispatch(setPage(1));
  };

  const goToLastPage = (page) => {
    dispatch(setPage(page));
  };

  const nextPage = () => {
    dispatch(setPage(page + 1));
  };

  const previousPage = () => {
    dispatch(setPage(page - 1));
  };

  const handleChangePerPage = (e) => {
    dispatch(setPerPage(e.target.value));
    dispatch(setPage(1));
  };

  const handleChangeStages = (value) => {
    dispatch(setStagesFilter(value));
    dispatch(setPage(1));
  };

  const handleChangeStatus = (value) => {
    setStatusFilter(value);
    dispatch(setPage(1));
  };

  const handleSearchMoreField = (values) => {
    dispatch(setSearchFields(values));
    dispatch(setPage(1));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
      params: {
        rmk_id: rmkActiveApplication.number,
        page: page,
        per_page: perPage,
        keywords: keywords,
        stages: stagesFilter?.id || null,
        application_status: statusFilter?.id || null,
        ministry_ids: searchFields?.ministries_code || [],
        department_ids: searchFields?.department_ids || [],
      },
    };

    Promise.all([
      dispatch(getCerityProjects(props)),
      dispatch(getMinistries(props)),
    ]).catch((error) => {
      console.log(error);
    });

    return () => {
      source.cancel();
    };
  }, [
    dispatch,
    page,
    perPage,
    keywords,
    statusFilter,
    stagesFilter,
    searchFields,
  ]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        rmk_id: rmkActiveApplication.number,
        page: page,
        per_page: perPage,
        keywords: keywords,
        stages: stagesFilter?.id || null,
        application_status: statusFilter?.id || null,
        ministry_ids: searchFields?.ministries_code || [],
        department_ids: searchFields?.department_ids || [],
      },
    };

    Promise.all([dispatch(getCerityStatistics(props))])
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });

    return () => {
      source.cancel();
    };
  }, [
    dispatch,
    keywords,
    statusFilter,
    stagesFilter,
    searchFields,
    rmkActiveApplication,
  ]);

  return (
    <React.Fragment>
      <MoreSearchField
        showCanvas={showCanvas}
        closeCanvas={handleHideCanvas}
        searchMoreField={handleSearchMoreField}
      />

      <Helmet title='Perakuan | Senarai Projek' />

      <Container fluid className='p-0'>
        <motion.div variants={container} initial='hidden' animate='show'>
          <Filters
            filterItem={filterItem}
            handleSearch={handleSearch}
            handleShowCanvas={handleShowCanvas}
            handleChangeStages={handleChangeStages}
            handleChangeStatus={handleChangeStatus}
            stagesFilter={stagesFilter}
            statusFilter={statusFilter}
          />

          <ApplicationStatistics />

          <Card>
            <Card.Body>
              <p className='text-sm'>
                Senarai projek yang sedang menunggu diperaku.
              </p>
              <TableProjects
                item={item}
                projects={projects}
                perPage={perPage}
                page={page}
                goToPage={goToPage}
                goToFirstPage={goToFirstPage}
                previousPage={previousPage}
                nextPage={nextPage}
                goToLastPage={goToLastPage}
                handleChangePerPage={handleChangePerPage}
              />
            </Card.Body>
          </Card>
        </motion.div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
