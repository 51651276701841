import React, { useState } from "react";
import { format } from "date-fns";
import { Tab, Table } from "react-bootstrap";

import Icon from "@mdi/react";
import { mdiFileDocument } from "@mdi/js";
import { IconButton } from "@mui/material";
import JPPNNewProject from "../../modals/JPPNNewProject";

const ApplyNewProject = ({ eventKey, project }) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <React.Fragment>
      <Tab.Pane eventKey={eventKey}>
        <JPPNNewProject
          show={showDetails}
          onHide={() => setShowDetails(false)}
          project={project}
        />

        <div className='d-flex justify-content-center'>
          <Table size='sm' bordered style={{ width: 700 }}>
            <tbody>
              <tr>
                <th style={{ width: 160 }}>Rujukan</th>
                <td>{project?.apply_with_jppn?.reference}</td>
              </tr>
              <tr>
                <th style={{ width: 160 }}>Diluluskan pada</th>
                <td>
                  {project?.apply_with_jppn &&
                    format(
                      new Date(project?.apply_with_jppn?.approved_at),
                      "dd-MM-yyyy",
                    )}
                </td>
              </tr>
              {/* <tr>
              <th className='align-middle ' style={{ width: 160 }}>
                Maklumat Lanjut
              </th>
              <td className='ps-2'>
                <IconButton size='small' onClick={() => setShowDetails(true)}>
                  <Icon path={mdiFileDocument} size='22px' />
                </IconButton>
              </td>
            </tr> */}
            </tbody>
          </Table>
        </div>
      </Tab.Pane>
    </React.Fragment>
  );
};

export default ApplyNewProject;
