import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import axios from "../../../utils/axios";

import {
  createProject,
  fetchProjectDetails,
  updateProject,
  getApprovedProjects,
  getApprovedProjectsWithFinancialInformation,
  getMicroProjects,
  getApprovedMicroProjectsStatistics,
} from "./projectSlice";
import {
  createFinancialDetails,
  updateFinancialDetails,
  removeFinancialDetails,
  createFinancialDetailNote,
  updateFinancialDetailNote,
  removeFinancialDetailNote,
  createCeilingFlow,
  updateCeilingFlow,
  removeCeilingFlow,
  createCeilingFlowNote,
  updateCeilingFlowNote,
  removeCeilingFlowNote,
} from "./financialDetailsSlice";
import {
  updatePhysicalDetails,
  createProjectScopeDetails,
  updateProjectScopeDetails,
} from "./physicalDetailsSlice";
import {
  createHumanResourcesRequirement,
  updatedHumanResourcesRequirement,
  removeHumanResourcesRequirement,
} from "./humanResourcesRequirementSlice";
import {
  createSupportDocument,
  updateSupportDocument,
  removeSupportDocument,
} from "./supportDocumentsSlice";
import { acknowledgedItem } from "./returnApplicationSlice";
import {
  createProjectScopeFinancial,
  createProjectCeilingFlow,
} from "./setProjectScopeFinancial";
import { storeExpenditureData } from "./projectExpenditureData";
import { getNewProjectApplication } from "../jppn/jppnApplyNewProjectSlice";

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(`/projects`, {
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

export const removeProject = createAsyncThunk(
  "projects/removeProject",
  async (id) => {
    try {
      const response = await axios.delete(`/projects/${id}/delete`);

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (props) => {
    try {
      const { params, source, applicationType } = props;

      const response = await axios.get(`/projects/get/${applicationType}`, {
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

const initialState = {
  projects: [],
  links: {},
  meta: {},
  project: {},
  microProjects: {
    isLoading: false,
    data: [],
    meta: null,
    links: null,
    perPage: 50,
    page: 1,
  },
  microProjectsStatistics: null,
  applicationStatus: "",
  applicationStages: "",
  isLoading: false,
  btnLoading: false,
  selectRmk: null,
  searchMinistry: null,
  searchDepartments: [],
  searchMainProjects: [],
  federalProjects: false,
  keywords: "",
  pagination: {
    page: "1",
    perPage: 30,
  },
  scopeFinancialList: [],
  financialCeilingFlow: [],
  projectCost: 0,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    resetProjectsReducer: (state) => initialState,
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setSelectRmk: (state, action) => {
      state.selectRmk = action.payload;
    },
    setSearchMinistry: (state, action) => {
      state.searchMinistry = action.payload;
    },
    setSearchDepartments: (state, action) => {
      state.searchDepartments = action.payload;
    },
    setSearchMainProjects: (state, action) => {
      state.searchMainProjects = action.payload;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setResetSearchInput: (state) => {
      state.searchDepartments = [];
      state.searchMainProjects = [];
    },
    setPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.pagination.perPage = action.payload;
    },
    setSelectFederalProjects: (state, action) => {
      state.federalProjects = action.payload;
    },
    getScopeFinancialList: (state, action) => {
      state.scopeFinancialList = action.payload;
    },
    createScopeFinancial: (state, action) => {
      const newObj = action.payload;
      state.scopeFinancialList = [...state.scopeFinancialList, newObj];
    },
    updateScopeFinancial: (state, action) => {
      const { id } = action.payload;

      state.scopeFinancialList = state.scopeFinancialList.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
    },
    deleteScopeFinancial: (state, action) => {
      const id = action.payload;
      state.scopeFinancialList = state.scopeFinancialList.filter(
        (item) => item.id.toString() !== id.toString(),
      );
    },
    getFinancialCeilingFlowList: (state, action) => {
      state.financialCeilingFlow = action.payload;
    },
    createFinancialCeilingFlow: (state, action) => {
      state.financialCeilingFlow = [
        ...state.financialCeilingFlow,
        action.payload,
      ];
    },
    updateFinancialCeilingFlow: (state, action) => {
      const { id } = action.payload;
      state.financialCeilingFlow = state.financialCeilingFlow.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
    },
    deleteFinancialCeilingFlow: (state, action) => {
      const id = action.payload;

      state.financialCeilingFlow = state.financialCeilingFlow.filter(
        (item) => item.id.toString() !== id.toString(),
      );
    },
    setProjectCost: (state, action) => {
      state.projectCost = action.payload;
    },

    setMicroProjectPage: (state, action) => {
      state.microProjects.page = action.payload;
    },

    setMicroProjectPerPage: (state, action) => {
      state.microProjects.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      const { data, links, meta } = action.payload;
      state.projects = data;
      state.links = links;
      state.meta = meta;
      state.isLoading = false;
    });

    builder.addCase(getProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      const { data, pagination } = action.payload;
      state.projects = data;
      state.links = pagination.links;
      state.meta = pagination.meta;
      state.isLoading = false;
    });

    // Create Project
    builder.addCase(createProject.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.project = action.payload;

      state.applicationStatus = action.payload.application_status_name;

      state.btnLoading = false;
      state.isLoading = false;
    });

    // Get Project
    // builder.addCase(fetchProjectDetails.pending, (state) => {
    //   state.isLoading = true;
    // });
    builder.addCase(getNewProjectApplication.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProjectDetails.fulfilled, (state, action) => {
      state.project = action.payload;
      state.projectCost = action.payload.project_cost;
      state.applicationStatus = action.payload.application_status_name;
      state.applicationStages = Number(action.payload.stages);
      state.isLoading = false;
    });

    // Update Project
    builder.addCase(updateProject.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.project = action.payload;

      state.project_cost = action.payload.project_cost;

      state.applicationStatus = action.payload.application_status_name;
      state.btnLoading = false;
    });

    // Delete Project
    builder.addCase(removeProject.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeProject.fulfilled, (state, action) => {
      state.projects = state.projects.filter(
        (item) => item.id !== action.payload,
      );
      state.btnLoading = false;
    });

    // Financial
    builder.addCase(createFinancialDetails.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createFinancialDetails.fulfilled, (state, action) => {
      state.project.financial_details.push(action.payload);
      state.btnLoading = false;
    });
    builder.addCase(updateFinancialDetails.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateFinancialDetails.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === id) {
            return { ...action.payload };
          }
          return item;
        },
      );
      state.btnLoading = false;
    });
    builder.addCase(removeFinancialDetails.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(removeFinancialDetails.fulfilled, (state, action) => {
      state.project.financial_details = state.project.financial_details.filter(
        (item) => item.id !== action.payload,
      );
      state.btnLoading = false;
    });

    // Financial detail note
    builder.addCase(createFinancialDetailNote.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(createFinancialDetailNote.fulfilled, (state, action) => {
      const { financial_detail_id } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            let newArr = item.financial_detail_notes;
            newArr.push(action.payload);
            return {
              ...item,
              financial_detail_notes: newArr,
            };
          }
          return item;
        },
      );

      state.btnLoading = false;
    });
    builder.addCase(updateFinancialDetailNote.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateFinancialDetailNote.fulfilled, (state, action) => {
      const { financial_detail_id, id } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            const updatedItem = item.financial_detail_notes.map((note) => {
              if (note.id === id) {
                return {
                  ...action.payload,
                };
              }
              return note;
            });

            return {
              ...item,
              financial_detail_notes: updatedItem,
            };
          }
          return item;
        },
      );
      state.btnLoading = false;
    });

    builder.addCase(removeFinancialDetailNote.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(removeFinancialDetailNote.fulfilled, (state, action) => {
      const { financial_detail_id, id } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            const removeItem = item.financial_detail_notes.filter(
              (item) => item.id !== id,
            );

            return {
              ...item,
              financial_detail_notes: removeItem,
            };
          }
          return item;
        },
      );
      state.btnLoading = false;
    });

    // Ceiling Flow
    builder.addCase(createCeilingFlow.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createCeilingFlow.fulfilled, (state, action) => {
      const { financial_detail_id } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            let ceilingFlowItems = item.ceiling_flow_details;
            ceilingFlowItems.push(action.payload);

            const newItem = {
              ...item,
              ceiling_flow_details: ceilingFlowItems,
            };
            return newItem;
          }
          return item;
        },
      );
      state.btnLoading = false;
    });

    builder.addCase(updateCeilingFlow.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateCeilingFlow.fulfilled, (state, action) => {
      const { financial_detail_id, id } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            const ceilingFlowItems = item.ceiling_flow_details;

            const newItems = ceilingFlowItems.map((flow) => {
              if (flow.id === id) {
                return { ...action.payload };
              }
              return flow;
            });

            const newItem = {
              ...item,
              ceiling_flow_details: newItems,
            };
            return newItem;
          }
          return item;
        },
      );
      state.btnLoading = false;
    });

    builder.addCase(removeCeilingFlow.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(removeCeilingFlow.fulfilled, (state, action) => {
      const { financial_detail_id, id } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            const removeItem = item.ceiling_flow_details.filter(
              (flow) => flow.id !== id,
            );
            const newItem = {
              ...item,
              ceiling_flow_details: removeItem,
            };
            return newItem;
          }
          return item;
        },
      );
      state.btnLoading = false;
    });

    // Ceiling Flow Notes
    builder.addCase(createCeilingFlowNote.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createCeilingFlowNote.fulfilled, (state, action) => {
      const {
        financial_detail_id,
        ceiling_flow_detail_id,
        note,
        write_by,
        id,
      } = action.payload;
      const newItem = {
        id: id,
        ceiling_flow_detail_id: ceiling_flow_detail_id,
        note: note,
        write_by: write_by,
      };
      state.project.financial_details = state.project.financial_details.map(
        (obj1) => {
          if (obj1.id === financial_detail_id) {
            const obj2 = obj1.ceiling_flow_details.map((obj2) => {
              if (obj2.id === ceiling_flow_detail_id) {
                let newArr = obj2.ceiling_flow_detail_notes;
                newArr.push(newItem);

                const newItems = {
                  ...obj2,
                  ceiling_flow_detail_notes: newArr,
                };
                return newItems;
              }
              return obj2;
            });
            return {
              ...obj1,
              ceiling_flow_details: obj2,
            };
          }
          return obj1;
        },
      );
      state.btnLoading = false;
    });

    builder.addCase(updateCeilingFlowNote.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateCeilingFlowNote.fulfilled, (state, action) => {
      const { ceiling_flow_detail_id, id } = action.payload;
      const { financial_detail_id, ...rest } = action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (obj1) => {
          if (obj1.id === financial_detail_id) {
            const obj2 = obj1.ceiling_flow_details.map((obj2) => {
              if (obj2.id === ceiling_flow_detail_id) {
                const updateItem = obj2.ceiling_flow_detail_notes.map(
                  (item) => {
                    if (item.id === id) {
                      return {
                        ...rest,
                      };
                    }
                    return item;
                  },
                );

                const newItems = {
                  ...obj2,
                  ceiling_flow_detail_notes: updateItem,
                };

                return newItems;
              }
              return obj2;
            });
            return {
              ...obj1,
              ceiling_flow_details: obj2,
            };
          }
          return obj1;
        },
      );
      state.btnLoading = false;
    });

    builder.addCase(removeCeilingFlowNote.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(removeCeilingFlowNote.fulfilled, (state, action) => {
      const { financial_detail_id, ceiling_flow_detail_id, id } =
        action.payload;

      state.project.financial_details = state.project.financial_details.map(
        (obj1) => {
          if (obj1.id === financial_detail_id) {
            const obj2 = obj1.ceiling_flow_details.map((obj2) => {
              if (obj2.id === ceiling_flow_detail_id) {
                const removeItem = obj2.ceiling_flow_detail_notes.filter(
                  (item) => item.id !== id,
                );

                const newItems = {
                  ...obj2,
                  ceiling_flow_detail_notes: removeItem,
                };

                return newItems;
              }
              return obj2;
            });
            return {
              ...obj1,
              ceiling_flow_details: obj2,
            };
          }
          return obj1;
        },
      );
      state.btnLoading = false;
    });

    // Physical Details
    builder.addCase(updatePhysicalDetails.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updatePhysicalDetails.fulfilled, (state, action) => {
      state.project.physical_details = action.payload;
      state.btnLoading = false;
    });

    // Scope Details
    builder.addCase(createProjectScopeDetails.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createProjectScopeDetails.fulfilled, (state, action) => {
      const { financial_detail_id } = action.payload;
      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            return {
              ...item,
              project_scope_details: action.payload,
            };
          }
          return item;
        },
      );
      state.btnLoading = false;
    });
    builder.addCase(updateProjectScopeDetails.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateProjectScopeDetails.fulfilled, (state, action) => {
      const { financial_detail_id } = action.payload;
      state.project.financial_details = state.project.financial_details.map(
        (item) => {
          if (item.id === financial_detail_id) {
            return {
              ...item,
              project_scope_details: action.payload,
            };
          }
          return item;
        },
      );
      state.btnLoading = false;
    });

    // Human Resources Requirements
    builder.addCase(createHumanResourcesRequirement.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(
      createHumanResourcesRequirement.fulfilled,
      (state, action) => {
        state.project.human_resources_requirements.push(action.payload);

        state.btnLoading = false;
      },
    );

    builder.addCase(updatedHumanResourcesRequirement.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(
      updatedHumanResourcesRequirement.fulfilled,
      (state, action) => {
        const { id } = action.payload;
        state.project.human_resources_requirements =
          state.project.human_resources_requirements.map((item) => {
            if (item.id === id) {
              return action.payload;
            }
            return item;
          });
        state.btnLoading = false;
      },
    );

    builder.addCase(removeHumanResourcesRequirement.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(
      removeHumanResourcesRequirement.fulfilled,
      (state, action) => {
        const id = action.payload;
        state.project.human_resources_requirements =
          state.project.human_resources_requirements.filter(
            (item) => item.id.toString() !== id.toString(),
          );

        state.btnLoading = false;
      },
    );

    // Document
    builder.addCase(createSupportDocument.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(createSupportDocument.fulfilled, (state, action) => {
      state.project.support_documents.push(action.payload);

      state.btnLoading = false;
    });

    builder.addCase(updateSupportDocument.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateSupportDocument.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.project.support_documents = state.project.support_documents.map(
        (item) => {
          if (item.id === id) {
            return action.payload;
          }
          return item;
        },
      );
      state.btnLoading = false;
    });

    builder.addCase(removeSupportDocument.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeSupportDocument.fulfilled, (state, action) => {
      const id = action.payload;
      state.project.support_documents = state.project.support_documents.filter(
        (item) => item.id.toString() !== id.toString(),
      );

      state.btnLoading = false;
    });

    // Approved projects
    builder.addCase(getApprovedProjects.pending, (state, action) => {
      state.isLoading = true;
      state.projects = [];
    });
    builder.addCase(getApprovedProjects.fulfilled, (state, action) => {
      // const { data, links, meta } = action.payload;
      state.projects = action.payload;
      // state.links = links;
      // state.meta = meta;
      state.isLoading = false;
    });
    builder.addCase(
      getApprovedProjectsWithFinancialInformation.pending,
      (state, action) => {
        state.isLoading = true;
        state.projects = [];
      },
    );
    builder.addCase(
      getApprovedProjectsWithFinancialInformation.fulfilled,
      (state, action) => {
        // const { data, links, meta } = action.payload;
        state.projects = action.payload;
        // state.links = links;
        // state.meta = meta;
        state.isLoading = false;
      },
    );

    // Acknowledged Item
    builder.addCase(acknowledgedItem.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.project.return_applications = state.project.return_applications.map(
        (item) => {
          if (item.id === id) {
            return action.payload;
          }
          return item;
        },
      );
    });

    builder.addCase(createProjectScopeFinancial.fulfilled, (state, action) => {
      state.scopeFinancialList = action.payload;
      state.project.financial_details = action.payload;
    });
    builder.addCase(createProjectCeilingFlow.fulfilled, (state, action) => {
      const { project_scope, ceiling_flow } = action.payload;
      state.financialCeilingFlow = ceiling_flow;

      state.project.financial_details = project_scope;
    });

    builder.addCase(storeExpenditureData.fulfilled, (state, action) => {
      state.project = action.payload;
    });

    builder.addCase(getMicroProjects.pending, (state) => {
      state.microProjects.isLoading = true;
    });
    builder.addCase(getMicroProjects.fulfilled, (state, action) => {
      const { data, meta, links } = action.payload;
      state.microProjects = {
        ...state.microProjects,
        data: data,
        meta: meta,
        links: links,
      };
      state.microProjects.isLoading = false;
    });

    builder.addCase(getApprovedMicroProjectsStatistics.pending, (state) => {
      state.microProjects.isLoading = true;
    });
    builder.addCase(
      getApprovedMicroProjectsStatistics.fulfilled,
      (state, action) => {
        state.microProjectsStatistics = action.payload;
        state.microProjects.isLoading = false;
      },
    );
  },
});

export const {
  resetProjectsReducer,
  setSelectRmk,
  setSearchMinistry,
  setSearchDepartments,
  setSearchMainProjects,
  setKeywords,
  setResetSearchInput,
  setPerPage,
  setPage,
  setSelectFederalProjects,
  getScopeFinancialList,
  createScopeFinancial,
  updateScopeFinancial,
  deleteScopeFinancial,
  getFinancialCeilingFlowList,
  createFinancialCeilingFlow,
  updateFinancialCeilingFlow,
  deleteFinancialCeilingFlow,

  setMicroProjectPage,
  setMicroProjectPerPage,

  setProjectCost,

  setIsLoading,
} = projectsSlice.actions;

export default projectsSlice.reducer;
