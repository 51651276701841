import React, { useContext } from "react";
import { Accordion, Button, Card, Form, Table } from "react-bootstrap";
import usePalette from "../../../../../hooks/usePalette";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import NotyfContext from "../../../../../contexts/NotyfContext";

// icons
import { MdCheckCircle } from "react-icons/md";

// redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";

const ProjectBrief = ({
  handleChecked,
  handleExpand,
  passedSteps,
  projectId,
}) => {
  const palette = usePalette();
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const { rmkActiveApplication, project } = useSelector((state) => ({
    rmkActiveApplication: state.rmkReducer.rmkActiveApplication,
    project: state.projectReducer.project,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      introduction: project?.project_brief?.introduction || "",
      background: project?.project_brief?.background || "",
      objective: project?.project_brief?.objective || "",
      site_information: project?.project_brief?.site_information || "",
      component_project: project?.project_brief?.component_project || "",
      cost_estimation: project?.project_brief?.cost_estimation || "",
      expected_output_and_benefit:
        project?.project_brief?.expected_output_and_benefit || "",
      justification: project?.project_brief?.justification || "",
      additional_manpower: project?.project_brief?.additional_manpower || "",
      implementation_and_cash_flow:
        project?.project_brief?.implementation_and_cash_flow || "",
    },
    onSubmit: async (values) => {
      const newValues = {
        id: projectId,
        project_brief: values,
      };
      await dispatch(updateProject(newValues));
      handleNotification();
      handleChecked(5);
      handleExpand(6);
    },
  });

  const handleSave = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "Maklumat telah disimpan.";
    let color = "success";

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 2000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <div>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(5)}
      >
        {/* {(passedSteps || []).includes(5) ? (
          <MdCheckCircle className='me-2' size={29} color={palette.success} />
        ) : ( */}
        <span className='number'>5.</span>
        {/* )} */}
        Kandungan Ringkasan Projek / Project Brief
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Header className='text-center my-3'>
            <Card.Text className='fw-bolder'>
              <span className='text-uppercase me-1'>
                Penyediaan {rmkActiveApplication?.name}
              </span>
              ({rmkActiveApplication?.short_form})
            </Card.Text>

            <Card.Text className='fw-bolder text-uppercase'>
              Kandungan Ringkasan Projek /{" "}
              <span className='fst-italic'>Project Brief</span>
            </Card.Text>
          </Card.Header>
          <Card.Body style={{ paddingRight: "8%", paddingLeft: "8%" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>I.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>Pengenalan</span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='introduction'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.introduction}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>II.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Nama dan latar belakang projek
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='background'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.background}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>III.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Objektif Projek
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='objective'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.objective}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>IV.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Maklumat Tapak
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='site_information'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.site_information}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>V.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Komponen Projek
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='component_project'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.component_project}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>VI.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Anggaran Kos Projek
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='cost_estimation'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.cost_estimation}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>VII.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      <span className='fst-italic me-1'>Output</span>dan faedah
                      yang dijangka daripada projek
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='expected_output_and_benefit'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.expected_output_and_benefit}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>VIII.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Justifikasi Projek
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='justification'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.justification}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>VIIII.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Keperluan Tenaga Manusia
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='additional_manpower'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.additional_manpower}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td className='p-2' style={{ width: "5%" }}>
                    <span className='fw-bolder'>X.</span>
                  </td>
                  <td className='p-2' style={{ width: "95%" }}>
                    <span className='fw-bolder text-uppercase'>
                      Jadual Pelaksanaan dan Aliran Tunai program/projek
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='p-2' style={{ width: "5%" }} />
                  <td className='p-2' style={{ width: "95%" }}>
                    <Form.Group>
                      <Form.Control
                        name='implementation_and_cash_flow'
                        type='text'
                        as='textarea'
                        style={{ height: 80, minHeight: 80, maxHeight: 150 }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.implementation_and_cash_flow}
                        disabled={project?.application_sent}
                      />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Card.Body>
          <div className='d-flex justify-content-end p-4'>
            <Button onClick={handleSave}>Simpan</Button>
          </div>
        </Card>
      </Accordion.Body>
    </div>
  );
};

export default ProjectBrief;
