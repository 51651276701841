import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { IconButton, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import { customStyles } from "../../../../components/common/customStyleSelect";

// Icons
import { MdSearch } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

// redux
import {
  setSelectRmk,
  setKeywords,
} from "../../../../redux/slices/projects/projectsSlice";

const Filters = ({ filterItem, handleShowCanvas }) => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { applicationType } = routeParams;

  const [showRmkFilter, setShowRmkFilter] = useState(false);
  const [keywordsInput, setKeywordsInput] = useState("");

  const { rmkOptions, selectRmk, activeRmk, keywords } = useSelector(
    (state) => ({
      rmkOptions: state.utilsReducer.dropdownItems.rmkItems,
      activeRmk: state.rmkReducer.activeRmk,
      selectRmk: state.projectReducer.selectRmk,
      keywords: state.projectReducer.keywords,
    }),
  );

  useEffect(() => {
    if (applicationType === "sambungan") {
      setShowRmkFilter(true);
    } else {
      setShowRmkFilter(false);
    }
  }, [applicationType]);

  useEffect(() => {
    setKeywordsInput(keywords);
  }, [keywords]);

  return (
    <div className='d-flex align-items-center gap-2 mb-3'>
      {/* <motion.div variants={filterItem} className='remove-outline-item'>
        <Tooltip title='Lebih Pilihan' placement='top' arrow>
          <Button variant='secondary' onClick={handleShowCanvas}>
            <Icon path={mdiFilterVariant} size={0.8} />
          </Button>
        </Tooltip>
      </motion.div> */}

      <motion.div variants={filterItem}>
        <div className='search-box-custom'>
          <div className='position-relative'>
            <label htmlFor='search-bar-0' className='search-label-custom'>
              <input
                type='text'
                className='form-control'
                placeholder='Cari...'
                value={keywordsInput}
                style={{ width: 280 }}
                onChange={(e) => setKeywordsInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    dispatch(setKeywords(keywordsInput));
                  }
                }}
              />
            </label>
            <MdSearch className='search-icon-2' size={18} />
          </div>
        </div>
      </motion.div>
      {showRmkFilter && (
        <motion.div variants={filterItem}>
          <Form.Group>
            <Select
              placeholder='RMK'
              styles={customStyles}
              className='react-select-container'
              classNamePrefix='react-select'
              options={rmkOptions}
              value={selectRmk || activeRmk}
              onChange={(val) => dispatch(setSelectRmk(val))}
            />
          </Form.Group>
        </motion.div>
      )}
    </div>
  );
};

export default Filters;
