import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";
import { isEmpty } from "lodash";
import { useDropzone } from "react-dropzone";
import {
  CardMedia,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from "@mui/material";
import DOMPurify from "dompurify";
import Quill from "quill";
import Editor from "../../../../../hooks/Editor";

// Styles
import NotyfContext from "../../../../../contexts/NotyfContext";
import usePalette from "../../../../../hooks/usePalette";

// Components
import ImplementationSchedule from "../tables/ImplementationSchedule";

// Icons
import { MdCheckCircle, MdCancel } from "react-icons/md";
import Icon from "@mdi/react";
import {
  mdiInformation,
  mdiTrashCan,
  mdiFile,
  mdiHelpCircle,
  mdiMagnify,
} from "@mdi/js";
import PDF from "../../../../../assets/img/icons/pdf.svg";

// Redux
import { updatePhysicalDetails } from "../../../../../redux/slices/projects/physicalDetailsSlice";
import {
  storeAttachment,
  deleteAttachment,
  previewProjectDesigns,
  updateProject,
} from "../../../../../redux/slices/projects/projectSlice";
import { checkPriorityNumber } from "../../../../../redux/slices/utilsSlice";

const Delta = Quill.import("delta");

const ProjectScopeForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  projectId,
  enableBtnSave,
}) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const palette = usePalette();
  const quillRef1 = useRef(null);
  const quillRef2 = useRef(null);

  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [designValue, setDesignValue] = useState("");
  const [implementationPeriod, setImplementationPeriod] = useState({
    total: "",
    type: "",
  });

  const [files, setFiles] = useState([]);
  const [showBtn, setShowBtn] = useState(null);

  const [showEIAInput, setShowEIAInput] = useState(false);
  const [showQuillComponent, setShowQuillComponent] = useState(false);
  const [environmentalImpactAreas, setEnvironmentalImpactAreas] =
    useState(null);
  const [eiaMeasures, setEiaMeasures] = useState(null);
  const [levelOfImplementationInput, setLevelOfImplementationInput] =
    useState(null);
  const [technicalSpecificationInput, setTechnicalSpecificationInput] =
    useState(null);
  const [listOfNumberPriority, setListOfNumberPriority] = useState([]);

  const { project, targetGroups } = useSelector((state) => ({
    project: state.projectReducer.project,
    targetGroups: state.utilsReducer.dropdownItems.groups,
  }));

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      priority: project?.priority || "",
      accordance_with_local_plans:
        !isEmpty(project?.physical_details) &&
        project.physical_details.accordance_with_local_plans,
      utility_related:
        !isEmpty(project?.physical_details) &&
        project?.physical_details.utility_related,
      expected_tender_date:
        (!isEmpty(project?.physical_details?.expected_tender_date) &&
          format(
            new Date(project?.physical_details?.expected_tender_date),
            "yyyy-MM-dd",
          )) ||
        "",
      expected_start_date:
        (!isEmpty(project?.physical_details?.expected_start_date) &&
          format(
            new Date(project?.physical_details?.expected_start_date),
            "yyyy-MM-dd",
          )) ||
        "",
      expected_completion_date:
        (!isEmpty(project?.physical_details?.expected_completion_date) &&
          format(
            new Date(project?.physical_details?.expected_completion_date),
            "yyyy-MM-dd",
          )) ||
        "",
      site_width:
        (!isEmpty(project?.physical_details?.expected_completion_date) &&
          project?.physical_details.site_width) ||
        "",
      project_type_id: project?.project_type?.id || null,
      target_groups: project?.target_groups || [],
    },
    validationSchema: Yup.object({
      project_type_id: Yup.number()
        .typeError("Sila pilih jenis projek")
        .required("Sila pilih jenis projek"),
    }),
    onSubmit: async (values) => {
      const { project_type_id, target_groups, ...newValuesPhysicalDetails } =
        values;
      newValuesPhysicalDetails.id = project.physical_details.id;
      // newValuesPhysicalDetails.environmental_impact_areas =
      //   environmentalImpactAreas;
      // newValuesPhysicalDetails.eia_measures = eiaMeasures;
      newValuesPhysicalDetails.level_of_implementation =
        levelOfImplementationInput;
      newValuesPhysicalDetails.technical_specification =
        technicalSpecificationInput;
      await dispatch(updatePhysicalDetails(newValuesPhysicalDetails));

      const targetGroupIds = values.target_groups.map((item) => item.id);
      const newValuesProject = {
        id: project.uuid,
        project_type_id: values.project_type_id,
        target_groups: targetGroupIds,
        priority: values?.priority,
      };
      await dispatch(updateProject(newValuesProject));

      handleNotification("success");
      handleChecked(3);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    try {
      acceptedFiles.forEach((acceptedFile) => {
        const newValues = {
          project_id: projectId,
          collection_type: "project_design",
          file: acceptedFile,
        };
        dispatch(storeAttachment(newValues)).then(({ payload }) => {
          const { data } = payload;
          setFiles((prev) => [...prev, data]);
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxFiles: 5,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const handleMouseEnter = (idx) => {
    setShowBtn(idx);
  };

  const handleViewAttachment = (item) => {
    const { url_path } = item;
    window.open(url_path);
  };

  const handleRemoveAttachment = async (item) => {
    const { id } = item;
    await dispatch(deleteAttachment(id)).then(({ payload }) => {
      const id = payload;
      setFiles(files.filter((item) => item.id !== id));
    });
    setShowBtn(null);
  };

  const handleChangeRadio = (value) => {
    setDesignValue(value);
    if (value === 3) {
      setShowUploadBtn(true);
    } else {
      setShowUploadBtn(false);
    }

    formik.setFieldValue("project_design_status", value);
  };

  const handleSelectProjectType = (e) => {
    const value = Number(e.target.value);
    formik.setFieldValue("project_type_id", value);

    if (value === 1) {
      setShowEIAInput(true);
    } else {
      setShowEIAInput(false);
    }
  };

  const handleCheckPriorityNumber = async () => {
    try {
      const props = {
        params: {
          ministry_id: Number(project?.ministry?.code),
          department_id: project?.department?.id || null,
          rmk_no: project?.rmk?.number,
          main_project_id: project?.main_project?.id,
        },
      };
      const response = await dispatch(checkPriorityNumber(props));
      const data = response.payload;

      setListOfNumberPriority(data);
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  // Notification
  const handleNotification = (type, values) => {
    // Errors
    if (type === "danger") {
      notyf.open({
        type: type,
        message: "Sila semak pembetulan.",
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "right",
          y: "top",
        },
      });
      // const errors = Object.values(values);

      // errors.map((item) => {
      //   const strMsg = item;

      //   notyf.open({
      //     type: type,
      //     message: strMsg,
      //     duration: 7000,
      //     ripple: false,
      //     dismissible: true,
      //     position: {
      //       x: "right",
      //       y: "top",
      //     },
      //   });
      // });
      return false;
    }

    // Success
    notyf.open({
      type: type,
      message: "Maklumat skop projek berjaya disimpan.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    if (
      !isEmpty(formik.values.expected_completion_date) &&
      !isEmpty(formik.values.expected_tender_date)
    ) {
      const result = formatDistanceStrict(
        new Date(formik.values.expected_tender_date),
        new Date(formik.values.expected_completion_date),
        {
          addSuffix: false,
          locale: ms,
        },
      );
      const splitText = result.split(" ");
      const upperCaseFirstLetter =
        splitText[1].charAt(0).toLocaleUpperCase() + splitText[1].slice(1);
      setImplementationPeriod({
        total: splitText[0],
        type: upperCaseFirstLetter,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [
    formik.values.expected_tender_date,
    formik.values.expected_completion_date,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    if (isEmpty(project)) return;

    handleChangeRadio(project.physical_details?.project_design_status);
    dispatch(previewProjectDesigns(project.id)).then(({ payload }) =>
      setFiles(payload),
    );

    if (project?.project_type?.id === 1) {
      setShowEIAInput(true);
    }

    setShowQuillComponent(true);

    setLevelOfImplementationInput(
      project.physical_details?.level_of_implementation,
    );
    setTechnicalSpecificationInput(
      project?.physical_details?.technical_specification,
    );

    // if (project?.application_sent !== true) {
    handleCheckPriorityNumber();
    // }

    return () => {
      setFiles([]);
      isMounted = false;
    };
  }, [project]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(3)}
      >
        {/* {(passedSteps || []).includes(3) ? (
          <MdCheckCircle className='me-2' size={29} color={palette.success} />
        ) : ( */}
        <span className='number'>3.</span>
        {/* )} */}
        Maklumat Skop Projek
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Body className='m-0'>
            <Card.Text className='text-sm'>
              Sila isi maklumat skop projek.
            </Card.Text>
            <ImplementationSchedule enableBtnSave={enableBtnSave} />
            <Form
              noValidate
              autoComplete='off'
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Col sm={3}>
                  <Form.Label className='text-sm'>Keutamaan Projek</Form.Label>
                  <span className='ms-1'>
                    <Tooltip
                      title={
                        <div className='text-start'>
                          Keutamaan program/projek perlu tersusun mengikut
                          bilangan jumlah keseluruhan program/projek yang telah
                          dipohon.
                        </div>
                      }
                      placement='top'
                      arrow
                    >
                      <span>
                        <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                      </span>
                    </Tooltip>
                  </span>
                </Col>
                <Col sm={9}>
                  {project?.application_sent ? (
                    <Form.Control
                      name='priority'
                      type='text'
                      style={{ width: 200 }}
                      defaultValue={formik.values.priority}
                      disabled
                    />
                  ) : (
                    <div className='position-relative d-inline-flex'>
                      <div style={{ width: 200 }}>
                        <Form.Select
                          name='priority'
                          style={{ width: 200 }}
                          value={formik.values?.priority}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={project?.application_sent}
                        >
                          <option value='' disabled>
                            Pilih nombor keutamaan
                          </option>
                          {listOfNumberPriority?.map((priority) => (
                            <option key={priority} value={priority}>
                              {priority}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex'>
                <Col sm={3}>
                  <Form.Label className='text-sm'>
                    Perancangan tahap pelaksanaan RMKe-13
                  </Form.Label>
                  <span className='ms-1'>
                    <Tooltip
                      title={
                        <div className='text-start'>
                          Komponen yang akan dilaksanakan dalam RMKe-13 (Contoh
                          : Penyediaan tapak/kajian kemungkinan)
                        </div>
                      }
                      placement='top'
                      arrow
                    >
                      <span>
                        <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                      </span>
                    </Tooltip>
                  </span>
                </Col>

                <Col sm={9}>
                  {showQuillComponent && (
                    <div
                      className='mb-3'
                      style={{
                        pointerEvents: project?.application_sent
                          ? "none"
                          : "auto",
                      }}
                    >
                      <Editor
                        ref={quillRef1}
                        readOnly={project?.application_sent}
                        defaultValue={levelOfImplementationInput}
                        onTextChange={setLevelOfImplementationInput}
                      />
                    </div>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex'>
                <Form.Label column sm={3}>
                  Spesifikasi Teknikal
                </Form.Label>
                <Col sm={9}>
                  {showQuillComponent && (
                    <div
                      style={{
                        pointerEvents: project?.application_sent
                          ? "none"
                          : "auto",
                      }}
                    >
                      <Editor
                        ref={quillRef2}
                        readOnly={project?.application_sent}
                        defaultValue={technicalSpecificationInput}
                        onTextChange={setTechnicalSpecificationInput}
                      />
                    </div>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Reka Bentuk Projek *
                  <div>
                    <Form.Text className='text-muted fst-italic'>
                      Sila pilih salah satu
                    </Form.Text>
                  </div>
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    <Form.Check
                      inline
                      type='radio'
                      id='not_needed'
                      name='project_design_status'
                      label='Tidak Diperlukan'
                      className='mb-2'
                      value={1}
                      checked={designValue === 1}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleChangeRadio(value);
                      }}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='not_ready'
                      name='project_design_status'
                      label='Belum Disediakan'
                      className='mb-2'
                      value={2}
                      checked={designValue === 2}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleChangeRadio(value);
                      }}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='ready'
                      name='project_design_status'
                      label='Telah Disediakan'
                      className='mb-2'
                      value={3}
                      checked={designValue === 3}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleChangeRadio(value);
                      }}
                    />
                  </div>
                </Col>
              </Form.Group>

              {showUploadBtn && (
                <>
                  <Row className='mb-3 align-items-center'>
                    <Col sm={3}></Col>
                    <Col sm={9}>
                      <div className=''>
                        {isEmpty(files) ? (
                          <div className='d-flex justify-content-center border-dashed-m py-3 rounded'>
                            <div className='align-self-center'>
                              Reka Bentuk Projek Tidak Tersedia
                            </div>
                          </div>
                        ) : (
                          <ImageList className='mb-0' cols={5}>
                            {files.map((item, idx) => {
                              let src = null;

                              switch (item.mime_type) {
                                case "application/pdf":
                                  src = PDF;
                                  break;

                                default:
                                  src = item.blob;
                                  break;
                              }

                              return (
                                <ImageListItem
                                  key={idx}
                                  className='position-relative d-flex align-items-center'
                                >
                                  <img
                                    src={src || item?.thumb}
                                    alt='Fail tidak ditemui'
                                    loading='lazy'
                                    style={{ width: 60, height: "auto" }}
                                    onClick={() => handleViewAttachment(item)}
                                  />

                                  <div
                                    className='position-absolute'
                                    style={{ right: 40, top: -3 }}
                                  >
                                    <Tooltip title='Padam' arrow>
                                      <IconButton
                                        className='p-0'
                                        size='small'
                                        color='error'
                                        onClick={() =>
                                          handleRemoveAttachment(item, idx)
                                        }
                                      >
                                        <MdCancel
                                          size={20}
                                          color={palette.danger}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <ImageListItemBar
                                    subtitle={item.file_name}
                                    position='below'
                                    sx={{ width: "6rem" }}
                                  />
                                </ImageListItem>
                              );
                            })}
                          </ImageList>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className='mb-3 align-items-center'>
                    <Col sm={3} className='text-sm'>
                      <Form.Label className='m-0'>
                        Muat Naik Reka Bentuk Projek
                      </Form.Label>
                      <Form.Text as='div' className='m-0 fst-italic'>
                        Sila muat naik reka bentuk yang telah disediakan
                      </Form.Text>
                    </Col>
                    <Col sm={9}>
                      <div
                        {...getRootProps()}
                        className='d-flex align-items-center'
                      >
                        <input {...getInputProps()} />
                        <Button
                          className='me-3'
                          variant='secondary'
                          onClick={open}
                          style={{ fontSize: 11 }}
                        >
                          MUAT NAIK
                        </Button>
                        {/* <div>
                          <ImageList className='mb-0' cols={5}>
                            {files.map((item, idx) => {
                              return (
                                <ImageListItem
                                  key={idx}
                                  className='position-relative'
                                  onMouseEnter={() => handleMouseEnter(idx)}
                                  onMouseLeave={() => setShowBtn(null)}
                                >
                                  <img
                                    className={`${
                                      idx === showBtn ? "opacity-25" : ""
                                    } pb-b mb-1`}
                                    src={PDF}
                                    alt='Fail tidak ditemui'
                                    loading='lazy'
                                    style={{ height: 60, objectFit: "fill" }}
                                  />
                                  {idx === showBtn && (
                                    <Tooltip title='Lihat' arrow>
                                      <IconButton
                                        className='position-absolute'
                                        size='small'
                                        sx={{ right: "50%", top: "20%" }}
                                        color='primary'
                                        onClick={() =>
                                          handleViewAttachment(item)
                                        }
                                      >
                                        <Icon path={mdiFile} size={1} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {idx === showBtn && (
                                    <Tooltip title='Padam' arrow>
                                      <IconButton
                                        className='position-absolute'
                                        size='small'
                                        sx={{ right: "15%", top: "20%" }}
                                        color='error'
                                        onClick={() =>
                                          handleRemoveAttachment(item)
                                        }
                                      >
                                        <Icon path={mdiTrashCan} size={1} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <ImageListItemBar
                                    className={`${
                                      item.id === showBtn ? "opacity-25" : ""
                                    } text-center`}
                                    subtitle={item.file_name}
                                    position='below'
                                    sx={{ width: "6rem" }}
                                  />
                                </ImageListItem>
                              );
                            })}
                          </ImageList>
                        </div> */}
                      </div>
                    </Col>
                  </Form.Group>
                </>
              )}

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tarikh Projek Dijangka Tender
                </Form.Label>
                <Col sm={9}>
                  <div className=''>
                    <Form.Control
                      name='expected_tender_date'
                      type='date'
                      style={{ width: 200 }}
                      value={formik.values.expected_tender_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tarikh Projek Dijangka Mula
                </Form.Label>
                <Col sm={9}>
                  <div className=''>
                    <Form.Control
                      name='expected_start_date'
                      type='date'
                      style={{ width: 200 }}
                      value={formik.values.expected_start_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tarikh Projek Dijangka Siap
                </Form.Label>
                <Col sm={9}>
                  <div className=''>
                    <Form.Control
                      name='expected_completion_date'
                      type='date'
                      style={{ width: 200 }}
                      value={formik.values.expected_completion_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tempoh Perlaksanaan
                </Form.Label>
                <Col sm={9}>
                  <InputGroup className='' style={{ width: 200 }}>
                    <Form.Control
                      type='number'
                      aria-describedby='month'
                      min={0}
                      name='implementation_period'
                      value={implementationPeriod.total}
                      style={{ fontWeight: 500 }}
                      disabled
                    />
                    <InputGroup.Text id='month'>
                      {implementationPeriod.type}
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Keluasan Tapak
                </Form.Label>
                <Col sm={9}>
                  <InputGroup className='' style={{ width: 200 }}>
                    <Form.Control
                      name='site_width'
                      type='text'
                      min={0}
                      value={formik.values.site_width}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    <Form.Check
                      inline
                      type='radio'
                      id='sync_yes'
                      name='accordance_with_local_plans'
                      label='Ya'
                      className='mb-2'
                      value={true}
                      checked={
                        formik.values.accordance_with_local_plans === true
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          "accordance_with_local_plans",
                          JSON.parse(e.target.value),
                        );
                      }}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='sync_no'
                      name='accordance_with_local_plans'
                      label='Tidak'
                      className='mb-2'
                      value={false}
                      checked={
                        formik.values.accordance_with_local_plans === false
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          "accordance_with_local_plans",
                          JSON.parse(e.target.value),
                        );
                      }}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className='mb-3 d-flex align-items-center'
                controlId='radio1'
              >
                <Form.Label column sm={3} className='text-sm'>
                  Cadangan Berkaitan Utiliti
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    <Form.Check
                      inline
                      type='radio'
                      id='utility_yes'
                      name='utility_related'
                      label='Ya'
                      className='mb-2'
                      value={true}
                      checked={formik.values.utility_related === true}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "utility_related",
                          JSON.parse(e.target.value),
                        )
                      }
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='utility_no'
                      name='utility_related'
                      label='Tidak'
                      className='mb-2'
                      value={false}
                      checked={formik.values.utility_related === false}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "utility_related",
                          JSON.parse(e.target.value),
                        )
                      }
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Jenis Projek
                  {formik.touched.project_type_id &&
                    formik.errors.project_type_id && (
                      <p className='mt-1 custom-feedback-invalid'>
                        {formik.errors.project_type_id}
                      </p>
                    )}
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    <Form.Check
                      inline
                      type='radio'
                      id='program_yes_physical'
                      name='project_type_id'
                      label='Fizikal'
                      className='mb-2'
                      value={1}
                      checked={formik.values.project_type_id === 1}
                      onChange={(e) => handleSelectProjectType(e)}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='program_physical'
                      name='project_type_id'
                      label='Program (Fizikal)'
                      className='mb-2'
                      value={2}
                      checked={formik.values.project_type_id === 2}
                      onChange={(e) => handleSelectProjectType(e)}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='program_non_physical'
                      name='project_type_id'
                      label='Program (Bukan Fizikal)'
                      className='mb-2'
                      value={3}
                      checked={formik.values.project_type_id === 3}
                      onChange={(e) => handleSelectProjectType(e)}
                    />
                  </div>
                </Col>
              </Form.Group>

              {/* {showEIAInput && (
                <Form.Group as={Row} className='mb-3 d-flex'>
                  <Form.Label column sm={3}>
                    Kawasan Sensitif Alam Sekitar (Jika Berkaitan)
                  </Form.Label>
                  <Col sm={9}>
                    <div className='mb-3'>
                      <Form.Label>
                        Nyatakan kawasan sensitif (Sila rujuk lampiran F) yang
                        berada di dalam lingkungan 5 Km radius dengan tapak
                        projek yang dicadangkan.
                        <span className='ms-1 fw-bolder'>(Jika Berkaitan)</span>
                      </Form.Label>
                      <Editor
                        ref={quillRef3}
                        readOnly={project?.application_sent}
                        defaultValue={environmentalImpactAreas}
                        onTextChange={setEnvironmentalImpactAreas}
                      />
                    </div>
                    <div className='mb-3'>
                      <Form.Label>
                        Senaraikan langkah-langkah perlindungan alam sekitar
                        yang berkaitan melalui
                        <span className='fst-italic mx-1'>
                          high-level assessment
                        </span>
                        dengan projek yang dicandangkan.
                        <span className='ms-1 fw-bolder'>(Jika Berkaitan)</span>
                      </Form.Label>
                      <Editor
                        ref={quillRef4}
                        readOnly={project?.application_sent}
                        defaultValue={eiaMeasures}
                        onTextChange={setEiaMeasures}
                      />
                    </div>
                  </Col>
                </Form.Group>
              )} */}

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Kumpulan Sasar
                </Form.Label>
                <Col sm={9}>
                  <Select
                    placeholder='Pilih...'
                    isMulti
                    noOptionsMessage={() => "Tiada pilihan"}
                    options={targetGroups}
                    value={formik.values?.target_groups}
                    onChange={(value) =>
                      formik.setFieldValue("target_groups", value)
                    }
                    isDisabled={project?.application_sent}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                </Col>
              </Form.Group>

              {enableBtnSave && (
                <div className='d-flex justify-content-end'>
                  <Button
                    type='submit'
                    variant='primary'
                    // disabled={(passedSteps || []).includes(2) ? false : true}
                  >
                    Simpan
                  </Button>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectScopeForm;
