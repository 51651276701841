import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";

// Icons
import { BiMessageDetail, BiBell } from "react-icons/bi";
import { AlertCircle, Bell, Home, UserPlus } from "react-feather";
import Icon from "@mdi/react";
import { mdiMenuOpen, mdiMenuClose } from "@mdi/js";

import useSidebar from "../../hooks/useSidebar";

import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarUser from "./NavbarUser";

const notifications = [];

const messages = [];

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const currentUrl = location.pathname;
    if (currentUrl === "/jadual-analisis-dalaman/senarai-projek") {
      setShowNavbar(false);
    }

    return () => {
      setShowNavbar(true);
    };
  }, [location]);

  return (
    <Navbar
      variant='light'
      expand
      className={`navbar-bg ${showNavbar ? "" : "hide-navbar"}`}
    >
      <span
        className='sidebar-toggle d-flex'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {/* <i className='hamburger align-self-center' /> */}
        {isOpen ? (
          <Icon className="align-self-center'" path={mdiMenuOpen} size={1} />
        ) : (
          <Icon className="align-self-center'" path={mdiMenuClose} size={1} />
        )}
      </span>

      <Navbar.Collapse>
        <Nav className='navbar-align'>
          {/* <NavbarDropdown
            header="New Messages"
            footer="Show all messages"
            icon={BiMessageDetail}
            count={messages.length}
            showBadge={messages.length === 0 ? false : true}
          >
            {messages.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={
                    <img
                      className="avatar img-fluid rounded-circle"
                      src={item.avatar}
                      alt={item.name}
                    />
                  }
                  title={item.name}
                  description={item.description}
                  time={item.time}
                  spacing
                />
              );
            })}
          </NavbarDropdown>

          <NavbarDropdown
            header="New Notifications"
            footer="Show all notifications"
            icon={BiBell}
            count={notifications.length}
            showBadge={notifications.length === 0 ? false : true}
          >
            {notifications.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === "important") {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === "login") {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === "request") {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.title}
                  description={item.description}
                  time={item.time}
                />
              );
            })}
          </NavbarDropdown> */}
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
