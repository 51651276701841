import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { current, produce } from "immer";
import axios from "../../../utils/axios";
import {
  createReport,
  showReport,
  updateReport,
  removeReport,
  storeReportAttachment,
  previewReportAttachments,
  removeReportAttachment,
  uploadProgress,
  getTotalProgressStatusToDate,
} from "./projectReportSlice";

export const fetchProjects = createAsyncThunk(
  "reports/fetchProjects",
  async (props) => {
    try {
      const { params, source } = props;

      const response = await axios.get(`/project/reports`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProject = createAsyncThunk(
  "reports/getProject",
  async (props) => {
    try {
      const { source, id } = props;

      const response = await axios.get(`/project/reports/${id}?with=query`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

const initialState = {
  projects: {
    data: [],
    links: null,
    meta: null,
    page: 1,
    perPage: 30,
    keywords: "",
  },
  project: {},
  totalProgressStatus: null,
  report: {},
  attachments: [],
  uploadProgress: {},
  isLoading: {
    projects: false,
    project: false,
    report: false,
  },
  btnLoading: false,
  searchMinistry: null,
  searchDepartment: null,
  searchBranch: null,
  searchStatus: null,
  selectDraft: null,
};

export const projectReportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    storeTempFiles: (state, action) => {
      state.attachments.push(action.payload);
    },
    setUploadProgress: (state, action) => {
      const { fileId, progress } = action.payload;
      state.uploadProgress[fileId] = progress;
    },
    removeProgress: (state, action) => {
      if (state.uploadProgress[action.payload] === 100) {
        delete state.uploadProgress[action.payload];
      }
    },
    setSearchMinistry: (state, action) => {
      state.searchMinistry = action.payload;
    },
    setSearchDepartment: (state, action) => {
      state.searchDepartment = action.payload;
    },
    setSearchBranch: (state, action) => {
      state.searchBranch = action.payload;
    },
    setPage: (state, action) => {
      state.projects.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.projects.perPage = action.payload;
    },
    setKeywords: (state, action) => {
      state.projects.keywords = action.payload;
    },
    setSearchStatus: (state, action) => {
      state.searchStatus = action.payload;
    },
    setSelectDraft: (state, action) => {
      state.selectDraft = action.payload;
    },
    setIsLoadingProjects: (state, action) => {
      state.isLoading.projects = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state, action) => {
      state.isLoading.projects = true;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      // const { data, links, meta } = action.payload;
      // state.projects.links = links;
      // state.projects.meta = meta;
      state.projects.data = action.payload;
    });

    builder.addCase(getProject.pending, (state, action) => {
      state.isLoading.project = true;
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.project = action.payload;
      state.isLoading.project = false;
    });

    builder.addCase(createReport.pending, (state, action) => {
      state.isLoading.report = true;
      state.btnLoading = true;
    });
    builder.addCase(createReport.fulfilled, (state, action) => {
      state.report = action.payload;
      state.isLoading.report = false;
      state.btnLoading = false;
    });

    builder.addCase(showReport.pending, (state, action) => {
      state.isLoading.report = true;
      state.btnLoading = true;
    });
    builder.addCase(showReport.fulfilled, (state, action) => {
      state.report = action.payload;
      state.isLoading.report = false;
      state.btnLoading = false;
    });

    builder.addCase(removeReport.fulfilled, (state, action) => {
      const id = action.payload;
      const reports = state.project.reports;

      const newItems = current(reports).filter((item) => item.id !== id);

      state.project = {
        ...state.project,
        reports: newItems,
      };
    });

    builder.addCase(storeReportAttachment.fulfilled, (state, action) => {
      const { temp_id } = action.payload;
      state.attachments = state.attachments.map((item) => {
        if (item.temp_id === temp_id) {
          return {
            ...action.payload,
            temp_id: null,
            uploading: false,
          };
        }

        return item;
      });
    });

    builder.addCase(previewReportAttachments.fulfilled, (state, action) => {
      state.attachments = action.payload;
    });

    builder.addCase(removeReportAttachment.fulfilled, (state, action) => {
      const id = action.payload;
      state.attachments = state.attachments.filter((item) => item.id !== id);
    });

    builder.addCase(updateReport.pending, (state) => {
      state.btnLoading = true;
    });

    builder.addCase(updateReport.fulfilled, (state, action) => {
      state.report = action.payload;
      state.btnLoading = false;
    });

    builder.addCase(getTotalProgressStatusToDate.pending, (state) => {
      state.btnLoading = true;
    });

    builder.addCase(getTotalProgressStatusToDate.fulfilled, (state, action) => {
      state.totalProgressStatus = action.payload;
      state.btnLoading = false;
    });
  },
});

export const {
  setUploadProgress,
  storeTempFiles,
  removeProgress,
  setSearchMinistry,
  setSearchDepartment,
  setSearchBranch,
  setPage,
  setPerPage,
  setKeywords,
  setSearchStatus,
  setSelectDraft,
  setIsLoadingProjects,
} = projectReportsSlice.actions;

export default projectReportsSlice.reducer;
