import React, { useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";

// Images
import kplb from "../../../assets/img/photos/KPLB-Logo-Lines.png";
import jkm from "../../../assets/img/photos/sabah-logo.png";

// redux
import { createMicroProject } from "../../../redux/slices/micro-project/microProjectSlice";

const MainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const user = useSelector((state) => state.userReducer.user);

  const openApplicationForm = async (ministryCode) => {
    const district = user?.user_access_to_district;

    const newValues = {
      ministry_id: ministryCode,
      district_id: district?.id,
    };

    await dispatch(createMicroProject(newValues)).then((res) => {
      const data = res.payload;
      navigate(
        `/projek-mikro/daftar-projek/${data.ministry_id}/kemas-kini/${data.id}`,
      );
    });
    handleNotification();
  };

  // Notification
  const handleNotification = () => {
    // Success
    notyf.open({
      type: "default",
      message: `Draf permohonan telah dicipta.`,
      duration: 3000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <React.Fragment>
      <Helmet title='Daftar Projek Mikro' />
      <Container
        fluid
        className='p-0'
        style={{ minHeight: "100%", height: "100%" }}
      >
        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
          <div className='mb-3'>
            <h5>Sila pilih</h5>
          </div>
          <Row>
            <Col className='d-flex'>
              <Card
                className='flex-fill w-100 shadow-lg card-animated '
                onClick={() => openApplicationForm(11)}
                style={{
                  cursor: "pointer",
                  minWidth: 300,
                  maxWidth: 300,
                  height: 250,
                  backgroundColor: "#e3f2fd",
                }}
              >
                <Card.Body className='d-flex flex-column  justify-content-center align-items-center'>
                  <div className='mb-3'>
                    <img src={jkm} style={{ height: 120, width: "100%" }} />
                  </div>
                  <h5 className='text-center text-uppercase'>
                    Projek Pembangunan Khas Dibawah <br /> Jabatan Ketua Menteri
                  </h5>
                </Card.Body>
              </Card>
            </Col>
            <Col className='d-flex'>
              <Card
                className='flex-fill w-100 shadow-lg card-animated '
                onClick={() => openApplicationForm(19)}
                style={{
                  cursor: "pointer",
                  minWidth: 300,
                  maxWidth: 300,
                  height: 250,
                  backgroundColor: "#e3f2fd",
                }}
              >
                <Card.Body className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='mb-4'>
                    <img
                      src={kplb}
                      alt='logo'
                      style={{ height: "auto", width: "100%" }}
                    />
                  </div>
                  <h5 className='text-center text-uppercase'>
                    Projek Pembangunan Luar Bandar Dibawah <br /> Kementerian
                    Pembangunan Luar Bandar
                  </h5>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
