import React, { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Card, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import DOMPurify from "dompurify";
import Quill from "quill";
import Editor from "../../../../../hooks/Editor";

// Components
import HrRequirement from "../tables/HrRequirement";
import SupportDocuments from "../tables/SupportDocuments";
import SectionUpenGuide from "../modals/SectionUpenGuide";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiCheckCircle, mdiHelpCircle } from "@mdi/js";
import { IoAlert } from "react-icons/io5";

// Styles
import NotyfContext from "../../../../../contexts/NotyfContext";
import usePalette from "../../../../../hooks/usePalette";
import {
  customStyles,
  customStylesForm,
} from "../../../../../components/common/customStyleSelect";

// Redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";
import { IconButton, Tooltip } from "@mui/material";

const Delta = Quill.import("delta");

const AdditionalInfoForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  enableBtnSave,
  projectId,
}) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const palette = usePalette();
  const quillRef = useRef();

  const [showQuillComponent, setShowQuillComponent] = useState(false);
  const [executingAgencyOptions, setExecutingAgencyOptions] = useState([]);
  const [showUpenSectionGuide, setShowUpenSectionGuide] = useState(false);
  const [additionalInformationInput, setAdditionalInformationInput] =
    useState(null);

  const { user, departments, executingAgencies, project, upenSections } =
    useSelector((state) => ({
      user: state.userReducer.user,
      project: state.projectReducer.project,
      departments: state.utilsReducer.dropdownItems.departments,
      executingAgencies: state.utilsReducer.dropdownItems.executingAgencies,
      upenSections: state.utilsReducer.dropdownItems.upen_sections,
    }));

  const isSuperAdmin = user.roles.includes("superadmin");

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicant_agency:
        project.applicant_agency || project.department || project.ministry,
      owner_agency: project.owner_agency || project.department,
      financially_responsible_agency:
        project.financially_responsible_agency || project.department,
      executing_agency: project.executing_agency || project.department,
      section_upen: project.upen_section || null,
    },
    validationSchema: Yup.object({
      financially_responsible_agency: Yup.object()
        .shape()
        .typeError(
          "Sila pilih agensi yang bertanggungjawab untunk bahagian kewangan",
        )
        .required(
          "Sila pilih agensi yang bertanggungjawab untunk bahagian kewangan",
        ),
      executing_agency: Yup.object()
        .shape()
        .typeError("Sila pilih agensi pelaksana")
        .required("Sila pilih agensi pelaksana"),
      section_upen: Yup.object()
        .shape()
        .typeError("Sila pilih seksyen upen")
        .required("Sila pilih seksyen upen"),
    }),
    onSubmit: (values) => {
      const newValues = {
        id: projectId,
        applicant_agency: values.applicant_agency?.id || null,
        owner_agency: values.owner_agency?.id || null,
        financially_responsible_agency:
          values.financially_responsible_agency?.id || null,
        executing_agency: values.executing_agency?.id || null,
        section_upen_id: values.section_upen?.id || null,
        additional_information: additionalInformationInput,
      };

      dispatch(updateProject(newValues));

      handleNotification("success");
      handleChecked(6);
      handleExpand("review");
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleShowSectionUpenGuide = () => {
    setShowUpenSectionGuide(true);
  };

  // Notification
  const handleNotification = (type, values) => {
    // Errors
    if (type === "danger") {
      const errors = Object.values(values);

      errors.map((item) => {
        const strMsg = item;

        notyf.open({
          type: type,
          message: strMsg,
          duration: 7000,
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "top",
          },
        });
      });
      return false;
    }

    // Success
    notyf.open({
      type: type,
      message: "Maklumat tambahan berjaya telah disimpan.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !isEmpty(executingAgencies)) {
      const options = executingAgencies.filter(
        (item) =>
          item.ministry_code.toString() === project.ministry?.code?.toString(),
      );
      setExecutingAgencyOptions(options);
    }
    return () => {
      isMounted = false;
    };
  }, [executingAgencies]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    if (isEmpty(project)) return;

    setShowQuillComponent(true);

    setAdditionalInformationInput(project?.additional_information);
    return () => {
      isMounted = false;
    };
  }, [project]);

  return (
    <React.Fragment>
      <SectionUpenGuide
        show={showUpenSectionGuide}
        handleClose={() => setShowUpenSectionGuide(false)}
      />

      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(6)}
      >
        {/* {isEmpty(formik.errors) ? (
          <Icon
            className='me-1'
            path={mdiCheckCircle}
            color={palette.success}
            style={{ height: 28, width: 28 }}
          />
        ) : ( */}
        <span
          className='number'
          // style={{ color: palette.danger, borderColor: palette.danger }}
        >
          6.
        </span>
        {/* )} */}
        Maklumat Tambahan
        {!isEmpty(formik.errors) && (
          <IoAlert size={24} color={palette.danger} />
        )}
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Body className='m-0'>
            <Form noValidate autoComplete='off'>
              <div className='mb-3'>
                <Card.Title>Agensi Pelaksana</Card.Title>
                <Card.Text className='text-sm'>
                  Sila isi maklumat agensi pelaksana.
                </Card.Text>
              </div>

              <Form.Group as={Row} className='mb-3'>
                <Form.Label column sm={3} htmlFor='applicant_agency'>
                  Agensi Pemohon
                </Form.Label>
                <Col sm={9}>
                  <Select
                    name='applicant_agency'
                    isSearchable
                    options={departments}
                    placeholder='Pilih Agensi...'
                    value={formik.values?.applicant_agency}
                    onChange={(value) =>
                      formik.setFieldValue("applicant_agency", value)
                    }
                    noOptionsMessage={() => "Tiada pilihan"}
                    isDisabled={isSuperAdmin ? false : true}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column sm={3} htmlFor='owner_agency'>
                  Agensi Pemilik
                </Form.Label>
                <Col sm={9}>
                  <Select
                    name='owner_agency'
                    isSearchable
                    options={departments}
                    placeholder='Pilih Agensi...'
                    value={formik.values.owner_agency}
                    onChange={(value) =>
                      formik.setFieldValue("owner_agency", value)
                    }
                    noOptionsMessage={() => "Tiada pilihan"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isDisabled={project?.application_sent}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label
                  column
                  sm={3}
                  htmlFor='financially_responsible_agency'
                >
                  Agensi Bertanggungjawab (Kewangan)
                </Form.Label>
                <Col sm={9}>
                  <Select
                    name='financially_responsible_agency'
                    isSearchable
                    options={departments}
                    placeholder='Pilih Agensi...'
                    value={formik.values.financially_responsible_agency}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "financially_responsible_agency",
                        value,
                      )
                    }
                    noOptionsMessage={() => "Tiada pilihan"}
                    isDisabled={project?.application_sent}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                  {formik.errors.financially_responsible_agency && (
                    <p className='mt-1 mb-0 custom-feedback-invalid'>
                      {formik.errors.financially_responsible_agency}
                    </p>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column sm={3} htmlFor='executing_agency'>
                  Agensi Pelaksana
                </Form.Label>
                <Col sm={9}>
                  <Select
                    name='executing_agency'
                    isSearchable
                    options={departments}
                    placeholder='Pilih Agensi...'
                    value={formik.values.executing_agency}
                    onChange={(value) =>
                      formik.setFieldValue("executing_agency", value)
                    }
                    noOptionsMessage={() => "Tiada pilihan"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isDisabled={project?.application_sent}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                  {formik.errors.executing_agency && (
                    <p className='mt-1 mb-0 custom-feedback-invalid'>
                      {formik.errors.executing_agency}
                    </p>
                  )}
                </Col>
              </Form.Group>

              <div className='mb-3'>
                <Card.Title>
                  Seksyen UPEN
                  {/* <span
                    className='text-muted ms-2 d-inline-flex align-item-center'
                    onClick={handleShowSectionUpenGuide}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon path={mdiHelpCircle} size={0.6} />
                  </span> */}
                </Card.Title>
              </div>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column sm={3} htmlFor='section_upen'>
                  Seksyen
                </Form.Label>
                <Col sm={9}>
                  <Select
                    name='section_upen'
                    isSearchable
                    options={upenSections}
                    placeholder='Pilih seksyen upen...'
                    value={formik.values.section_upen}
                    onChange={(value) =>
                      formik.setFieldValue("section_upen", value)
                    }
                    noOptionsMessage={() => "Tiada pilihan"}
                    isDisabled={project?.application_sent}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                  {formik.errors.section_upen && (
                    <p className='mt-1 mb-0 custom-feedback-invalid'>
                      {formik.errors.section_upen}
                    </p>
                  )}
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>

          <HrRequirement enableBtnSave={enableBtnSave} />
          <SupportDocuments enableBtnSave={enableBtnSave} />

          <div className='px-4'>
            <Form.Group className='mb-3'>
              <div>
                <Card.Title>
                  Maklumat Tambahan
                  <span className='ms-1'>
                    <Tooltip
                      title={
                        <div className='text-start'>
                          <p>
                            Berikan maklumat yang boleh menyokong projek.
                            <br /> Contoh:
                          </p>
                          <p className='mb-0'>
                            1) Nyatakan sama ada projek akan dibuat secara
                            berfasa.
                          </p>
                          <p className='mb-0'>
                            2) Menghuraikan sebarang halangan besar atau faktor
                            umum lain yang berkemungkinan mendatangkan kesan
                            yang besar ke atas projek.
                          </p>
                          <p className='mb-0'>
                            3) Lain-lain maklumat yang dapat memberi gambaran
                            lebih jelas mengenai projek.
                          </p>
                        </div>
                      }
                      placement='right'
                      arrow
                    >
                      <span>
                        <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                      </span>
                    </Tooltip>
                  </span>
                </Card.Title>
              </div>

              {showQuillComponent && (
                <div
                  style={{
                    pointerEvents: project?.application_sent ? "none" : "auto",
                  }}
                >
                  <Editor
                    ref={quillRef}
                    readOnly={project?.application_sent}
                    defaultValue={additionalInformationInput}
                    onTextChange={setAdditionalInformationInput}
                  />
                </div>
              )}
            </Form.Group>
          </div>

          <Card.Body>
            {enableBtnSave && (
              <div className='d-flex justify-content-end'>
                <Button
                  type='submit'
                  variant='primary'
                  onClick={handleSubmit}
                  // disabled={(passedSteps || []).includes(5) ? false : true}
                >
                  Simpan
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default AdditionalInfoForm;
